import React from "react";
import {
  Form,
  ModalBody,
  Label,
  FormFeedback,
  Input,
  Spinner,
  Row,
  Col,
} from "reactstrap";

const CountryForm: React.FC<{
  toggle: () => void;
  loading: boolean;
  isUpdate?: boolean;
  validation: any;
  setLoading: (loading: boolean) => void;
}> = ({ validation, toggle, isUpdate, loading, setLoading }) => {
  return (
    <Form
      className="tablelist-form"
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      <ModalBody>
        <input type="hidden" id="id-field" />

        <div className="mb-3">
          <Label htmlFor="id-field" className="form-label">
            Country Name
          </Label>
          <Input
            name="name"
            id="name"
            className="form-control"
            placeholder="Enter country name"
            type="text"
            validate={{
              required: { value: true },
            }}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.name || ""}
            invalid={
              validation.touched.name && validation.errors.name ? true : false
            }
          />
          {validation.touched.name && validation.errors.name ? (
            <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
          ) : null}
        </div>

        <Row>
          <Col md={12} className="mb-3">
            <Label htmlFor="id-field" className="form-label">
              Alternative Name
            </Label>
            <Input
              name="altName1"
              id="altName1"
              className="form-control"
              placeholder="Enter alternative name"
              type="text"
              validate={{
                required: { value: true },
              }}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.altName1 || ""}
              invalid={
                validation.touched.altName1 && validation.errors.altName1
                  ? true
                  : false
              }
            />
            {validation.touched.altName1 && validation.errors.altName1 ? (
              <FormFeedback type="invalid">
                {validation.errors.altName1}
              </FormFeedback>
            ) : null}
          </Col>
        </Row>
      </ModalBody>

      <div className="modal-footer">
        <div className="hstack gap-2 justify-content-end">
          <button
            type="button"
            className="btn btn-light"
            onClick={() => {
              toggle();
            }}
          >
            Close
          </button>

          <button type="submit" className="btn btn-primary" disabled={loading}>
            {loading && (
              <Spinner
                color="light"
                size="sm"
                style={{ marginRight: "8px", marginBottom: "-1px" }}
              ></Spinner>
            )}
            {isUpdate ? "Update Country" : " Add Country"}
          </button>
        </div>
      </div>
    </Form>
  );
};

export default CountryForm;
