import {
  userForgetPasswordSuccess,
  userForgetPasswordError,
  setLoading,
} from "./reducer";

import { postJwtForgetPwd } from "../../../helpers/backend_helper";

export const userForgetPassword =
  (user: any, history: any) => async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      let response;
      response = postJwtForgetPwd({
        email: user.email,
      });

      const data = await response;

      if (data) {
        dispatch(
          userForgetPasswordSuccess(
            "The password reset link has been sent to your email."
          )
        );
      }
      dispatch(setLoading(false));
      return data;
    } catch (forgetError: any) {
      dispatch(setLoading(false));
      dispatch(userForgetPasswordError(forgetError?.message));
    }
  };
