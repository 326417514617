import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Card, CardBody, Row, CardHeader, Col } from "reactstrap";
import UpdateAliasNames from "./UpdateAliasNames";
import { getPerson as onGetPerson } from "slices/thunks";
import Permission from "Components/Common/Permission";

const AliasNames = ({ person }: { person: any }) => {
  const dispatch: any = useDispatch();

  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const showUpdateModal = () => {
    setIsUpdateModalOpen(true);
  };

  const aliasNames = person?.alias || [];

  return (
    <Card>
      <CardHeader className="d-flex align-items-center">
        <div className="text-muted flex-grow-1 d-flex align-items-center">
          <h6 className="mb-0 fw-semibold text-uppercase">Alias Names</h6>
        </div>
        <div className="flex-shrink-0 d-flex align-items-center">
          <Permission resource={"person"} action={"update"}>
            <Link
              to="#"
              onClick={(e) => {
                e.stopPropagation(); // Prevent collapse toggle on edit click
                showUpdateModal();
              }}
              className="link-primary link-offset-2 text-decoration-underline link-underline-opacity-25 link-underline-opacity-100-hover me-3"
            >
              <i className="ri-pencil-line"></i> Edit
            </Link>
          </Permission>
        </div>
      </CardHeader>

      <CardBody className="card-body ">
        <Row className="g-3">
          <Col md={12}>
            <p className="fw-semibold text-body mb-1">
              {aliasNames.map((el: any) => el.name).join(", ")}
            </p>
          </Col>
        </Row>
      </CardBody>
      <UpdateAliasNames
        isModalOpen={isUpdateModalOpen}
        toggle={() => {
          setIsUpdateModalOpen((state) => !state);
        }}
        person={person}
        fetchData={() => {
          dispatch(
            onGetPerson({
              id: person?.id,
            })
          );
        }}
      />
    </Card>
  );
};

export default AliasNames;
