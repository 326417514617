import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  forgetSuccessMsg: null,
  forgetError: null,
  loading: false,
};

const ResetPasswordSlice = createSlice({
  name: "forgotpwd",
  initialState,
  reducers: {
    userResetPasswordSuccess(state, action) {
      state.forgetSuccessMsg = action.payload;
    },
    userResetPasswordError(state, action) {
      state.loading = false;
      state.forgetSuccessMsg = null;
      state.forgetError = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { userResetPasswordSuccess, userResetPasswordError, setLoading } =
  ResetPasswordSlice.actions;

export default ResetPasswordSlice.reducer;
