import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";

import { Autoplay } from "swiper/modules";
import SwiperCore from "swiper";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import AllResults from "./SearchTabs/AllResults";
import Contractors from "./SearchTabs/Contractors";
import Contracts from "./SearchTabs/Contracts";
import Assessments from "./SearchTabs/Assessments";
import {
  ICON_ASSESSMENTS,
  ICON_CONTRACTORS,
  ICON_CONTRACTS,
  ICON_PEOPLE,
  ICON_SANCTIONS,
} from "common/constants";
import SanctionsList from "./SearchTabs/SantionsList";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { setSearchValue } from "slices/search/reducer";
import { Link, useSearchParams } from "react-router-dom";
import DownloadSearchReport from "./DownloadSearchReport";
import BulkSearch from "./BulkSearch";
import Persons from "./SearchTabs/Persons";

const SearchResults = () => {
  SwiperCore.use([Autoplay]);

  const dispatch: any = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  //Tab
  const [activeTab, setActiveTab] = useState(searchParams.get("tab") || "1");
  const toggleTab = (tab: React.SetStateAction<string>) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      setSearchParams(
        (params) => {
          const newParams = new URLSearchParams(params);
          newParams.set("tab", tab?.toString());
          return newParams;
        },
        { replace: true }
      );
    }
  };
  useEffect(() => {
    if (activeTab !== searchParams.get("tab")) {
      setActiveTab(searchParams.get("tab") || "1");
    }
  }, [activeTab, searchParams]);

  const selectProperties = createSelector(
    (state: any) => state.Search,
    (state) => ({
      globalSearchValue: state.globalSearchValue,
    })
  );
  const { globalSearchValue: searchValue } = useSelector(selectProperties);

  useEffect(() => {
    if (searchValue === "") {
      dispatch(setSearchValue(searchParams.get("search") || ""));
    }
  }, [dispatch, searchParams, searchValue]);

  document.title = "Due Diligence Search | CIMS";

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <BreadCrumb
            title="Due Diligence Search"
            pageTitles={[{ title: "Dashboard", link: "/dashboard" }]}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row className="">
                    <Col lg={12}>
                      <Row className="g-2">
                        <Col sm={10}>
                          <div className="position-relative">
                            <Input
                              type="text"
                              className="form-control form-control-lg bg-light border-light"
                              style={{
                                paddingRight: "120px",
                              }}
                              placeholder="Search here..."
                              value={searchValue}
                              onChange={(e) => {
                                dispatch(setSearchValue(e.target.value));

                                setSearchParams(
                                  (params) => {
                                    const newParams = new URLSearchParams(
                                      params
                                    );
                                    newParams.set("search", e.target.value);
                                    return newParams;
                                  },
                                  { replace: true }
                                );
                              }}
                            />

                            <div className="position-absolute end-0 top-0 d-flex align-items-center ">
                              <Link
                                to="#"
                                className="btn btn-link link-muted btn-lg"
                                onClick={() => {
                                  if (searchValue.length > 0) {
                                    dispatch(setSearchValue(""));
                                    setSearchParams(
                                      (params) => {
                                        const newParams = new URLSearchParams(
                                          params
                                        );
                                        newParams.delete("search");
                                        return newParams;
                                      },
                                      { replace: true }
                                    );
                                  }
                                }}
                              >
                                <i
                                  className={
                                    searchValue?.length > 0
                                      ? "mdi mdi-close-circle fs-18 text-muted"
                                      : "ri-search-line text-muted"
                                  }
                                ></i>
                              </Link>
                              <span className="text-muted fs-18 ">|</span>

                              <BulkSearch />
                            </div>
                          </div>
                        </Col>
                        <Col sm={2}>
                          <DownloadSearchReport />
                        </Col>
                        {/* <div className="col-auto">
                          <BulkSearch />
                        </div> */}
                      </Row>
                    </Col>
                    {/* {searchValue && (
                      <Col lg={12}>
                        <h5 className="fs-16 fw-semibold text-center mb-0 mt-3">
                          Showing results for "
                          <span className="text-primary fw-medium fst-italic">
                            {searchValue}
                          </span>{" "}
                          "
                        </h5>
                      </Col>
                    )} */}
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col lg={12}>
              <Card className="">
                <div>
                  <Nav
                    className="nav-tabs nav-tabs-custom flex-grow-1 "
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames({
                          active: activeTab === "1",
                          "py-3": true,
                        })}
                        onClick={() => {
                          toggleTab("1");
                        }}
                      >
                        <i className="ri-search-2-line text-muted align-bottom me-1"></i>{" "}
                        All Results
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames({
                          active: activeTab === "2",
                          "py-3": true,
                        })}
                        onClick={() => {
                          toggleTab("2");
                        }}
                      >
                        <i
                          className={`${ICON_CONTRACTORS} text-muted align-bottom me-1`}
                        ></i>
                        Contractors
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames({
                          active: activeTab === "3",
                          "py-3": true,
                        })}
                        onClick={() => {
                          toggleTab("3");
                        }}
                      >
                        <i
                          className={`${ICON_CONTRACTS} text-muted align-bottom me-1`}
                        ></i>
                        Contracts
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames({
                          active: activeTab === "4",
                          "py-3": true,
                        })}
                        onClick={() => {
                          toggleTab("4");
                        }}
                      >
                        <i
                          className={`${ICON_ASSESSMENTS} text-muted align-bottom me-1`}
                        ></i>{" "}
                        Assessments
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames({
                          active: activeTab === "5",
                          "py-3": true,
                        })}
                        onClick={() => {
                          toggleTab("5");
                        }}
                      >
                        <i
                          className={`${ICON_PEOPLE} text-muted align-bottom me-1`}
                        ></i>{" "}
                        Persons
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames({
                          active: activeTab === "6",
                          "py-3": true,
                        })}
                        onClick={() => {
                          toggleTab("6");
                        }}
                      >
                        <i
                          className={`${ICON_SANCTIONS} text-muted align-bottom me-1`}
                        ></i>{" "}
                        Sanctions
                      </NavLink>
                    </NavItem>

                    {/* <li className="nav-item ms-auto">
                      <DownloadSearchReport />
                    </li> */}
                  </Nav>
                </div>
                <CardBody className="p-4">
                  <TabContent activeTab={activeTab} className="text-muted">
                    <TabPane tabId="1">
                      <AllResults
                        searchValue={searchValue}
                        activeTab={activeTab}
                      />
                    </TabPane>
                    <TabPane tabId="2">
                      <Contractors
                        searchValue={searchValue}
                        activeTab={activeTab}
                      />
                    </TabPane>
                    <TabPane tabId="3">
                      <Contracts
                        searchValue={searchValue}
                        activeTab={activeTab}
                      />
                    </TabPane>
                    <TabPane tabId="4">
                      <Assessments
                        searchValue={searchValue}
                        activeTab={activeTab}
                      />
                    </TabPane>
                    <TabPane tabId="5">
                      <Persons
                        searchValue={searchValue}
                        activeTab={activeTab}
                      />
                    </TabPane>
                    <TabPane tabId="6">
                      <SanctionsList
                        searchValue={searchValue}
                        activeTab={activeTab}
                      />
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SearchResults;
