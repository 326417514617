import React from "react";
import {
  Input,
  FormFeedback,
  Button,
  Card,
  CardBody,
  Col,
  Row,
} from "reactstrap";

interface AliasNamesInputProps {
  validation: any;
}

const AliasNamesInput = ({ validation }: AliasNamesInputProps) => {
  return (
    <>
      <Card className="bg-light">
        <CardBody>
          {validation.values.previousOrAliasName.map(
            (name: string, index: number) => (
              <div key={index} className="my-2">
                <Row>
                  <Col xl="11" className="mb-3 mb-xl-0">
                    <Input
                      name={`previousOrAliasName.${index}`}
                      id={`previousOrAliasName.${index}`}
                      className="form-control"
                      placeholder="Enter alias name"
                      type="text"
                      validate={{
                        required: { value: true },
                      }}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={name}
                      invalid={
                        validation.touched.previousOrAliasName &&
                        validation.touched.previousOrAliasName[index] &&
                        validation.errors.previousOrAliasName &&
                        validation.errors.previousOrAliasName[index]
                          ? true
                          : false
                      }
                    />
                    {validation.touched.previousOrAliasName &&
                    validation.touched.previousOrAliasName[index] &&
                    validation.errors.previousOrAliasName &&
                    validation.errors.previousOrAliasName[index] ? (
                      <FormFeedback type="invalid">
                        {validation.errors?.previousOrAliasName &&
                          validation.errors.previousOrAliasName[index]}
                      </FormFeedback>
                    ) : null}
                  </Col>
                  <Col xl="1">
                    <Button
                      className="btn btn-sm btn-soft-danger waves-effect waves-light material-shadow-none w-100 h-100"
                      onClick={() => {
                        const names = [
                          ...validation.values.previousOrAliasName,
                        ];
                        names.splice(index, 1);
                        validation.setFieldValue("previousOrAliasName", names);
                      }}
                    >
                      {/* <i className="ri-delete-bin-fill me-2"></i> */}
                      Remove
                    </Button>
                  </Col>
                </Row>
              </div>
            )
          )}
        </CardBody>
      </Card>
      <button
        className="btn btn-soft-secondary waves-effect waves-light material-shadow-none"
        onClick={(e) => {
          e.preventDefault();
          if (validation.values.previousOrAliasName.length < 5) {
            validation.setFieldValue("previousOrAliasName", [
              ...validation.values.previousOrAliasName,
              "",
            ]);
          }
        }}
      >
        Add
      </button>
    </>
  );
};

export default AliasNamesInput;
