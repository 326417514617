import EmailInput from "pages/Persons/EmailInput";
import PhoneNumberInput from "pages/Persons/PhoneNumberInput";
import PhysicalAddressInput from "pages/Persons/PhysicalAddressinput";
import PostalAddressInput from "pages/Persons/PostalAddressInput";
import React, { useEffect, useRef } from "react";
import {
  Form,
  Label,
  FormFeedback,
  Input,
  Spinner,
  Row,
  Col,
  ModalBody,
} from "reactstrap";
import SimpleBar from "simplebar-react";

const ContactDetailsForm: React.FC<{
  toggle: () => void;
  loading: boolean;
  isUpdate?: boolean;
  validation: any;
  setLoading: (loading: boolean) => void;
}> = ({ validation, isUpdate, loading, setLoading, toggle }) => {
  const simpleBarRef: any = useRef(null);
  useEffect(() => {
    if (simpleBarRef.current) {
      const simpleBarInstance = simpleBarRef.current.getScrollElement();
      simpleBarInstance.scrollTop = simpleBarInstance.scrollHeight;
    }
  }, []);

  return (
    <Form
      className="tablelist-form"
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      <ModalBody className="px-0 py-0">
        <SimpleBar
          ref={simpleBarRef}
          style={{ maxHeight: "80vh" }}
          className=" px-4 py-4"
          autoHide={false}
        >
          <Row className="mb-4">
            <Col md={12} className="">
              <Label className="form-label">Phone Number</Label>
            </Col>
            <Col md={12} className="">
              <PhoneNumberInput validation={validation} />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col md={12} className="">
              <Label className="form-label">Email Address</Label>
              <EmailInput validation={validation} />
            </Col>
          </Row>

          <Row className="mb-4">
            <Col md={12} className="">
              <Label htmlFor="id-field" className="form-label">
                Website or Link
              </Label>
            </Col>
            <Col md={12} className="">
              <Input
                name="websiteOrLink"
                id="websiteOrLink"
                className="form-control"
                placeholder="Enter websiteOrLink"
                type="text"
                validate={{
                  required: { value: true },
                }}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.websiteOrLink || ""}
                invalid={
                  validation.touched.websiteOrLink &&
                  validation.errors.websiteOrLink
                    ? true
                    : false
                }
              />
              {validation.touched.websiteOrLink &&
              validation.errors.websiteOrLink ? (
                <FormFeedback type="invalid">
                  {validation.errors.websiteOrLink}
                </FormFeedback>
              ) : null}
            </Col>
          </Row>
          <Row className="mb-4">
            <Col md={12} className="">
              <Label className="form-label">Physical Address</Label>
            </Col>
            <Col md={12} className="">
              <PhysicalAddressInput validation={validation} />
            </Col>
          </Row>
          <Row className="mb-4">
            <Col md={12} className="">
              <Label className="form-label">Postal Address</Label>
            </Col>
            <Col md={12} className="">
              <PostalAddressInput validation={validation} />
            </Col>
          </Row>
        </SimpleBar>
      </ModalBody>

      <div className="modal-footer">
        <div className="hstack gap-2 justify-content-end">
          <button
            type="button"
            className="btn btn-light"
            onClick={() => {
              toggle();
            }}
          >
            Close
          </button>

          <button type="submit" className="btn btn-primary" disabled={loading}>
            {loading && (
              <Spinner
                color="light"
                size="sm"
                style={{ marginRight: "8px", marginBottom: "-1px" }}
              ></Spinner>
            )}
            {"Save Changes"}
          </button>
        </div>
      </div>
    </Form>
  );
};

export default ContactDetailsForm;
