import React, { useCallback, useMemo, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Row, Container } from "reactstrap";

import TableContainer from "Components/Common/TableContainer";
import BreadCrumb from "Components/Common/BreadCrumb";

import { getSortObj } from "common/lib/tableDataOptions";
import * as moment from "moment";
import { getUnDesignatedList } from "common/services/sanctions-list.service";
import { normalize } from "common/lib/normalize";
import { toast } from "react-toastify";
import UNListTypeFilter from "./UNListTypeFilter";

interface FilterValue {
  designateType: string;
  unListType: string;
}

const UNDesignatedList = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [params] = useState({
    page: Number(searchParams.get("page")) || 1, // Starts from 1
    pageSize: Number(searchParams.get("pageSize")) || 20, // Default page size
  });

  const [pagination, setPagination] = useState({
    pageIndex: params.page - 1, // Convert to zero-based index
    pageSize: params.pageSize,
  });

  // Update pageIndex
  const setPage = (newPage: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      pageIndex: newPage - 1,
    }));
  };

  const [sorted, setSorted] = useState(() => {
    const sortParam = searchParams.get("sort");
    return sortParam
      ? JSON.parse(sortParam)
      : [{ id: "createdAt", desc: true }];
  });

  const [searchValue, setSearchValue] = useState(
    searchParams.get("search") || ""
  );
  const defaultFilterValue: FilterValue = {
    designateType: "",
    unListType: "",
  };
  const [filterValue, setFilterValue] = useState(() => {
    const filterParam = searchParams.get("filter");
    return filterParam ? JSON.parse(filterParam) : defaultFilterValue;
  });

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState<any>({});
  const onPageChange = useCallback(
    async ({
      page,
      sorted,
      searchValue,
    }: {
      page: number;
      sorted: Array<{ id: string; desc: boolean }>;
      searchValue: string;
    }) => {
      const params = new URLSearchParams(searchParams);
      params.set("page", String(page));
      params.set("sort", JSON.stringify(sorted));
      params.set("search", searchValue);
      // params.set("filter", JSON.stringify(filterValue));

      setSearchParams(params, { replace: true });

      const sortObj = getSortObj(sorted);
      let andConditions: any[] = [];
      if (filterValue.designateType) {
        andConditions.push({ designateType: filterValue.designateType });
      }
      if (filterValue.unListType) {
        andConditions.push({ unListType: filterValue.unListType });
      }
      andConditions.push({
        $or: [
          {
            title: {
              $containsi: searchValue,
            },
          },
          {
            aliasNames: {
              $containsi: searchValue,
            },
          },
          {
            reference: {
              $containsi: searchValue,
            },
          },
        ],
      });

      try {
        setLoading(true);
        const response: any = await getUnDesignatedList({
          pagination: {
            page,
            pageSize: pagination.pageSize,
          },
          sort: sortObj,
          populate: [],
          filters: {
            $and: andConditions,
          },
        });
        setData(normalize(response.data));
        setMeta(response.meta);
      } catch (error) {
        toast.error("Error while fetching data", {});
      } finally {
        setLoading(false);
      }
    },
    [
      filterValue.designateType,
      filterValue.unListType,
      pagination.pageSize,
      searchParams,
      setSearchParams,
    ]
  );

  const columns = useMemo(
    () => [
      {
        header: "Full Name",
        accessorKey: "title",
        id: "title",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const wrappedText = cell
            .getValue()
            .trim()
            .match(/.{1,80}/g)
            .join("\n");

          return (
            <div style={{ whiteSpace: "pre-wrap", width: "300px" }}>
              <Link
                to={`/sanctions-list/un-designated/${cell.row.original?.id}`}
                className="fw-medium link-primary"
              >
                {wrappedText}
              </Link>
            </div>
          );
        },
      },
      {
        header: "Reference",
        accessorKey: "reference",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => {
          return cell.getValue() || "Not Provided";
        },
      },
      {
        header: "Designate Type",
        accessorKey: "designateType",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => {
          return cell.getValue() || "Not Provided";
        },
      },
      {
        header: "Address",
        accessorKey: "address",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => {
          return (
            <div className="fixed-table-column-width-2" style={{ width: 200 }}>
              {cell.getValue() || "Not Provided"}
            </div>
          );
        },
      },
      {
        header: "Alias Names",
        accessorKey: "aliasNames",
        id: "aliasNames",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div className="fixed-table-column-width-2">
              {cell.getValue() || "Not Provided"}
            </div>
          );
        },
      },
      {
        header: "Last Updated At",
        accessorKey: "updatedAt",
        id: "updatedAt",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const date = moment(new Date(cell?.getValue())).format("DD MMM Y");
          return <>{date}</>;
        },
      },
    ],
    []
  );

  document.title = "UN Designated List | CIMS";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="UN Designated List"
            pageTitles={[
              { title: "Dashboard", link: "/dashboard" },
              {
                title: "Sanctions List",
                link: "/sanctions-list/un-designated",
              },
            ]}
          />
          <Card>
            <CardBody>
              <Row className="g-3">
                <Col xxl={6} lg={6}>
                  <h6 className="text-uppercase fs-12 mb-2">Search</h6>
                  <div className="search-box">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search un designated list..."
                      onChange={(e) => {
                        setSearchValue(e.target.value);
                        setPage(1);
                      }}
                      value={searchValue}
                    />
                    <i className="ri-search-line search-icon"></i>
                  </div>
                </Col>
                <Col xxl={2} lg={6}>
                  <h6 className="text-uppercase fs-12 mb-2">Designate Type</h6>
                  <select
                    className="form-control"
                    name="designateType"
                    id="designateType"
                    onChange={(e) => {
                      setFilterValue((prev: any) => ({
                        ...prev,
                        designateType: e.target.value,
                      }));
                    }}
                    value={filterValue.designateType}
                  >
                    {loading && <option>Loading...</option>}
                    <option value="">Select Designate Type</option>
                    {[
                      { value: "Individual", label: "Individual" },
                      { value: "Entity", label: "Entity" },
                    ].map((option: any) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </Col>
                <Col xxl={2} lg={6}>
                  <h6 className="text-uppercase fs-12 mb-2">UN List Type</h6>
                  <UNListTypeFilter
                    onChange={(value) => {
                      setFilterValue({ ...filterValue, unListType: value });
                      setPage(1);
                    }}
                    value={filterValue.unListType}
                  />
                </Col>
                <Col xxl={2} lg={4}>
                  <h6 className="text-uppercase fs-12 mb-xxl-4"> {` `}</h6>

                  <button
                    onClick={() => {
                      setFilterValue(defaultFilterValue);
                      setSearchValue("");
                      setPage(1);
                      setSorted([{ id: "createdAt", desc: true }]);
                    }}
                    className="btn btn-outline-primary material-shadow-none w-100"
                  >
                    <i className="ri-equalizer-line align-bottom me-1"></i>{" "}
                    Reset Filters
                  </button>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card>
            <CardHeader>
              <Row className="align-items-center g-3">
                <Col md={12}>
                  <h5 className="card-title">UN Designated List</h5>
                  <p className="mb-0 text-muted">
                    The filter below runs a search of a name or entity through
                    our consolidated UN designated persons and entities. This
                    includes the list of the following committees and/or UN
                    Security Council resolutions , that is
                    <i className="me-1">
                      267 (Al- Qaida),1518 (Iraq), 1737 (Iran), 1988 (Taliban),
                      1521 (Liberia), 1533 (DRC), 1572 (Cote D'Ivorie), 1591
                      (Sudan), 751 ( Somalia and Eritrea, also includes the 1844
                      list), 1718 Democratic People's Republic of Korea (North
                      Korea) and 1970 (Libya).
                    </i>
                    <br className="mb-1" />
                    For more information please visit the
                    <a
                      href="https://main.un.org/securitycouncil/en/content/resolutions-adopted-security-council-2025"
                      className="mx-1 text-primary"
                    >
                      UNSCR Website.
                    </a>
                  </p>
                </Col>
                {/* <div className="col-md-auto ms-auto">
                  <div className="d-flex gap-2"></div>
                </div> */}
              </Row>
            </CardHeader>
            <CardBody>
              <TableContainer
                loading={loading}
                columns={columns}
                data={data || []}
                pagination={pagination}
                setPagination={setPagination}
                sorted={sorted}
                setSorted={setSorted}
                onPageChange={onPageChange}
                FilterSection={() => {}}
                totalRows={meta?.pagination?.total || 0}
                totalPages={meta?.pagination?.pageCount || 0}
                searchValue={searchValue}
                divClass="table-responsive table-card mb-4"
                tableClass="align-middle table-nowrap mb-0 table-striped"
                theadClass="table-light table-nowrap"
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UNDesignatedList;
