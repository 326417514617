import { createAsyncThunk } from "@reduxjs/toolkit";

//Include Both Helper File with needed methods
import { searchClient } from "helpers/meiisearch_helper";

import { setLoading, setPage } from "./reducer";

export const getAllSearchResults = createAsyncThunk(
  "search/getAllSearchResults",
  async (params: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      dispatch(setPage(1));
      const response = await searchClient
        .index("contract")
        .search(params?.query, {
          hitsPerPage: 10,
          page: 1,
          // filter: 'countryCode = "SO"',
        });
      return response;
    } catch (error) {
      return error;
    }
  }
);
