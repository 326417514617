import { ICON_CALENDAR } from "common/constants";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";

const PersonItem = ({ data, index }: { data: any; index: number }) => {
  console.log("Persons Data: ", data);

  const details = [
    data.gender && `Gender: ${data.gender}`,
    data.firstName && `First Name: ${data.firstName}`,
    data.middleName && `Middle Name: ${data.middleName}`,
    data.surname && `Surname: ${data.surname}`,
    data.dateOfBirth && `Date of Birth: ${data.dateOfBirth}`,
    data?.alias?.length > 0 &&
      `Alias Names: ${data.alias
        .map((val: { name: any }) => val.name)
        .join(", ")}`,
    data?.currentNationality?.name &&
      `Current Nationality: ${data.currentNationality.name}`,
    data?.nationalityAtBirth?.name &&
      `Nationality at Birth: ${data.nationalityAtBirth.name}`,
    data?.phoneNumbers?.length > 0 &&
      `Phone Number: ${data.phoneNumbers[0].number}`,
    data?.emails?.length > 0 && `Email: ${data.emails[0].email}`,
    data?.physicalAddress?.length > 0 &&
      `Physical Address: ${data.physicalAddress[0].address}`,
    data?.contractorAffiliations?.length > 0 &&
      `Contractor Affiliations: Exists`,
  ].filter(Boolean);

  const detailsString = useMemo(() => details.join(", "), [details]);

  return (
    <div className={index === 0 ? "pb-3" : "py-3"}>
      <h5 className="mb-1">
        <Link to={`/persons/${data.id}`}>{`${data?.fullName || ""}`}</Link>
      </h5>
      <p className="text-success mb-2">
        <span className="me-2">Person</span>
        {data?.semgMentions && data?.semgMentions?.length > 0 && (
          <span className="badge bg-warning-subtle text-warning fs-11 me-2">
            SEMG Mention
          </span>
        )}
      </p>
      <p className="text-muted mb-2">{`... ${detailsString} ...
      `}</p>
      <ul className="list-inline d-flex align-items-center g-3 text-muted fs-14 mb-0">
        <li className="list-inline-item">
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0">
              <i className={ICON_CALENDAR}></i>
            </div>
            <div className="flex-grow-1 fs-13 ms-1">
              <span className="fw-medium">12 Feb 2024</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default PersonItem;
