import React, { useState } from "react";
import { Modal, ModalHeader } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { updatePerson } from "common/services/persons.service";
import { toast } from "react-toastify";
import PersonForm from "./PersonForm";

const UpdatePerson: React.FC<{
  toggle: () => void;
  isModalOpen: boolean;
  fetchData: () => void;
  person: any;
}> = ({ toggle, isModalOpen, person, fetchData }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: person?.title || "",
      firstName: person?.firstName || "",
      surname: person?.surname || "",
      middleName: person?.middleName || "",
      gender: person?.gender || "",
      dateOfBirth: person?.dateOfBirth || "",
      currentNationality: {
        value: person?.currentNationality?.id || "",
        label: person?.currentNationality?.name || "",
      },
      nationalityAtBirth: {
        value: person?.nationalityAtBirth?.id || "",
        label: person?.nationalityAtBirth?.name || "",
      },
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please select title"),
      firstName: Yup.string().required("Please enter first name"),
      surname: Yup.string().required("Please enter surname"),
      middleName: Yup.string().required("Please enter middle name"),
      currentNationality: Yup.object().shape({
        value: Yup.number().required("Please select current nationality"),
      }),
      nationalityAtBirth: Yup.object().shape({
        value: Yup.number().required("Please select nationality at birth"),
      }),
      gender: Yup.string(),
      dateOfBirth: Yup.string(),
    }),
    onSubmit: (values: any) => {
      const data = {
        id: person.id,
        data: {
          title: values.title,
          firstName: values.firstName,
          surname: values.surname,
          middleName: values.middleName,
          currentNationality: values.currentNationality.value || null,
          nationalityAtBirth: values.nationalityAtBirth.value || null,
          gender: values.gender || null,
          dateOfBirth: values.dateOfBirth || null,
        },
      };

      setLoading(true);
      const saveData = async () => {
        try {
          await updatePerson(data);
          validation.resetForm();
          toggle();
          toast.success("Contractor Updated Successfully", {
            autoClose: 1000,
          });
        } catch (err: any) {
          toast.error("Error while updating person", {
            autoClose: 1000,
          });
        } finally {
          setLoading(false);
          fetchData();
        }
      };
      saveData();
    },
  });

  return (
    <Modal
      id="showModal"
      size="lg"
      isOpen={isModalOpen}
      toggle={toggle}
      centered
    >
      <ModalHeader className="bg-light p-3" toggle={toggle}>
        Update Contractor Details
      </ModalHeader>

      <PersonForm
        toggle={toggle}
        validation={validation}
        isUpdate
        loading={loading}
        setLoading={setLoading}
      />
    </Modal>
  );
};

export default UpdatePerson;
