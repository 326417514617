import React, { useCallback, useState } from "react";
import { Button } from "reactstrap";
import { downloadSearchReport } from "common/services/search.service";
import { toast } from "react-toastify";
import ExportLoadingModal from "Components/Common/ExportLoadingModal";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";

const DownloadSearchReport = () => {
  const selectProperties = createSelector(
    (state: any) => state.Search,
    (state) => ({
      globalSearchValue: state.globalSearchValue,
    })
  );
  const { globalSearchValue: searchValue } = useSelector(selectProperties);

  const [exporting, setExporting] = useState(false);
  const downloadReport = useCallback(async () => {
    if (
      searchValue === "" ||
      searchValue === null ||
      searchValue === undefined ||
      !searchValue
    ) {
      return toast.warning("Please enter a search term to download the report");
    }
    setExporting(true);

    await downloadSearchReport({
      query: searchValue,
    })
      .then((response: any) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Search_Report_${searchValue}.pdf`);
        link.click();
        link.remove();
      })
      .catch((error: any) => {
        toast.error("An error occurred while downloading search report");
      });

    setExporting(false);
  }, [searchValue]);

  return (
    <>
      <Button
        color="primary"
        className="btn w-100 h-100"
        onClick={downloadReport}
      >
        <i className="ri-download-line align-middle me-1 "></i> Download Results
      </Button>

      {/* <ButtonGroup className="material-shadow">
        <UncontrolledButtonDropdown id="btnGroupDrop1">
          <DropdownToggle color="link" className="material-shadow-none">
            <i className="ri-download-line align-middle me-1 text-link"></i>{" "}
            Download Results
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem> PDF </DropdownItem>
            <DropdownItem> XlSX </DropdownItem>
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      </ButtonGroup> */}
      <ExportLoadingModal
        show={exporting}
        onCloseClick={() => {}}
        message="Downloading search results..."
      />
    </>
  );
};

export default DownloadSearchReport;
