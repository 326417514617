import { getAgencies } from "common/services/agencies.service";
import React, { useEffect } from "react";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";

const UNAgencies = () => {
  const [agencyCount, setAgencyCount] = React.useState(0);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response: any = await getAgencies({
          pagination: {
            page: 1,
            pageSize: 100,
          },
          filters: {
            $and: [
              {
                category: {
                  name: {
                    $eq: "UN",
                  },
                },
              },
            ],
          },
        });
        setAgencyCount(response.meta.pagination.total);
      } catch (e: any) {
        console.warn(e);
      }
    };
    fetchData();
  }, []);

  return (
    <Card className="card-animate">
      <CardBody>
        <div className="d-flex align-items-center">
          <div className="flex-grow-1 overflow-hidden">
            <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
              UN Agencies
            </p>
          </div>
        </div>
        <div className="d-flex align-items-end justify-content-between mt-4">
          <div>
            <h4 className="fs-22 fw-semibold ff-secondary mb-4">
              <span className="counter-value" data-target="559.25">
                <CountUp
                  start={0}
                  prefix={""}
                  suffix={""}
                  separator={","}
                  end={agencyCount} // Change this value to change the counter value
                  decimals={0}
                  duration={4}
                />
              </span>
            </h4>
            <Link to="/un-agencies" className="text-decoration-underline">
              View all agencies
            </Link>
          </div>
          <div className="avatar-sm flex-shrink-0">
            <span className={"avatar-title rounded fs-3 bg-info-subtle"}>
              <i className={`text-info bx bx-shopping-bag `}></i>
            </span>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default UNAgencies;
