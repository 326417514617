import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Row,
  Col,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  CardHeader,
} from "reactstrap";
import UpdateContactDetails from "./UpdateContactDetails";
import { getContractor as onGetContractor } from "slices/thunks";
import Permission from "Components/Common/Permission";

const ContactDetails = ({ contractor }: { contractor: any }) => {
  const [openFlush, setOpenFlush] = useState("1");
  const toggleFlush = (id: any) => {
    if (openFlush !== id) {
      setOpenFlush(id);
    }
  };

  const dispatch: any = useDispatch();

  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const showUpdateModal = () => {
    setIsUpdateModalOpen(true);
  };

  return (
    <Card>
      <CardHeader className="d-flex align-items-center">
        <div className="text-muted flex-grow-1 d-flex align-items-center">
          <h6 className="mb-0 fw-semibold text-uppercase">Contact Details</h6>
        </div>
        <div className="flex-shrink-0 d-flex align-items-center">
          <Permission resource={"contractor"} action={"update"}>
            <Link
              to="#"
              onClick={(e) => {
                e.stopPropagation(); // Prevent collapse toggle on edit click
                showUpdateModal();
              }}
              className="link-primary link-offset-2 text-decoration-underline link-underline-opacity-25 link-underline-opacity-100-hover me-3"
            >
              <i className="ri-pencil-line"></i> Edit
            </Link>
          </Permission>
        </div>
      </CardHeader>

      <CardBody className="card-body px-0 pt-0">
        <Row className="g-3">
          <Accordion
            id="default-accordion-example"
            flush
            open={openFlush}
            toggle={toggleFlush}
          >
            <AccordionItem className="material-shadow">
              <AccordionHeader targetId="1">Business Contacts</AccordionHeader>
              <AccordionBody accordionId="1">
                <Row>
                  <Col sm={6} className="">
                    <div className="d-flex mb-2 align-items-baseline">
                      <div className="flex-shrink-0">
                        <div className="avatar-xxs">
                          <div className="avatar-title bg-light text-danger rounded fs-17">
                            <i className="ri-phone-line text-primary"></i>
                          </div>
                        </div>
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <p className=" mb-1" id="billing-address-line-1">
                          <span className="fw-semibold text-body">Phone: </span>
                          <span>
                            {(contractor?.phoneNumber &&
                              contractor?.phoneNumber
                                .map((el: any) => el.name || "")
                                .join(", ")) ||
                              "Not Provided"}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="d-flex mb-2 align-items-baseline">
                      <div className="flex-shrink-0">
                        <div className="avatar-xxs">
                          <div className="avatar-title bg-light text-danger rounded fs-17">
                            <i className="ri-mail-line text-primary"></i>
                          </div>
                        </div>
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <p className=" mb-1" id="billing-address-line-1">
                          <span className="fw-semibold text-body">Email: </span>
                          <span>
                            {(contractor?.emailAddress &&
                              contractor.emailAddress
                                .map((email: any) => email.name || "")
                                .join(", ")) ||
                              "Not Provided"}
                          </span>
                        </p>
                      </div>
                    </div>

                    <div className="d-flex mb-2 align-items-baseline">
                      <div className="flex-shrink-0">
                        <div className="avatar-xxs">
                          <div className="avatar-title bg-light text-danger rounded fs-17">
                            <i className="ri-map-pin-2-line text-primary"></i>
                          </div>
                        </div>
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <p className=" mb-1" id="billing-address-line-1">
                          <span className="fw-semibold text-body">
                            Physical Address:{" "}
                          </span>
                          <span>
                            {(contractor?.physicalAddress &&
                              contractor.physicalAddress
                                .map(
                                  (physical: any) =>
                                    `${physical.address || ""} ${
                                      physical.city || ""
                                    } ${physical.country || ""}`
                                )
                                .join(", ")) ||
                              "Not Provided"}
                          </span>
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col sm={6} className="">
                    <div className="d-flex mb-2 align-items-baseline">
                      <div className="flex-shrink-0">
                        <div className="avatar-xxs">
                          <div className="avatar-title bg-light text-danger rounded fs-17">
                            <i className="ri-map-pin-user-line text-primary"></i>
                          </div>
                        </div>
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <p className=" mb-1" id="billing-address-line-1">
                          <span className="fw-semibold text-body">
                            Postal Address:{" "}
                          </span>
                          <span>
                            {(contractor?.postalAddress &&
                              contractor.postalAddress
                                .map(
                                  (postal: any) =>
                                    `${postal.address || ""} ${
                                      postal.city || ""
                                    } ${postal.country || ""}`
                                )
                                .join(", ")) ||
                              "Not Provided"}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="d-flex mb-2 align-items-baseline">
                      <div className="flex-shrink-0">
                        <div className="avatar-xxs">
                          <div className="avatar-title bg-light text-danger rounded fs-17">
                            <i className="ri-global-line text-primary"></i>
                          </div>
                        </div>
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <p className=" mb-1" id="billing-address-line-1">
                          <span className="fw-semibold text-body me-1">
                            Business Website:
                          </span>
                          <span>{contractor?.website || "Not Provided"}</span>
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </AccordionBody>
            </AccordionItem>
            {contractor?.contactPersons?.map((el: any, index: number) => {
              const actualId = `${index + 2}`;
              const contactPersonCount = index + 1;
              return (
                <AccordionItem className="material-shadow" key={index}>
                  <AccordionHeader targetId={actualId}>
                    Contact Person {contactPersonCount}
                  </AccordionHeader>
                  <AccordionBody accordionId={actualId}>
                    <Row>
                      <Col sm={6}>
                        <div className="d-flex">
                          <div className="flex-shrink-0">
                            <div className="avatar-sm">
                              <div className="avatar-title bg-light text-danger rounded fs-24">
                                <i className="ri-user-line text-primary"></i>
                              </div>
                            </div>
                          </div>
                          <div className="flex-grow-1 ms-3">
                            <p
                              className="text-muted mb-1"
                              id="shipping-address-line-1"
                            >
                              <span className="fw-semibold text-body">
                                Name:
                              </span>{" "}
                              {el.emailAddress}
                            </p>
                            <h6 className="mb-1 fs-14 text-muted">
                              {" "}
                              {el.name}
                            </h6>

                            {/* <p className="text-muted mb-1">
                              <span className="fw-semibold text-body">
                                Phone:{" "}
                              </span>
                              <span id="shipping-phone-no">
                                {" "}
                                {el?.mobileNumber}
                              </span>
                            </p> */}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </AccordionBody>
                </AccordionItem>
              );
            })}
          </Accordion>
        </Row>
      </CardBody>
      <UpdateContactDetails
        isModalOpen={isUpdateModalOpen}
        toggle={() => setIsUpdateModalOpen((state) => !state)}
        contractor={contractor}
        fetchData={() => {
          dispatch(
            onGetContractor({
              id: contractor?.id,
            })
          );
        }}
      />
    </Card>
  );
};

export default ContactDetails;
