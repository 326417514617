import React from "react";
import {
  Label,
  Input,
  FormFeedback,
  Button,
  Card,
  CardBody,
  Col,
  Row,
} from "reactstrap";

interface EmailInputProps {
  validation: any;
}

const EmailInput = ({ validation }: EmailInputProps) => {
  return (
    <>
      <Card className="bg-light">
        <CardBody>
          {validation.values.emailAddress.map((name: string, index: number) => (
            <div key={index} className="my-2">
              <Row>
                <Col xl="11" className="mb-3 mb-xl-0">
                  <Input
                    name={`emailAddress.${index}`}
                    id={`emailAddress.${index}`}
                    className="form-control"
                    placeholder="Enter email address"
                    type="text"
                    validate={{
                      required: { value: true },
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={name}
                    invalid={
                      validation.touched.emailAddress &&
                      validation.touched.emailAddress[index] &&
                      validation.errors.emailAddress &&
                      validation.errors.emailAddress[index]
                        ? true
                        : false
                    }
                  />
                  {validation.touched.emailAddress &&
                  validation.touched.emailAddress[index] &&
                  validation.errors.emailAddress &&
                  validation.errors.emailAddress[index] ? (
                    <FormFeedback type="invalid">
                      {validation.errors?.emailAddress &&
                        validation.errors.emailAddress[index]}
                    </FormFeedback>
                  ) : null}
                </Col>
                <Col xl="1">
                  <Button
                    className="btn btn-sm btn-soft-danger waves-effect waves-light material-shadow-none w-100 h-100"
                    onClick={() => {
                      const names = [...validation.values.emailAddress];
                      names.splice(index, 1);
                      validation.setFieldValue("emailAddress", names);
                    }}
                  >
                    {/* <i className="ri-delete-bin-fill me-2"></i> */}
                    Remove
                  </Button>
                </Col>
              </Row>
            </div>
          ))}
        </CardBody>
      </Card>
      <button
        className="btn btn-soft-secondary waves-effect waves-light material-shadow-none"
        onClick={(e) => {
          e.preventDefault();
          if (validation.values.emailAddress.length < 5) {
            validation.setFieldValue("emailAddress", [
              ...validation.values.emailAddress,
              "",
            ]);
          }
        }}
      >
        Add
      </button>
    </>
  );
};

export default EmailInput;
