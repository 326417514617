import React, { useState } from "react";
import { Modal, ModalHeader } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { addRegion } from "common/services/regions.service";
import CountryForm from "./CountryForm";
import { toast } from "react-toastify";
import { COUNTRY_LEVEL } from "common/constants";

const AddCountry: React.FC<{
  toggle: () => void;
  isModalOpen: boolean;
  fetchData: () => void;
}> = ({ toggle, isModalOpen, fetchData }) => {
  const [loading, setLoading] = useState<boolean>(false);
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: "",
      altName1: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter name"),
      altName1: Yup.string(),
    }),
    onSubmit: async (values: any) => {
      const data = {
        data: {
          name: values["name"],
          altName1: values["altName1"],
          level: COUNTRY_LEVEL,
        },
      };
      // save new order
      setLoading(true);
      try {
        await addRegion(data);
        fetchData();
        validation.resetForm();
        toggle();
        toast.success("Country added successfully", {});
      } catch (e: any) {
        toast.error("Failed to create country", {});
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <Modal
      id="showModal"
      size="lg"
      isOpen={isModalOpen}
      toggle={toggle}
      centered
    >
      <ModalHeader className="bg-light p-3" toggle={toggle}>
        Add Country
      </ModalHeader>

      <CountryForm
        validation={validation}
        isUpdate={false}
        loading={loading}
        toggle={toggle}
        setLoading={setLoading}
      />
    </Modal>
  );
};

export default AddCountry;
