import * as url from "helpers/url_helper";
import { APIClient } from "helpers/api_helper";

const api = new APIClient();

export const getEUSanctionsList = (params: any) =>
  api.get(url.GET_EU_SANCTIONS_LIST, params);
export const getEUSanctionedItem = (id: any, params: any) =>
  api.get(url.GET_EU_SANCTIONED_ITEM + "/" + id, params);

export const getUSSanctionsList = (params: any) =>
  api.get(url.GET_US_SANCTIONS_LIST, params);
export const getUSSanctionedItem = (id: any, params: any) =>
  api.get(url.GET_US_SANCTIONED_ITEM + "/" + id, params);

export const getUKSanctionsList = (params: any) =>
  api.get(url.GET_UK_SANCTIONS_LIST, params);
export const getUKSanctionedItem = (id: any, params: any) =>
  api.get(url.GET_UK_SANCTIONED_ITEM + "/" + id, params);

export const getUNGMList = (params: any) => api.get(url.GET_UNGM_LIST, params);
export const getUNGMItem = (id: any, params: any) =>
  api.get(url.GET_UNGM_ITEM + "/" + id, params);

export const getUnDesignatedList = (params: any) =>
  api.get(url.GET_UN_DESIGNATED_LIST, params);
export const getUnDesignatedItem = (id: any, params: any) =>
  api.get(url.GET_UN_DESIGNATED_ITEM + "/" + id, params);

export const uploadUNGMList = (data: any) =>
  api.createAndUpload(url.UPLOAD_UNGM_LIST, data);

export const getWBBannedList = (params: any) =>
  api.get(url.GET_WB_BANNED_LIST, params);
export const getWBBannedItem = (id: any, params: any) =>
  api.get(url.GET_WB_BANNED_ITEM + "/" + id, params);
