import React, { useMemo, useEffect } from "react";
import { Label, FormFeedback, FormGroup } from "reactstrap";
import Select from "react-select/async";
import { getPersons } from "common/services/persons.service";
import { normalize } from "common/lib/normalize";
import {
  GroupBase,
  OptionsOrGroups,
} from "react-select/dist/declarations/src/types";

const SelectPerson = ({
  validation,
  currentId,
  index,
}: {
  validation: any;
  isUpdate?: boolean;
  currentId?: string;
  index: number;
}) => {
  const customSelectStyles = {
    control: (styles: any) => ({
      ...styles,
      borderColor:
        validation?.touched?.affiliations?.[index]?.affiliatedTo &&
        validation?.errors?.affiliations?.[index]?.affiliatedTo
          ? "red"
          : "#ced4da",
      "&:hover": {
        borderColor:
          validation?.touched?.affiliations?.[index]?.affiliatedTo &&
          validation?.errors?.affiliations?.[index]?.affiliatedTo
            ? "red"
            : "#ced4da",
      },
    }),
  };

  const [data, setData] = React.useState<any>([]);
  const [loading, setLoading] = React.useState<boolean>(false);

  const selectedIds = useMemo(() => {
    return validation.values.affiliations
      .map((el: { affiliatedTo: { value: string | number | null } }) => {
        const value = el.affiliatedTo.value;
        return typeof value === "number" ? value : null;
      })
      .filter((value: any): value is number => value !== null); // Filters out null values, ensures the type is number
  }, [validation.values.affiliations]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await getPersons({
          pagination: {
            page: 1,
            pageSize: 20,
          },
          filters: {
            id: {
              $ne: currentId,
            },
          },
        });
        setData(normalize(response.data));
      } catch (e) {
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [currentId]);

  const options = useMemo(() => {
    return data.map((el: any) => {
      return {
        value: el?.id,
        label: `${el?.fullName || ""}`,
      };
    });
  }, [data]);

  return (
    <FormGroup key={index}>
      <Label
        htmlFor={`affiliations.${index}.affiliatedTo`}
        className="form-label"
      >
        Person
      </Label>
      <Select
        name={`affiliations.${index}.affiliatedTo`}
        id={`affiliations.${index}.affiliatedTo`}
        value={
          validation.values.affiliations[index].affiliatedTo.value
            ? validation.values.affiliations[index].affiliatedTo
            : null
        }
        placeholder="Select affiliatedTo"
        onChange={(value: any) => {
          validation.setFieldValue(`affiliations.${index}.affiliatedTo`, value);
          validation.setFieldError(`affiliations.${index}.affiliatedTo`, "");
        }}
        defaultOptions={options}
        // @ts-ignore
        loadOptions={(
          inputValue: string,
          callback: (options: OptionsOrGroups<any, GroupBase<any>>) => void
        ) => {
          return getPersons({
            pagination: {
              page: 1,
              perPage: 20,
            },
            filters: {
              id: {
                $notIn: [currentId, ...selectedIds],
              },
              $or: [
                {
                  fullName: {
                    $containsi: inputValue,
                  },
                },
              ],
            },
          }).then((result: any) => {
            const parsedData = normalize(result.data);
            callback(
              parsedData.map((el: any) => {
                return {
                  value: el?.id,
                  label: `${el?.fullName || ""}`,
                };
              })
            );
          });
        }}
        onBlur={() =>
          validation.setFieldTouched(`affiliations.${index}.affiliatedTo`, true)
        }
        className={
          validation.touched.affiliations?.[index]?.affiliatedTo &&
          validation.errors.affiliations?.[index]?.affiliatedTo
            ? "is-invalid"
            : ""
        }
        styles={customSelectStyles}
        isLoading={loading}
      />
      <FormFeedback>
        {validation.errors.affiliations?.[index]?.affiliatedTo?.value}
      </FormFeedback>
    </FormGroup>
  );
};

export default SelectPerson;
