import { useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import {
  Alert,
  Card,
  CardBody,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  Progress,
  Spinner,
} from "reactstrap";
import * as Yup from "yup";
import SelectSearchFile from "./SelectSearchFile";
import {
  downloadBulkSearchTemplate,
  bulkSearch,
} from "common/services/search.service";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

const BulkSearch = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [resultsUrl, setResultsUrl] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [progress, setProgress] = useState(10); // Initial progress
  const [timeRemaining, setTimeRemaining] = useState("...");

  const afterSubmit = useCallback(() => {
    setSubmitted(true);
  }, []);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      selectedFiles: [],
    },
    validationSchema: Yup.object({
      selectedFiles: Yup.array()
        .min(1, "Atleast one file is required")
        .required("Required"),
    }),
    onSubmit: async (values: any) => {
      const data = {};

      const formData = new FormData();

      values.selectedFiles.forEach((file: any) => {
        formData.append("files", file, file.name);
      });
      formData.append("data", JSON.stringify(data));

      try {
        setLoading(true);
        afterSubmit();
        const response: any = await bulkSearch(formData);
        setResultsUrl(response?.xlsx?.url || "");
        setProgress(100);
        setTimeRemaining("Processed");
      } catch (error) {
        toast.error("An error occurred while processing the bulk search");
        setError("An error occurred while processing the bulk search");
      } finally {
        setLoading(false);
      }
    },
  });

  const onFinish = useCallback(() => {
    setSubmitted(false);
    validation.resetForm();
    setProgress(10);
    setTimeRemaining("...");
    setError("");
  }, [validation]);

  const [downloading, setDownloading] = React.useState(false);
  const downloadTemplate = useCallback(async () => {
    setDownloading(true);

    await downloadBulkSearchTemplate()
      .then((response: any) => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Bulk_Search_Template.csv`);
        link.click();
        link.remove();
      })
      .catch((error: any) => {
        toast.error("An error occurred while downloading search report");
      });

    setDownloading(false);
  }, []);

  const toggle = () => {
    setIsModalOpen(!isModalOpen);
    onFinish();
  };

  useEffect(() => {
    // Fake progress bar (increasing by 10% every second up to 50%)
    let fakeProgressInterval: string | number | NodeJS.Timeout | undefined;

    if (progress < 50 && loading) {
      let secondsLeft = 20;
      fakeProgressInterval = setInterval(() => {
        secondsLeft = secondsLeft - 4;
        setProgress((prevProgress) => Math.min(prevProgress + 5, 50));
        setTimeRemaining(`...`);
      }, 1000); // Update every second
    }

    // Once we reach 50%, stop fake progress and start a slower interval
    if (progress >= 50 && progress < 70 && loading) {
      clearInterval(fakeProgressInterval);
      fakeProgressInterval = setInterval(() => {
        setProgress((prevProgress) => Math.min(prevProgress + 3, 70));
        setTimeRemaining(`...`);
      }, 3000); // Update every 3 seconds
    }

    // Once we reach 70%, stop the slower interval and start an even slower interval
    if (progress >= 70 && progress < 85 && loading) {
      clearInterval(fakeProgressInterval);
      fakeProgressInterval = setInterval(() => {
        setProgress((prevProgress) => Math.min(prevProgress + 2, 85));
        setTimeRemaining(`...`);
      }, 3000); // Update every 5 seconds
    }

    // Once we reach 85%, stop the interval until loading completes
    if (progress >= 85 && loading) {
      clearInterval(fakeProgressInterval);
    }

    return () => clearInterval(fakeProgressInterval);
  }, [progress, loading]);

  return (
    <>
      {/* <button
        type="submit"
        onClick={() => {
          setIsModalOpen(true);
        }}
        className="btn btn-primary btn-lg waves-effect waves-light"
      >
        <i className="mdi mdi-table-search me-0"></i>{" "}
      </button> */}
      <Link
        to="#"
        onClick={(e) => {
          e.preventDefault();
          setIsModalOpen(true);
        }}
        className="btn btn-link link-muted btn-lg text-muted "
      >
        <i className="mdi mdi-table-search "></i>{" "}
      </Link>
      <Modal
        id="bulk-search-modal"
        size="lg"
        isOpen={isModalOpen}
        toggle={toggle}
        centered
        className="bulk-search-modal"
      >
        <ModalHeader className="bg-light p-3" toggle={toggle}>
          Bulk Search
        </ModalHeader>
        {submitted ? (
          <>
            <ModalBody>
              <Card className="bg-light overflow-hidden shadow-none">
                <CardBody>
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <h6 className="mb-0">
                        <b className={error ? "text-danger" : "text-secondary"}>
                          {progress}%
                        </b>{" "}
                        {loading
                          ? "Searching..."
                          : error
                          ? "Failed"
                          : "Completed!"}
                      </h6>
                    </div>
                    <div className="flex-shrink-0">
                      <h6 className="mb-0">
                        {error ? "Failed" : timeRemaining}
                      </h6>
                    </div>
                  </div>
                </CardBody>

                <div>
                  <Progress
                    value={progress}
                    color={error ? "danger" : "primary"}
                    className="bg-info-subtle rounded-0"
                  />
                </div>
              </Card>
              {(loading && (
                <Skeleton
                  height={200}
                  baseColor="#F1F4F7"
                  highlightColor="#E3E8ED"
                />
              )) ||
                (error && (
                  <Alert color="danger" className="material-shadow">
                    <strong>
                      {" "}
                      An error occurred while processing the bulk search.{" "}
                    </strong>
                    Please try again later or contact the Risk Management Unit
                    if the issue persists.
                  </Alert>
                )) || (
                  <Alert color="primary" className="material-shadow">
                    <strong>
                      {" "}
                      Good News! Your bulk search has been successfully
                      completed.
                    </strong>{" "}
                    You can now download the results in Excel format using the
                    buttons below. The system has processed all the names from
                    your uploaded file, carefully analyzing the data to provide
                    accurate and comprehensive search results. If any matches
                    were found, they are included in the generated reports,
                    along with relevant details to help you make informed
                    decisions. Please review the results carefully, and if you
                    need further assistance, feel free to reach out to the Risk
                    Management Unit.
                  </Alert>
                )}
            </ModalBody>

            {(loading && (
              <div className="modal-footer d-flex justify-content-between">
                <Skeleton
                  width={160}
                  height={38}
                  borderRadius={6}
                  baseColor="#F1F4F7"
                  highlightColor="#E3E8ED"
                />
                <Skeleton
                  width={120}
                  height={38}
                  borderRadius={6}
                  baseColor="#F1F4F7"
                  highlightColor="#E3E8ED"
                />
              </div>
            )) || (
              <div className=" modal-footer d-flex justify-content-between">
                <button
                  type="button"
                  className="btn btn-info"
                  onClick={() => {
                    onFinish();
                  }}
                  disabled={downloading}
                >
                  {downloading && (
                    <Spinner
                      color="light"
                      size="sm"
                      style={{ marginRight: "8px", marginBottom: "-1px" }}
                    ></Spinner>
                  )}
                  <i className="ri-restart-line me-1"></i>
                  New Bulk Search
                </button>

                {error ? null : (
                  <Link
                    className="btn btn-primary"
                    to={`${resultsUrl}`}
                    target={"_blank"}
                  >
                    <i className="ri-download-2-line me-1"></i>
                    Download Results
                  </Link>
                )}
              </div>
            )}
          </>
        ) : (
          <Form
            className="tablelist-form"
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <ModalBody>
              <SelectSearchFile validation={validation} />
            </ModalBody>
            <div className=" modal-footer d-flex justify-content-between">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => {
                  downloadTemplate();
                }}
                disabled={downloading}
              >
                {downloading && (
                  <Spinner
                    color="light"
                    size="sm"
                    style={{ marginRight: "8px", marginBottom: "-1px" }}
                  ></Spinner>
                )}
                <i className="ri-file-download-line me-1"></i>
                Download Template
              </button>

              <button
                type="submit"
                className="btn btn-primary"
                disabled={loading}
              >
                {loading && (
                  <Spinner
                    color="light"
                    size="sm"
                    style={{ marginRight: "8px", marginBottom: "-1px" }}
                  ></Spinner>
                )}
                <i className="ri-search-line me-1"></i>
                Search
              </button>
            </div>
          </Form>
        )}
      </Modal>
    </>
  );
};

export default BulkSearch;
