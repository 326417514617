import React, { useCallback, useMemo, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import { toast } from "react-toastify";
import TableContainer from "Components/Common/TableContainer";
import {
  getAgencyContractors,
  deleteContractor,
} from "common/services/contractors.service";
import { Debarred, SEMGMention } from "./ContractorsCol";
import Permission from "Components/Common/Permission";
import { getSortObj } from "common/lib/tableDataOptions";
import * as moment from "moment";
import DeleteModal from "Components/Common/DeleteModal";
import SelectOrganizationTypeFilter from "pages/Contractors/SelectOrganizationTypeFilter";
import { normalize } from "common/lib/normalize";
import { useProfile } from "Components/Hooks/UserHooks";

interface FilterValue {
  organizationType: string;
  flaggedStatus: string;
}

const AllAgencyContractors = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [params] = useState({
    page: Number(searchParams.get("page")) || 1, // Starts from 1
    pageSize: Number(searchParams.get("pageSize")) || 20, // Default page size
  });

  const [pagination, setPagination] = useState({
    pageIndex: params.page - 1, // Convert to zero-based index
    pageSize: params.pageSize,
  });

  // Update pageIndex
  const setPage = (newPage: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      pageIndex: newPage - 1,
    }));
  };

  // Update pageSize
  // const setPageSize = (newPageSize: number) => {
  //   setPagination((prevPagination) => ({
  //     ...prevPagination,
  //     pageSize: newPageSize,
  //   }));
  // };

  const [sorted, setSorted] = useState(() => {
    const sortParam = searchParams.get("sort");
    return sortParam
      ? JSON.parse(sortParam)
      : [{ id: "createdAt", desc: true }];
  });
  const [selectedRecord, setSelectedRecord] = useState<any>({});

  const { userProfile } = useProfile();

  const [deleteModal, setDeleteModal] = useState(false);

  const [searchValue, setSearchValue] = useState(
    searchParams.get("search") || ""
  );
  const defaultFilterValue: FilterValue = {
    organizationType: "",
    flaggedStatus: "",
  };
  const [filterValue, setFilterValue] = useState(() => {
    const filterParam = searchParams.get("filter");
    return filterParam ? JSON.parse(filterParam) : defaultFilterValue;
  });

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  const [meta, setMeta] = useState<any>({});
  const onPageChange = useCallback(
    async ({
      page,
      sorted,
      searchValue,
      filterValue,
    }: {
      page: number;
      sorted: Array<{ id: string; desc: boolean }>;
      searchValue: string;
      filterValue?: any;
    }) => {
      const params = new URLSearchParams(searchParams);
      params.set("page", String(page));
      params.set("sort", JSON.stringify(sorted));
      params.set("search", searchValue);
      params.set("filter", JSON.stringify(filterValue));
      setSearchParams(params, { replace: true });

      const sortObj = getSortObj(sorted);

      let andConditions: any = [];

      if (filterValue?.organizationType) {
        andConditions.push({
          organizationType: filterValue.organizationType,
        });
      }

      if (filterValue?.flaggedStatus) {
        if (filterValue.flaggedStatus === "Flagged") {
          andConditions.push({
            $or: [
              {
                semgMentions: {
                  id: {
                    $notNull: true,
                  },
                },
              },

              {
                performanceRatings: {
                  performanceRatingType: {
                    isFlag: true,
                  },
                },
              },
            ],
          });
        } else if (filterValue.flaggedStatus === "Not Flagged") {
          andConditions.push({
            $and: [
              {
                semgMentions: {
                  id: {
                    $null: true, // Check if `semgMentions` is null (i.e., no records exist)
                  },
                },
              },
              {
                $or: [
                  {
                    performanceRatings: {
                      id: {
                        $null: true, // Check if no performance ratings exist
                      },
                    },
                  },
                  {
                    performanceRatings: {
                      performanceRatingType: {
                        isFlag: false, // Check if performance rating exists and `isFlag` is false
                      },
                    },
                  },
                ],
              },
            ],
          });
        }
      }

      andConditions.push({
        $or: [
          {
            contractorName: {
              $containsi: searchValue,
            },
          },
        ],
      });

      try {
        const response: any = await getAgencyContractors({
          pagination: {
            page,
            pageSize: pagination.pageSize,
          },
          sort: sortObj,
          fields: ["contractorName", "acronym", "rmuFlag", "updatedAt"],
          populate: {
            organizationType: "name",
            contracts: {
              count: true,
            },
            semgMentions: {
              count: true,
            },
            capacityAssessments: {
              count: true,
            },
            riskAssessments: {
              count: true,
            },
            pvrs: {
              count: true,
            },
            performanceRatings: {
              populate: {
                performanceRatingType: true,
              },
            },
          },
          filters: {
            $and: andConditions,
          },
        });
        setData(normalize(response.data));
        setMeta(response?.meta);
      } catch (e: any) {
        toast.error("Error while fetching data", {
          autoClose: 500,
        });
      } finally {
        setLoading(false);
      }
    },
    [pagination.pageSize, searchParams, setSearchParams]
  );

  const onClickDelete = useCallback(
    (data: any) => {
      setSelectedRecord(data);
      setDeleteModal(true);
    },
    [setSelectedRecord, setDeleteModal]
  );

  const fetchUpdatedData = useCallback(() => {
    onPageChange({
      page: pagination.pageIndex + 1,
      sorted: sorted,
      searchValue: searchValue,
    });
  }, [onPageChange, pagination.pageIndex, sorted, searchValue]);

  const [deleting, setDeleting] = useState(false);
  const handleDelete = async () => {
    if (selectedRecord) {
      setDeleting(true);
      try {
        await deleteContractor(selectedRecord);
      } catch (e: any) {
        toast.error("Error while deleting data", {
          autoClose: 1000,
        });
      } finally {
        setDeleting(false);
        fetchUpdatedData();
        setDeleteModal(false);
      }
    }
  };

  const columns = useMemo(
    () => [
      {
        header: "Contractor Name",
        accessorKey: "contractorName",
        id: "contractorName",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const rowData = cell?.row?.original;

          return (
            <>
              <div
                className="d-flex align-items-center"
                style={{ whiteSpace: "pre-wrap", width: "300px" }}
              >
                <div className="flex-shrink-0 avatar-xs me-3">
                  <div className="avatar-title bg-primary-subtle text-primary rounded-circle fs-16">
                    <i className={"ri-building-line"}></i>
                  </div>
                </div>
                <Link
                  to={`/contractors/${rowData.id}`}
                  className="fw-medium link-primary"
                >
                  {cell.getValue()}
                </Link>
              </div>
            </>
          );
        },
      },
      {
        header: "Organization Type",
        accessorKey: "organizationType.name",
        enableColumnFilter: false,
        id: "organizationType.name",
        cell: (cell: any) => {
          return <>{cell.getValue() || "-"}</>;
        },
      },
      {
        header: "Flag Status",
        accessorKey: "flaggedStatus",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cell: any) => {
          const statuses = [];
          const rowData = cell?.row?.original;

          if (rowData?.semgMentions && rowData?.semgMentions?.count > 0) {
            statuses.push(<SEMGMention />);
            statuses.push(<br />);
          }
          if (
            rowData?.performanceRatings?.some(
              (rating: any) => rating?.performanceRatingType?.isFlag
            )
          ) {
            statuses.push(<Debarred />);
          }
          if (statuses.length === 0) {
            statuses.push(
              <span className="badge bg-primary-subtle text-primary fs-11 me-2">
                N/A
              </span>
            );
          }

          return <>{statuses}</>;
        },
      },
      {
        header: "Contracts",
        accessorKey: "contracts.count",
        enableColumnFilter: false,
        enableSorting: false,
      },

      {
        header: "Assessments",
        accessorKey: "assessments",
        enableColumnFilter: false,
        id: "capacityAssessments",
        enableSorting: false,
        sortingFn: () => null,
        cell: (cell: any) => {
          const rowData = cell?.row?.original;
          return (
            (rowData?.capacityAssessments?.count ?? 0) +
            (rowData?.riskAssessments?.count ?? 0) +
            (rowData?.pvrs?.count ?? 0)
          );
        },
      },
      {
        header: "Last Updated",
        accessorKey: "updatedAt",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const date = moment(new Date(cell?.getValue())).format("DD MMM Y");
          return <>{date}</>;
        },
      },
      {
        header: "Action",
        enableSorting: false,
        cell: (cellProps: any) => {
          const rowData = cellProps?.row?.original;
          return (
            <UncontrolledDropdown>
              <DropdownToggle
                href="#"
                className="btn btn-soft-secondary btn-sm dropdown"
                tag="button"
              >
                <i className="ri-more-fill align-middle"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <Permission resource="contractor" action="findOne">
                  <DropdownItem href={`/contractors/${rowData?.id}`}>
                    <i className="ri-eye-fill align-bottom me-2 text-muted"></i>{" "}
                    View
                  </DropdownItem>
                </Permission>

                <Permission resource="contractor" action="update">
                  <DropdownItem href="/#">
                    <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}
                    Edit
                  </DropdownItem>
                </Permission>

                <Permission resource="contractor" action="delete">
                  <DropdownItem divider />
                  <DropdownItem
                    href="#"
                    onClick={() => {
                      onClickDelete(rowData);
                    }}
                  >
                    <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
                    Delete
                  </DropdownItem>
                </Permission>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    [onClickDelete]
  );
  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={() => handleDelete()}
        onCloseClick={() => setDeleteModal(false)}
        loading={deleting}
      />
      <Card>
        <CardBody>
          <Row className="g-3">
            <Col xxl={5} lg={6}>
              <h6 className="text-uppercase fs-12 mb-2">Search</h6>
              <div className="search-box">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search contractors..."
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                    setPage(1);
                  }}
                  value={searchValue}
                />
                <i className="ri-search-line search-icon"></i>
              </div>
            </Col>
            <Col xxl={3} lg={6}>
              <h6 className="text-uppercase fs-12 mb-2">Organization Type</h6>
              <SelectOrganizationTypeFilter
                onChange={(value) => {
                  setFilterValue({ ...filterValue, organizationType: value });
                  setPage(1);
                }}
                value={filterValue.organizationType}
              />
            </Col>
            <Col xxl={2} lg={6}>
              <h6 className="text-uppercase fs-12 mb-2">Select Status</h6>
              <select
                className="form-control"
                data-choices
                data-choices-search-false
                name="choices-single-default2"
                id="choices-single-default2"
                onChange={(e) => {
                  setFilterValue({
                    ...filterValue,
                    flaggedStatus: e.target.value,
                  });
                  setPage(1);
                }}
                value={filterValue.flaggedStatus}
              >
                <option value="">Select Status</option>
                <option value="Flagged">Flagged</option>
                {/* <option value="Not Flagged">Not Flagged</option> */}
              </select>
            </Col>

            <Col xxl={2} lg={4}>
              <h6 className="text-uppercase fs-12 mb-xxl-4"> {` `}</h6>
              <button
                onClick={() => {
                  setFilterValue(defaultFilterValue);
                  setSearchValue("");
                  setPage(1);
                  setSorted([{ id: "createdAt", desc: true }]);
                }}
                className="btn btn-outline-primary material-shadow-none w-100"
              >
                <i className="ri-equalizer-line align-bottom me-1"></i> Reset
                Filters
              </button>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card>
        <CardHeader>
          <Row className="align-items-center g-3">
            <Col md={8}>
              <h5 className="card-title mb-1">
                {userProfile?.agency?.name} Contractors
              </h5>
              <p className="mb-0 text-muted">
                This page shows a comprehensive list of contractors, vendors,
                and implementing partners provided by{" "}
                {userProfile?.agency?.name}.
              </p>
            </Col>
            <div className="col-md-auto ms-auto">
              <div className="d-flex gap-2">
                <Permission resource="contractor" action="create">
                  <Link to="/contractors/add" className="btn btn-primary">
                    <i className="ri-add-line align-bottom me-1"></i>
                    Add Contractor
                  </Link>
                </Permission>
              </div>
            </div>
          </Row>
        </CardHeader>
        <CardBody>
          <TableContainer
            loading={loading}
            columns={columns}
            data={data || []}
            pagination={pagination}
            setPagination={setPagination}
            sorted={sorted}
            setSorted={setSorted}
            onPageChange={onPageChange}
            FilterSection={() => {}}
            totalRows={meta?.pagination?.total || 0}
            totalPages={meta?.pagination?.pageCount || 0}
            searchValue={searchValue}
            filterValue={filterValue}
            divClass="table-responsive table-card mb-4"
            tableClass="align-middle table-nowrap mb-0 table-striped"
            theadClass="table-light table-nowrap"
          />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default AllAgencyContractors;
