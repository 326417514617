import React from "react";
import { Routes, Route } from "react-router-dom";

//Layouts
import NonAuthLayout from "../Layouts/NonAuthLayout";
import VerticalLayout from "../Layouts/index";

//routes
import { authProtectedRoutes, publicRoutes } from "./allRoutes";
import AuthProtected from "./AuthProtected";
import { UserProvider } from "Components/Hooks/UserHooks";
import { GoogleReCaptchaProvider } from "@google-recaptcha/react";

const Index = () => {
  return (
    <React.Fragment>
      <Routes>
        <Route>
          {publicRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <GoogleReCaptchaProvider
                  type="v3"
                  siteKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || ""}
                >
                  <NonAuthLayout>{route.component}</NonAuthLayout>
                </GoogleReCaptchaProvider>
              }
              key={idx}
            />
          ))}
        </Route>

        <Route>
          {authProtectedRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <AuthProtected>
                  <UserProvider>
                    <VerticalLayout>{route.component}</VerticalLayout>
                  </UserProvider>
                </AuthProtected>
              }
              key={idx}
            />
          ))}
        </Route>
      </Routes>
    </React.Fragment>
  );
};

export default Index;
