import React, { useState } from "react";
import { Modal, ModalHeader } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { addRegion } from "common/services/regions.service";
import RegionForm from "./RegionForm";
import { toast } from "react-toastify";

const AddRegion: React.FC<{
  toggle: () => void;
  isModalOpen: boolean;
  fetchData: () => void;
}> = ({ toggle, isModalOpen, fetchData }) => {
  const [loading, setLoading] = useState<boolean>(false);
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: "",
      altName1: "",
      level: {
        value: "",
        label: "",
      },
      country: {
        value: "",
        label: "",
      },
      adminLevel0: {
        value: null,
        label: "",
      },
      adminLevel1: {
        value: null,
        label: "",
      },
      x_coord: "",
      y_coord: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter name"),
      altName1: Yup.string().nullable(),
      level: Yup.object().shape({
        value: Yup.number().required("Please select administration level"),
      }),
      country: Yup.object().shape({
        value: Yup.number().required("Please select country"),
      }),
      adminLevel0: Yup.object().shape({
        value: Yup.string().nullable(),
      }),
      adminLevel1: Yup.object().shape({
        value: Yup.string().nullable(),
      }),
      x_coord: Yup.string().nullable(),
      y_coord: Yup.string().nullable(),
    }),
    onSubmit: async (values: any) => {
      const data = {
        data: {
          name: values["name"],
          altName1: values["altName1"],
          level: values["level"]["value"],
          adminLevel0: values["adminLevel0"]["value"] || null,
          adminLevel1: values["adminLevel1"]["value"] || null,
          x_coord: values["x_coord"] ? `${values["x_coord"]}` : null,
          y_coord: values["y_coord"] ? `${values["y_coord"]}` : null,
          country: values["country"]["value"] || null,
        },
      };
      // save new order
      setLoading(true);
      try {
        await addRegion(data);
        fetchData();
        validation.resetForm();
        toggle();
        toast.success("Region added successfully", {});
      } catch (e: any) {
        toast.error("Failed to create region", {});
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <Modal
      id="showModal"
      size="lg"
      isOpen={isModalOpen}
      toggle={toggle}
      centered
    >
      <ModalHeader className="bg-light p-3" toggle={toggle}>
        Add Region
      </ModalHeader>

      <RegionForm
        validation={validation}
        isUpdate={false}
        loading={loading}
        toggle={toggle}
        setLoading={setLoading}
      />
    </Modal>
  );
};

export default AddRegion;
