export const ICON_CONTRACTORS = "ri-account-circle-line";
export const ICON_CONTRACTS = "ri-file-text-line";
export const ICON_PEOPLE = "ri-team-line";
export const ICON_USERS = "ri-group-line";
export const ICON_ASSESSMENTS = "ri-file-edit-line";
export const ICON_AGENCIES = "ri-earth-fill";
export const ICON_CALENDAR = "ri-calendar-2-line";
export const ICON_SANCTIONS = "ri-shield-star-line";

export const APP_NAME_FULL = "Contractor Information Management System";
export const APP_NAME_SHORT = "CIMS";

export const LEVEL_GLOBAL = "Global";
export const LEVEL_COUNTRY = "Country";
export const LEVEL_AGENCY = "Agency";

export const ROLE_RISK_ANALYST = "Risk Analyst";

export const CARD_MIN_HEIGHT = "62vh";

export const CLASSIFICATION_RESTRICTED: string = "Restricted";
export const CLASSIFICATION_CONFIDENTIAL: string = "Confidential";

export const DEBOUNCE_TIME = 300;

export const COUNTRY_LEVEL = 100;
export const COUNTRY_CODE_SO = "SO";
