import {
  userResetPasswordSuccess,
  userResetPasswordError,
  setLoading,
} from "./reducer";

import { postResetPassword } from "helpers/backend_helper";

export const userResetPassword =
  (user: any, validation: any) => async (dispatch: any) => {
    try {
      dispatch(setLoading(true));
      let response;
      response = postResetPassword({
        code: user.code,
        password: user.password,
        passwordConfirmation: user.confirmPassword,
      });

      const data = await response;

      if (data) {
        dispatch(
          userResetPasswordSuccess(
            "Your password has been reset successfully. Please login with your new password."
          )
        );
        validation.resetForm();
      }
      dispatch(setLoading(false));
      return data;
    } catch (error: any) {
      const errorMsg = error?.response?.data?.error?.message || "";
      dispatch(setLoading(false));
      dispatch(
        userResetPasswordError(errorMsg.replace("code", "reset password link"))
      );
    }
  };
