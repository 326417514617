import PropTypes from "prop-types";
import React from "react";
import { Modal, ModalBody, Progress } from "reactstrap";

interface ExportCSVModalProps {
  show: boolean;
  onCloseClick: () => void;
  message?: string;
}

const ExportLoadingModal = ({
  show,
  onCloseClick,
  message,
}: ExportCSVModalProps) => {
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      {/* <ModalHeader toggle={onCloseClick}></ModalHeader> */}
      <ModalBody className="py-3 px-5">
        <div className="mt-4 text-center">
          {/* <i className="ri-file-text-line display-5 text-success"></i> */}

          <div className="mt-2 pt-2 fs-15 mx-4 mx-sm-5">
            <p className="text-muted mx-4 mb-0=2">
              {" "}
              {message || "Exporting data..."}{" "}
            </p>
            <Progress
              animated
              color="primary"
              value={100}
              striped
              className="mb-5"
            />
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

ExportLoadingModal.propTypes = {
  onCloseClick: PropTypes.func,
  data: PropTypes.any,
  show: PropTypes.any,
};

export default ExportLoadingModal;
