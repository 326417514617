import React, { useCallback, useMemo, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Row, Container } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer from "../../../Components/Common/TableContainer";
import BreadCrumb from "../../../Components/Common/BreadCrumb";

import { createSelector } from "reselect";
import { getUKSanctionsList as onGetSanctionsList } from "../../../slices/thunks";
import { getSortObj } from "common/lib/tableDataOptions";
import * as moment from "moment";
import RegimeFilter from "./RegimeFilter";

interface FilterValue {
  reference: string;
  regime: string;
}

const UKSanctionsList = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [params] = useState({
    page: Number(searchParams.get("page")) || 1, // Starts from 1
    pageSize: Number(searchParams.get("pageSize")) || 20, // Default page size
  });

  const [pagination, setPagination] = useState({
    pageIndex: params.page - 1, // Convert to zero-based index
    pageSize: params.pageSize,
  });

  // Update pageIndex
  const setPage = (newPage: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      pageIndex: newPage - 1,
    }));
  };

  // Update pageSize
  // const setPageSize = (newPageSize: number) => {
  //   setPagination((prevPagination) => ({
  //     ...prevPagination,
  //     pageSize: newPageSize,
  //   }));
  // };

  const [sorted, setSorted] = useState(() => {
    const sortParam = searchParams.get("sort");
    return sortParam
      ? JSON.parse(sortParam)
      : [{ id: "createdAt", desc: true }];
  });

  const dispatch: any = useDispatch();

  const selectLayoutState: any = (state: any) => state.SanctionsList;
  const selectProperties = createSelector(selectLayoutState, (state) => ({
    ukSanctionsList: state.ukSanctionsList.data,
    meta: state.ukSanctionsList.meta,
    error: state.error,
    loading: state.loading,
  }));

  const { ukSanctionsList, meta, loading } = useSelector(selectProperties);
  const [searchValue, setSearchValue] = useState(
    searchParams.get("search") || ""
  );
  const defaultFilterValue: FilterValue = {
    reference: "",
    regime: "",
  };
  const [filterValue, setFilterValue] = useState(() => {
    const filterParam = searchParams.get("filter");
    return filterParam ? JSON.parse(filterParam) : defaultFilterValue;
  });

  const onPageChange = useCallback(
    ({
      page,
      sorted,
      searchValue,
    }: {
      page: number;
      sorted: Array<{ id: string; desc: boolean }>;
      searchValue: string;
    }) => {
      const params = new URLSearchParams(searchParams);
      params.set("page", String(page));
      params.set("sort", JSON.stringify(sorted));
      params.set("search", searchValue);
      setSearchParams(params, { replace: true });

      const sortObj = getSortObj(sorted);

      let andConditions: any = [];

      if (filterValue.reference) {
        andConditions.push({
          unReferenceNumber: {
            $containsi: filterValue.reference,
          },
        });
      }
      if (filterValue.regime) {
        andConditions.push({
          regime: {
            $containsi: filterValue.regime,
          },
        });
      }

      andConditions.push({
        $or: [
          {
            title: {
              $containsi: searchValue,
            },
          },
          {
            fullName: {
              $containsi: searchValue,
            },
          },
        ],
      });

      dispatch(
        onGetSanctionsList({
          pagination: {
            page,
            pageSize: pagination.pageSize,
          },
          sort: sortObj,
          populate: [],
          filters: {
            $and: andConditions,
          },
        })
      );
    },
    [
      dispatch,
      filterValue.reference,
      filterValue.regime,
      pagination.pageSize,
      searchParams,
      setSearchParams,
    ]
  );

  const columns = useMemo(
    () => [
      //   {
      //     header: "#",
      //     accessorKey: "id",
      //     // cell: (cell: any) => <>{cell.getValue()}</>,
      //   },

      {
        header: "Full Name",
        accessorKey: "fullName",
        id: "fullName",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div style={{ whiteSpace: "pre-wrap", width: "300px" }}>
              <Link
                to={`/sanctions-list/uk/${cell.row.original?.id}`}
                className="fw-medium link-primary"
              >
                {cell.getValue()}
              </Link>
            </div>
          );
        },
      },
      {
        header: "Reference",
        accessorKey: "unReferenceNumber",
        id: "unReferenceNumber",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return cell.getValue() || "Not Provided";
        },
      },
      {
        header: "Regime",
        accessorKey: "regime",
        id: "regime",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div style={{ whiteSpace: "pre-wrap", width: "300px" }}>
              {cell.getValue()}
            </div>
          );
        },
      },
      {
        header: "Entity Type",
        accessorKey: "individualEntityShip",
        id: "individualEntityShip",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return cell.getValue() || "Not Provided";
        },
      },
      {
        header: "Designate Source",
        accessorKey: "designationSource",
        id: "designationSource",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return cell.getValue() || "Not Provided";
        },
      },
      {
        header: "Last Updated At",
        accessorKey: "updatedAt",
        id: "updatedAt",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const date = moment(new Date(cell?.getValue())).format("DD MMM Y");
          return <>{date}</>;
        },
      },
    ],
    []
  );
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="UK Sanctions List"
            pageTitles={[
              { title: "Dashboard", link: "/dashboard" },
              {
                title: "Sanctions List",
                link: "/sanctions-list/uk",
              },
            ]}
          />

          <Card>
            <CardBody>
              <Row className="g-3">
                <Col xxl={6} lg={6}>
                  <h6 className="text-uppercase fs-12 mb-2">Search</h6>
                  <div className="search-box">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search eu sanctions list..."
                      onChange={(e) => {
                        setSearchValue(e.target.value);
                        setPage(1);
                      }}
                      value={searchValue}
                    />
                    <i className="ri-search-line search-icon"></i>
                  </div>
                </Col>
                <Col xxl={2} lg={6}>
                  <h6 className="text-uppercase fs-12 mb-2">Reference</h6>
                  <div className="">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Reference"
                      onChange={(e) => {
                        setFilterValue({
                          ...filterValue,
                          reference: e.target.value,
                        });
                        setPage(1);
                      }}
                      value={filterValue.reference}
                    />
                  </div>
                </Col>
                <Col xxl={2} lg={6}>
                  <h6 className="text-uppercase fs-12 mb-2">Program</h6>
                  <RegimeFilter
                    onChange={(value) => {
                      setFilterValue({ ...filterValue, regime: value });
                      setPage(1);
                    }}
                    value={filterValue.regime}
                  />
                </Col>
                <Col xxl={2} lg={4}>
                  <h6 className="text-uppercase fs-12 mb-xxl-4"> {` `}</h6>

                  <button
                    onClick={() => {
                      setFilterValue(defaultFilterValue);
                      setSearchValue("");
                      setPage(1);
                      setSorted([{ id: "createdAt", desc: true }]);
                    }}
                    className="btn btn-outline-primary material-shadow-none w-100"
                  >
                    <i className="ri-equalizer-line align-bottom me-1"></i>{" "}
                    Reset Filters
                  </button>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card>
            <CardHeader>
              <Row className="align-items-center g-3">
                <Col md={3}>
                  <h5 className="card-title mb-0">UK Sanctions List</h5>
                </Col>
                <div className="col-md-auto ms-auto">
                  <div className="d-flex gap-2">
                    <div className="search-box">
                      <input
                        type="text"
                        className="form-control search"
                        placeholder="Search UK sanctions list..."
                        onChange={(e) => {
                          setSearchValue(e.target.value);
                          setPage(1);
                        }}
                        value={searchValue}
                      />
                      <i className="ri-search-line search-icon"></i>
                    </div>
                    {/* <button className="btn btn-success">
                      <i className="ri-equalizer-line align-bottom me-1"></i>
                      Filters
                    </button> */}
                  </div>
                </div>
              </Row>
            </CardHeader>
            <CardBody>
              <TableContainer
                loading={loading}
                columns={columns}
                data={ukSanctionsList || []}
                pagination={pagination}
                setPagination={setPagination}
                sorted={sorted}
                setSorted={setSorted}
                onPageChange={onPageChange}
                FilterSection={() => {}}
                totalRows={meta?.pagination?.total || 0}
                totalPages={meta?.pagination?.pageCount || 0}
                searchValue={searchValue}
                divClass="table-responsive table-card mb-4"
                tableClass="align-middle table-nowrap mb-0 table-striped"
                theadClass="table-light table-nowrap"
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UKSanctionsList;
