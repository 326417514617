import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  FormFeedback,
  Alert,
  Spinner,
} from "reactstrap";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import AuthSlider from "./AuthSlider";
import { APP_NAME_SHORT } from "common/constants";
import { registerUser, resetLoginFlag } from "slices/thunks";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import SelectAgency from "pages/Users/SelectAgency";
import TermsAndConditionsModal from "./TermsAndConditionsModal";
import { useGoogleReCaptcha } from "@google-recaptcha/react";
import { setLoading } from "slices/auth/register/reducer";

const Register = () => {
  const dispatch: any = useDispatch();
  const { executeV3 } = useGoogleReCaptcha();

  const selectLayoutState = (state: any) => state;
  const loginpageData = createSelector(selectLayoutState, (state) => ({
    user: state.Account.user,
    error: state.Account.error,
    errorMsg: state.Account.errorMsg,
    successMsg: state.Account.successMsg,
    loading: state.Account.loading,
  }));

  const { user, error, errorMsg, successMsg, loading } =
    useSelector(loginpageData);

  const [userLogin, setUserLogin] = useState<any>([]);
  const [passwordShow, setPasswordShow] = useState<boolean>(false);

  useEffect(() => {
    if (user && user) {
      const updatedUserData = user.email;
      const updatedUserPassword = user.confirm_password;
      setUserLogin({
        email: updatedUserData,
        password: updatedUserPassword,
      });
    }
  }, [user]);

  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: userLogin.email || "",
      password: userLogin.password || "",
      confirmPassword: "",
      agency: {
        label: "",
        value: "",
      },
      firstName: "",
      lastName: "",
      position: "",
      agreeToTerms: false,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Please enter your email")
        .email("Please enter a valid email address"),
      password: Yup.string().required("Please enter your password"),
      confirmPassword: Yup.string()
        .required("Please enter your confirm password")
        .oneOf([Yup.ref("password")], "Password does not match"),
      agency: Yup.object().shape({
        label: Yup.string(),
        value: Yup.string().required("Please select agency"),
      }),
      firstName: Yup.string().required("Please enter first name"),
      lastName: Yup.string().required("Please enter last name"),
      position: Yup.string().required("Please enter position"),
      agreeToTerms: Yup.boolean()
        .oneOf([true], "You must agree to the terms and conditions.")
        .required("Agreement is required"),
    }),
    onSubmit: async (values) => {
      try {
        dispatch(setLoading(true));
        if (!executeV3) {
          console.error("Recaptcha not loaded");
          return;
        }
        const reCaptchaToken = await executeV3("submit")
          .then((token) => token)
          .catch((e) => console.error("Error in recaptcha: ", e));

        if (!reCaptchaToken) {
          console.error("Recaptcha not verified");
          return;
        }
        dispatch(
          registerUser(
            {
              ...values,
              reCaptchaToken: reCaptchaToken,
            },
            validation
          )
        );
      } catch (e) {
        dispatch(setLoading(false));
      }
    },
  });

  useEffect(() => {
    if (errorMsg) {
      setTimeout(() => {
        dispatch(resetLoginFlag());
      }, 3000);
    }
  }, [dispatch, errorMsg]);

  const [showTermsModal, setShowTermModal] = useState<boolean>(false);

  document.title = "Sign Up | CIMS";

  return (
    <React.Fragment>
      <div
        className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100"
        style={{
          background: "rgba(1,78,107, 0.9)",
        }}
      >
        <div className="bg-overlay"></div>
        <div className="auth-page-content overflow-hidden pt-lg-5">
          <Container>
            <Row>
              <Col lg={12}>
                <Card className="overflow-hidden card-bg-fill galaxy-border-none">
                  <Row className="g-0">
                    <AuthSlider />

                    <Col lg={6}>
                      <div className="p-lg-5 p-4">
                        <div>
                          <h5 className="text-primary">
                            Create a new account !
                          </h5>
                          <p className="text-muted">
                            Get your free {APP_NAME_SHORT} account now
                          </p>
                        </div>

                        {typeof error === "string" && error ? (
                          <Alert color="danger"> {error} </Alert>
                        ) : null}

                        {successMsg ? (
                          <Alert color="success" style={{ marginTop: "13px" }}>
                            {successMsg}
                          </Alert>
                        ) : null}

                        <div className="mt-4">
                          <Form
                            onSubmit={(e) => {
                              e.preventDefault();
                              validation.handleSubmit();
                              return false;
                            }}
                            action="#"
                          >
                            <Row>
                              <Col md={6}>
                                <div className="mb-3">
                                  <Label
                                    htmlFor="firstName"
                                    className="form-label"
                                  >
                                    First Name
                                  </Label>
                                  <Input
                                    disabled={loading}
                                    type="text"
                                    className="form-control"
                                    id="firstName"
                                    placeholder="Enter first name"
                                    name="firstName"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.firstName || ""}
                                    invalid={
                                      validation.touched.firstName &&
                                      validation.errors.firstName
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.firstName &&
                                  validation.errors.firstName ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.firstName}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="mb-3">
                                  <Label
                                    htmlFor="lastName"
                                    className="form-label"
                                  >
                                    Last Name
                                  </Label>
                                  <Input
                                    disabled={loading}
                                    type="text"
                                    className="form-control"
                                    id=" "
                                    placeholder="Enter last name"
                                    name="lastName"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.lastName || ""}
                                    invalid={
                                      validation.touched.lastName &&
                                      validation.errors.lastName
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.lastName &&
                                  validation.errors.lastName ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.lastName}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                              <Col md={6}>
                                <SelectAgency
                                  validation={validation}
                                  label="Agency / Organization"
                                />
                              </Col>
                              <Col md={6}>
                                <div className="mb-3">
                                  <Label
                                    htmlFor="position"
                                    className="form-label"
                                  >
                                    Position / Title
                                  </Label>
                                  <Input
                                    disabled={loading}
                                    type="text"
                                    className="form-control"
                                    id="position"
                                    placeholder="Enter position / title"
                                    name="position"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.position || ""}
                                    invalid={
                                      validation.touched.position &&
                                      validation.errors.position
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.position &&
                                  validation.errors.position ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.position}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>
                            </Row>
                            <div className="mb-3">
                              <Label htmlFor="email" className="form-label">
                                Email
                              </Label>
                              <Input
                                disabled={loading}
                                type="text"
                                className="form-control"
                                id="email"
                                placeholder="Enter email"
                                name="email"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email || ""}
                                invalid={
                                  validation.touched.email &&
                                  validation.errors.email
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.email &&
                              validation.errors.email ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.email}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <Row>
                              <Col md={6} className="mb-3">
                                <Label
                                  className="form-label"
                                  htmlFor="password-input"
                                >
                                  Password
                                </Label>
                                <div className="position-relative auth-pass-inputgroup mb-3">
                                  <Input
                                    disabled={loading}
                                    type={passwordShow ? "text" : "password"}
                                    className="form-control pe-5 password-input"
                                    placeholder="Enter password"
                                    id="password-input"
                                    name="password"
                                    value={validation.values.password || ""}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    invalid={
                                      validation.touched.password &&
                                      validation.errors.password
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.password &&
                                  validation.errors.password ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.password}
                                    </FormFeedback>
                                  ) : null}
                                  <button
                                    className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon material-shadow-none"
                                    type="button"
                                    id="password-addon"
                                    onClick={() =>
                                      setPasswordShow(!passwordShow)
                                    }
                                  >
                                    <i className="ri-eye-fill align-middle"></i>
                                  </button>
                                </div>
                              </Col>
                              <Col md={6} className="mb-3">
                                <Label
                                  className="form-label"
                                  htmlFor="password-input"
                                >
                                  Confirm Password
                                </Label>
                                <div className="position-relative auth-pass-inputgroup mb-3">
                                  <Input
                                    disabled={loading}
                                    type={passwordShow ? "text" : "password"}
                                    className="form-control pe-5 password-input"
                                    placeholder="Confirm password"
                                    id="confirm-password-input"
                                    name="confirmPassword"
                                    value={
                                      validation.values.confirmPassword || ""
                                    }
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    invalid={
                                      validation.touched.confirmPassword &&
                                      validation.errors.confirmPassword
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.touched.confirmPassword &&
                                  validation.errors.confirmPassword ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.confirmPassword}
                                    </FormFeedback>
                                  ) : null}
                                  <button
                                    className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon material-shadow-none"
                                    type="button"
                                    id="password-addon"
                                    onClick={() =>
                                      setPasswordShow(!passwordShow)
                                    }
                                  >
                                    <i className="ri-eye-fill align-middle"></i>
                                  </button>
                                </div>
                              </Col>
                            </Row>

                            {/* <p className="text-muted fst-italic">
                              By checking the box below, I am acknowledging my
                              access to the classified information in CIMS is
                              strictly in accordance with requirements or the
                              United Nations Secretary Generals Bulletin
                              <Link
                                to="#"
                                className="text-primary text-decoration-underline fst-normal fw-medium me-1 ms-1"
                              >
                                ST/SGB/2007/6
                              </Link>
                              for Secretariat Agencies on handling classified
                              information. I will not disclose any classified UN
                              information to anybody without the requisite
                              clearance from Risk Management Unit. I will use
                              the classified Information solely for the purpose
                              for which it was supplied.
                            </p> */}
                            <div className="form-check">
                              <Input
                                disabled={loading}
                                type="checkbox"
                                className="form-check-input"
                                id="auth-remember-check"
                                name="agreeToTerms"
                                checked={
                                  validation.values.agreeToTerms || false
                                }
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.agreeToTerms &&
                                  validation.errors.agreeToTerms
                                    ? true
                                    : false
                                }
                              />
                              <Label
                                className="form-check-label"
                                htmlFor="auth-remember-check"
                              >
                                By registering you agree to the
                                {` ${APP_NAME_SHORT} `}
                                <Link
                                  to="#"
                                  onClick={() => setShowTermModal(true)}
                                  className="text-primary text-decoration-underline fst-normal fw-medium"
                                >
                                  Terms and Conditions of Use
                                </Link>
                              </Label>

                              {validation.touched.agreeToTerms &&
                              validation.errors.agreeToTerms ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.agreeToTerms}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="mt-4">
                              <Button
                                color="primary"
                                className="w-100 g-recaptcha"
                                type="submit"
                                disabled={loading}
                              >
                                {loading ? (
                                  <Spinner size="sm" className="me-2">
                                    Loading...
                                  </Spinner>
                                ) : null}
                                Sign Up
                              </Button>
                            </div>
                          </Form>
                          {/* <GoogleReCaptchaCheckbox
                            onChange={(token) => {
                              console.log(token);
                            }}
                            errorCallback={() => {
                              return console.error("Error in reCAPTCHA");
                            }}
                            expiredCallback={() => {
                              return console.error("Error in reCAPTCHA");
                            }}
                          /> */}
                          <TermsAndConditionsModal
                            toggle={() => setShowTermModal((state) => !state)}
                            isModalOpen={showTermsModal}
                          />
                        </div>

                        <div className="mt-5 text-center">
                          <p className="mb-0">
                            Already have an account ?{" "}
                            <Link
                              to="/login"
                              className="fw-semibold text-primary text-decoration-underline"
                            >
                              Sign In
                            </Link>
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>

        <footer className="footer galaxy-border-none">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center">
                  <p className="mb-0">
                    CIMS {new Date().getFullYear()} - Risk Management Unit
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      </div>
    </React.Fragment>
  );
};

export default Register;
