import React, { useCallback, useMemo, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Row, Container } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer from "Components/Common/TableContainer";
import BreadCrumb from "Components/Common/BreadCrumb";

import { createSelector } from "reselect";
import { getSortObj } from "common/lib/tableDataOptions";
import { getUSSanctionsList as onGetSanctionsList } from "slices/thunks";
import * as moment from "moment";
import ProgramFilter from "./ProgramFilter";

interface FilterValue {
  reference: string;
  program: string;
}

const USSanctionsList = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [params] = useState({
    page: Number(searchParams.get("page")) || 1, // Starts from 1
    pageSize: Number(searchParams.get("pageSize")) || 20, // Default page size
  });

  const [pagination, setPagination] = useState({
    pageIndex: params.page - 1, // Convert to zero-based index
    pageSize: params.pageSize,
  });

  // Update pageIndex
  const setPage = (newPage: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      pageIndex: newPage - 1,
    }));
  };

  // Update pageSize
  // const setPageSize = (newPageSize: number) => {
  //   setPagination((prevPagination) => ({
  //     ...prevPagination,
  //     pageSize: newPageSize,
  //   }));
  // };

  const [sorted, setSorted] = useState(() => {
    const sortParam = searchParams.get("sort");
    return sortParam
      ? JSON.parse(sortParam)
      : [{ id: "createdAt", desc: true }];
  });

  const dispatch: any = useDispatch();

  const selectLayoutState: any = (state: any) => state.SanctionsList;
  const selectinvoiceProperties = createSelector(
    selectLayoutState,
    (state) => ({
      usSanctionsList: state.usSanctionsList.data,
      meta: state.usSanctionsList.meta,
      error: state.error,
      loading: state.loading,
    })
  );

  const { usSanctionsList, meta, loading } = useSelector(
    selectinvoiceProperties
  );
  const [searchValue, setSearchValue] = useState(
    searchParams.get("search") || ""
  );
  const defaultFilterValue: FilterValue = {
    reference: "",
    program: "",
  };
  const [filterValue, setFilterValue] = useState(() => {
    const filterParam = searchParams.get("filter");
    return filterParam ? JSON.parse(filterParam) : defaultFilterValue;
  });

  const onPageChange = useCallback(
    ({
      page,
      sorted,
      searchValue,
    }: {
      page: number;
      sorted: Array<{ id: string; desc: boolean }>;
      searchValue: string;
    }) => {
      const params = new URLSearchParams(searchParams);
      params.set("page", String(page));
      params.set("sort", JSON.stringify(sorted));
      params.set("search", searchValue);
      setSearchParams(params, { replace: true });

      const sortObj = getSortObj(sorted);

      let andConditions: any = [];
      if (filterValue.reference) {
        andConditions.push({
          reference: {
            $containsi: filterValue.reference,
          },
        });
      }
      if (filterValue.program) {
        andConditions.push({
          program: {
            $containsi: filterValue.program,
          },
        });
      }
      andConditions.push({
        $or: [
          {
            title: {
              $containsi: searchValue,
            },
          },
          {
            firstName: {
              $containsi: searchValue,
            },
          },

          {
            lastName: {
              $containsi: searchValue,
            },
          },
          {
            aliasNames: {
              $containsi: searchValue,
            },
          },
        ],
      });

      dispatch(
        onGetSanctionsList({
          pagination: {
            page,
            pageSize: pagination.pageSize,
          },
          sort: sortObj,
          populate: [],
          filters: {
            $and: andConditions,
          },
        })
      );
    },
    [
      dispatch,
      filterValue.program,
      filterValue.reference,
      pagination.pageSize,
      searchParams,
      setSearchParams,
    ]
  );

  const columns = useMemo(
    () => [
      //   {
      //     header: "#",
      //     accessorKey: "id",
      //     // cell: (cell: any) => <>{cell.getValue()}</>,
      //   },

      {
        header: "Full Name",
        accessorKey: "title",
        id: "title",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const wrappedText = cell
            .getValue()
            .trim()
            .match(/.{1,80}/g)
            .join("\n");

          return (
            <div style={{ whiteSpace: "pre-wrap", width: "300px" }}>
              <Link
                to={`/sanctions-list/us/${cell.row.original?.id}`}
                className="fw-medium link-primary"
              >
                {wrappedText}
              </Link>
            </div>
          );
        },
      },
      {
        header: "Reference",
        accessorKey: "reference",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return cell.getValue() || "Not Provided";
        },
      },
      {
        header: "Program",
        accessorKey: "program",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cell: any) => {
          return cell.getValue() || "Not Provided";
        },
      },
      {
        header: "Alias Names",
        accessorKey: "aliasNames",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div className="fixed-table-column-width-2">
              {cell.getValue() || "Not Provided"}
            </div>
          );
        },
      },
      {
        header: "Last Updated At",
        accessorKey: "updatedAt",
        id: "updatedAt",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const date = moment(new Date(cell?.getValue())).format("DD MMM Y");
          return <>{date}</>;
        },
      },
    ],
    []
  );

  document.title = "US Sanctions List | CIMS";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="US Sanctions List"
            pageTitles={[
              { title: "Dashboard", link: "/dashboard" },
              {
                title: "Sanctions List",
                link: "/sanctions-list/us",
              },
            ]}
          />

          <Card>
            <CardBody>
              <Row className="g-3">
                <Col xxl={6} lg={6}>
                  <h6 className="text-uppercase fs-12 mb-2">Search</h6>
                  <div className="search-box">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search us sanctions list..."
                      onChange={(e) => {
                        setSearchValue(e.target.value);
                        setPage(1);
                      }}
                      value={searchValue}
                    />
                    <i className="ri-search-line search-icon"></i>
                  </div>
                </Col>
                <Col xxl={2} lg={6}>
                  <h6 className="text-uppercase fs-12 mb-2">Reference</h6>
                  <div className="">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Reference"
                      onChange={(e) => {
                        setFilterValue({
                          ...filterValue,
                          reference: e.target.value,
                        });
                        setPage(1);
                      }}
                      value={filterValue.reference}
                    />
                  </div>
                </Col>
                <Col xxl={2} lg={6}>
                  <h6 className="text-uppercase fs-12 mb-2">Program</h6>
                  <ProgramFilter
                    onChange={(value) => {
                      setFilterValue({ ...filterValue, program: value });
                      setPage(1);
                    }}
                    value={filterValue.program}
                  />
                </Col>
                <Col xxl={2} lg={4}>
                  <h6 className="text-uppercase fs-12 mb-xxl-4"> {` `}</h6>

                  <button
                    onClick={() => {
                      setFilterValue(defaultFilterValue);
                      setSearchValue("");
                      setPage(1);
                      setSorted([{ id: "createdAt", desc: true }]);
                    }}
                    className="btn btn-outline-primary material-shadow-none w-100"
                  >
                    <i className="ri-equalizer-line align-bottom me-1"></i>{" "}
                    Reset Filters
                  </button>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card>
            <CardHeader>
              <Row className="align-items-center g-3">
                <Col md={12}>
                  <h5 className="card-title">US Sanctions List</h5>
                  {/* <p className="mb-0 text-muted">
                    The US sanctions list identifies individuals, entities, and
                    countries subject to US government sanctions for activities
                    like terrorism and human rights abuses. It includes
                    restrictions on trade, finance, and travel to enforce US
                    foreign policy and national security objectives.
                    <a
                      href="https://home.treasury.gov/policy-issues/office-of-foreign-assets-control-sanctions-programs-and-information"
                      className="mx-1 text-primary"
                    >
                      Treasury's Sanctions Programs.
                    </a>
                  </p> */}
                  <p className="mb-0 text-muted">
                    The{" "}
                    <span className="text-primary">
                      Office of Foreign Assets Control ("OFAC")
                    </span>{" "}
                    of the{" "}
                    <span className="text-primary">
                      US Department of the Treasury
                    </span>{" "}
                    administers and enforces economic and trade sanctions based
                    on US foreign policy and national security goals against
                    targeted foreign countries and regimes, terrorists,
                    international narcotics traffickers, those engaged in
                    activities related to the proliferation of weapons of mass
                    destruction, and other threats to the national security,
                    foreign policy or economy of the United​ States.
                    Collectively, such individuals and companies are called
                    "Specially Designated Nationals" or "SDNs." Their assets are
                    blocked and U.S. persons are generally prohibited from
                    dealing with them. Read more information on{" "}
                    <a
                      href="https://home.treasury.gov/policy-issues/office-of-foreign-assets-control-sanctions-programs-and-information"
                      className="mx-1 text-primary"
                    >
                      Treasury's Sanctions Programs.
                    </a>{" "}
                    <br /> OFAC also administers several other sanctions lists
                    that are consolidated in the non-SDN category. The resource
                    list below includes both the SDN and non SDN list.
                  </p>
                </Col>
                {/* <div className="col-md-auto ms-auto">
                  <div className="d-flex gap-2">
                    <div className="search-box">
                      <input
                        type="text"
                        className="form-control search"
                        placeholder="Search US sanctions list..."
                        onChange={(e) => {
                          setSearchValue(e.target.value);
                          setPage(1);
                        }}
                        value={searchValue}
                      />
                      <i className="ri-search-line search-icon"></i>
                    </div>
                  </div>
                </div> */}
              </Row>
            </CardHeader>
            <CardBody>
              <TableContainer
                loading={loading}
                columns={columns}
                data={usSanctionsList || []}
                pagination={pagination}
                setPagination={setPagination}
                sorted={sorted}
                setSorted={setSorted}
                onPageChange={onPageChange}
                FilterSection={() => {}}
                totalRows={meta?.pagination?.total || 0}
                totalPages={meta?.pagination?.pageCount || 0}
                searchValue={searchValue}
                divClass="table-responsive table-card mb-4"
                tableClass="align-middle table-nowrap mb-0 table-striped"
                theadClass="table-light table-nowrap"
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default USSanctionsList;
