import React, { useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { getContractDistributionByRegion } from "common/services/contracts.service";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { setDistributionByRegion } from "slices/contract/reducer";
import { createSelector } from "reselect";
import getChartColorsArray from "Components/Common/ChartsDynamicColor";

const extractTotalValues = (
  data: Array<{ region: string; totalValue: number; percentage: number }>
): number[] => {
  return data.map((item) => item.totalValue);
};

const extractRegionNames = (
  data: Array<{ region: string; totalValue: number; percentage: number }>
): string[] => {
  return data.map((item) => item.region);
};

const ContractDistributionByRegion = () => {
  const dispatch: any = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentYear = new Date().getFullYear();
        const response = await getContractDistributionByRegion({
          startDate: `${currentYear}-01-01`,
          endDate: `${currentYear}-12-31`,
        });

        dispatch(setDistributionByRegion(response.data));
      } catch (error) {
        console.error("Error fetching contract distribution by region", error);
      }
    };
    fetchData();
  }, [dispatch]);

  const selectProperties = createSelector(
    (state: any) => state,
    (state) => ({
      distributionByRegion: state.Contracts.distributionByRegion,
      contractTotals: state.Contracts.contractTotals,
    })
  );

  const { distributionByRegion } = useSelector(selectProperties);

  var chartPieBasicColors = getChartColorsArray(
    '["--vz-primary", "--vz-success", "--vz-warning", "--vz-danger", "--vz-info", "--vz-success", "--vz-primary"]'
  );

  const series = extractTotalValues(distributionByRegion.data);
  var options: any = {
    chart: {
      height: 300,
      type: "pie",
    },
    labels: extractRegionNames(distributionByRegion.data),
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      verticalAlign: "bottom",
      fontSize: "14px",

      formatter: function (val: string, opts: any) {
        return val + ": " + opts.w.globals.series[opts.seriesIndex];
      },
    },
    dataLabels: {
      dropShadow: {
        enabled: false,
      },
      enabled: true,
    },
    colors: chartPieBasicColors,
  };

  return (
    <React.Fragment>
      <Card className="card-height-100">
        <CardHeader className="align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1">
            Distribution of Contracts by Region
          </h4>
          <div className="flex-shrink-0">
            <UncontrolledDropdown className="card-header-dropdown">
              <DropdownToggle
                tag="a"
                className="text-reset dropdown-btn "
                role="button"
              >
                <span className="fw-semibold text-uppercase fs-12">
                  Year :{" "}
                </span>
                <span className="text-muted">
                  {new Date().getFullYear()}
                  <i className="mdi mdi-chevron-down ms-1"></i>
                </span>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-start">
                <DropdownItem
                  onClick={() => {}}
                  className={true ? "active" : ""}
                >
                  {new Date().getFullYear()}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </CardHeader>

        <CardBody>
          <ReactApexChart
            dir="ltr"
            className="apex-charts"
            series={series}
            options={options}
            type="pie"
            height={380}
          />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default ContractDistributionByRegion;
