import React, { useCallback, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

import TableContainer from "Components/Common/TableContainer";
import BreadCrumb from "Components/Common/BreadCrumb";

import { getSortObj } from "common/lib/tableDataOptions";
import * as moment from "moment";
import Permission from "Components/Common/Permission";
import {
  getContractTypes,
  deleteContractType,
} from "common/services/contracts.service";
import { normalize } from "common/lib/normalize";
import { toast } from "react-toastify";
import AddContractType from "./Add";
import UpdateContractType from "./Update";
import DeleteModal from "Components/Common/DeleteModal";

const ContractTypes = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [params] = useState({
    page: Number(searchParams.get("page")) || 1,
    pageSize: Number(searchParams.get("pageSize")) || 20,
  });

  const [pagination, setPagination] = useState({
    pageIndex: params.page - 1, // Convert to zero-based index
    pageSize: params.pageSize,
  });

  // Update pageIndex
  const setPage = (newPage: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      pageIndex: newPage - 1,
    }));
  };

  // Update pageSize
  // const setPageSize = (newPageSize: number) => {
  //   setPagination((prevPagination) => ({
  //     ...prevPagination,
  //     pageSize: newPageSize,
  //   }));
  // };

  const [sorted, setSorted] = useState(() => {
    const sortParam = searchParams.get("sort");
    return sortParam
      ? JSON.parse(sortParam)
      : [{ id: "createdAt", desc: true }];
  });

  const [selectedRecord, setSelectedRecord] = useState<any>({});

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const showAddModalForm = () => {
    setIsAddModalOpen(true);
  };

  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const showUpdateModalForm = (data: any) => {
    setSelectedRecord(data);
    setIsUpdateModalOpen(true);
  };

  const fetchData = () => {
    setPage(1);
    setSorted([{ id: "createdAt", desc: true }]);
    setSearchValue("");
  };

  const [searchValue, setSearchValue] = useState(
    searchParams.get("search") || ""
  );

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState<any>({});
  const onPageChange = useCallback(
    async ({
      page,
      sorted,
      searchValue,
    }: {
      page: number;
      sorted: Array<{ id: string; desc: boolean }>;
      searchValue: string;
    }) => {
      const params = new URLSearchParams(searchParams);
      params.set("page", String(page));
      params.set("sort", JSON.stringify(sorted));
      params.set("search", searchValue);
      setSearchParams(params, { replace: true });

      const sortObj = getSortObj(sorted);

      let andConditions = [];
      andConditions.push({
        $or: [
          {
            name: {
              $containsi: searchValue,
            },
          },
        ],
      });

      try {
        setLoading(true);

        const response: any = await getContractTypes({
          pagination: {
            page,
            pageSize: pagination.pageSize,
          },
          sort: sortObj,
          populate: ["updatedby"],
          filters: {
            $and: andConditions,
          },
        });
        setData(normalize(response.data));
        setMeta(response?.meta);
      } catch (e: any) {
        toast.error("Error while fetching data", {});
      } finally {
        setLoading(false);
      }
    },
    [pagination.pageSize, searchParams, setSearchParams]
  );

  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = useCallback(
    (data: any) => {
      setSelectedRecord(data);
      setDeleteModal(true);
    },
    [setSelectedRecord, setDeleteModal]
  );

  const fetchUpdatedData = useCallback(() => {
    onPageChange({
      page: pagination.pageIndex + 1,
      sorted: sorted,
      searchValue: searchValue,
    });
  }, [onPageChange, pagination.pageIndex, sorted, searchValue]);

  const [deleting, setDeleting] = useState(false);
  const handleDelete = async () => {
    if (selectedRecord) {
      setDeleting(true);
      try {
        await deleteContractType(selectedRecord?.id);
        fetchUpdatedData();
        setDeleting(false);
        setDeleteModal(false);
        toast.success("Contract type deleted successfully");
      } catch (e: any) {
        toast.error("Error while deleting Contract type");
      } finally {
        setDeleting(false);
      }
    }
  };

  const columns = useMemo(
    () => [
      {
        header: "Name",
        accessorKey: "name",
        enableColumnFilter: false,
      },
      {
        header: "Last Updated",
        accessorKey: "updatedAt",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const date = moment(new Date(cell?.getValue())).format("DD MMM Y");
          return <>{date}</>;
        },
      },
      {
        header: "Action",
        enableSorting: false,
        cell: (cellProps: any) => {
          const rowData = cellProps.row.original;
          return (
            <UncontrolledDropdown>
              <DropdownToggle
                href="#"
                className="btn btn-soft-secondary btn-sm dropdown"
                tag="button"
              >
                <i className="ri-more-fill align-middle"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <Permission resource="contract-type" action="update">
                  <DropdownItem
                    href="/#"
                    onClick={(e) => {
                      e.preventDefault();
                      showUpdateModalForm({
                        id: rowData.id,
                        ...rowData,
                      });
                    }}
                  >
                    <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}
                    Edit
                  </DropdownItem>
                </Permission>

                <Permission resource="contract-type" action="delete">
                  <DropdownItem divider />
                  <DropdownItem
                    href="#"
                    onClick={() => {
                      onClickDelete(rowData);
                    }}
                  >
                    <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
                    Delete
                  </DropdownItem>
                </Permission>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    [onClickDelete]
  );
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Contract Types"
            pageTitles={[
              { title: "Dashboard", link: "/dashboard" },
              {
                title: "Data Types",
                link: "/data-types/contract-types",
              },
            ]}
          />

          <Card>
            <CardHeader>
              <Row className="align-items-center g-3">
                <Col md={8}>
                  <h5 className="card-title">Contract Types</h5>
                  <p className="mb-0 text-muted">
                    Contract types e.g. Goods, Services, Implementing Partner
                    etc.
                  </p>
                </Col>
                <div className="col-md-auto ms-auto">
                  <div className="d-flex gap-2">
                    <div className="search-box">
                      <input
                        type="text"
                        className="form-control search"
                        placeholder="Search contract types..."
                        onChange={(e) => {
                          setSearchValue(e.target.value);
                          setPage(1);
                        }}
                        value={searchValue}
                      />
                      <i className="ri-search-line search-icon"></i>
                    </div>
                    <Permission resource="contract-type" action="create">
                      <button
                        className="btn btn-primary"
                        onClick={() => showAddModalForm()}
                      >
                        <i className="ri-add-line align-bottom me-1"></i>
                        Add
                      </button>
                    </Permission>
                  </div>
                </div>
              </Row>
            </CardHeader>
            <CardBody>
              <TableContainer
                loading={loading}
                columns={columns}
                data={data || []}
                pagination={pagination}
                setPagination={setPagination}
                sorted={sorted}
                setSorted={setSorted}
                onPageChange={onPageChange}
                FilterSection={() => {}}
                totalRows={meta?.pagination?.total || 0}
                totalPages={meta?.pagination?.pageCount || 0}
                searchValue={searchValue}
                divClass="table-responsive table-card mb-4"
                tableClass="align-middle table-nowrap mb-0 table-striped"
                theadClass="table-light table-nowrap"
              />
              <AddContractType
                toggle={() => setIsAddModalOpen((state) => !state)}
                isModalOpen={isAddModalOpen}
                fetchData={fetchData}
              />
              <UpdateContractType
                toggle={() => setIsUpdateModalOpen((state) => !state)}
                isModalOpen={isUpdateModalOpen}
                selectedRecord={selectedRecord}
                fetchData={fetchUpdatedData}
              />
              <DeleteModal
                show={deleteModal}
                onDeleteClick={() => handleDelete()}
                onCloseClick={() => setDeleteModal(false)}
                loading={deleting}
              />
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ContractTypes;
