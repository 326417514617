import { postJwtRegister } from "helpers/backend_helper";

// action
import {
  registerUserSuccessful,
  registerUserFailed,
  resetRegisterFlagChange,
  setLoading,
} from "./reducer";
import { POST_REGISTER } from "helpers/url_helper";

// const oldMsg =
//   "Your account has been registered successfully. A message has been sent to your email account with further instructions.";

export const registerUser =
  (user: any, validation: any) => async (dispatch: any) => {
    try {
      dispatch(setLoading(true));

      const response: any = await postJwtRegister(POST_REGISTER, {
        username: user.email,
        email: user.email,
        password: user.password,
        firstName: user.firstName,
        lastName: user.lastName,
        title: user.title,
        agency: user.agency?.value,
        reCaptchaToken: user.reCaptchaToken,
      });

      if (response.jwt) {
        dispatch(
          registerUserSuccessful(
            "Your account has been registered successfully. We will be in touch shortly with further instructions regarding your account."
          )
        );
        validation.resetForm();
      } else {
        dispatch(registerUserFailed(response));
      }
      dispatch(setLoading(false));
    } catch (error: any) {
      const errorMsg = error?.response?.data?.error?.message || "";
      dispatch(registerUserFailed(errorMsg.replace("or Username", "")));
      dispatch(setLoading(false));
    }
  };

export const resetRegisterFlag = () => {
  try {
    const response = resetRegisterFlagChange();
    return response;
  } catch (error) {
    return error;
  }
};
