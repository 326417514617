import React, { useEffect, useMemo, useState } from "react";
import { getUKSanctionsList } from "common/services/sanctions-list.service";
import { normalize } from "common/lib/normalize";

type RegimeFilterProps = {
  onChange: (value: any) => void;
  value: any;
};

const RegimeFilter = ({ onChange, value }: RegimeFilterProps) => {
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await getUKSanctionsList({
          pagination: {
            page: 1,
            pageSize: 10000,
          },
          filters: {
            regime: {
              $ne: null,
            },
          },
        });

        setData(normalize(response.data));
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [setLoading]);

  const options = useMemo(() => {
    const uniqueOptions = new Set();
    return data
      .map((el: any) => {
        return {
          value: el?.regime,
          label: el?.regime,
        };
      })
      .filter((option) => {
        if (uniqueOptions.has(option.value)) {
          return false;
        } else {
          uniqueOptions.add(option.value);
          return true;
        }
      });
  }, [data]);

  return (
    <select
      className="form-control"
      data-choices
      data-choices-search-false
      name="choices-single-default2"
      id="choices-single-default2"
      onChange={(e) => {
        onChange(e.target.value);
      }}
      value={value}
    >
      {loading && <option>Loading...</option>}
      <option value="">Select Regime</option>
      {options.map((option: any) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default RegimeFilter;
