import React, { useState, useMemo, useCallback } from "react";
import {
  CardBody,
  Row,
  Col,
  Card,
  Container,
  CardHeader,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledButtonDropdown,
} from "reactstrap";
import { Link, useSearchParams } from "react-router-dom";
import BreadCrumb from "Components/Common/BreadCrumb";
import TableContainer from "Components/Common/TableContainer";
import DeleteModal from "Components/Common/DeleteModal";
import * as moment from "moment";
import { toast } from "react-toastify";
import AddUser from "./AddUser";
import UpdateUser from "./UpdateUser";
import { getSortObj } from "common/lib/tableDataOptions";
import BlockUserModal from "Components/Common/BlockUserModal";
import SendPasswordResetModal from "Components/Common/SendPasswordResetModal";
import Permission from "Components/Common/Permission";
import Permissions from "Components/Common/Permissions";
import {
  getUsers,
  deleteUser,
  activateUser,
  deactivateUser,
  sendPasswordResetLink,
} from "common/services/users.service";
import { normalize } from "common/lib/normalize";
import AgencyFilter from "pages/Contracts/AgencyFilter";
import Flatpickr from "react-flatpickr";
import RoleFilter from "./RoleFilter";

interface DateRange {
  fromDate: Date | null;
  toDate: Date | null;
}

interface FilterValue {
  role: string;
  agency: string;
  createdAt: DateRange;
}

const getInitials = (firstName: string | null, lastName: string | null) => {
  const firstInitial = firstName ? firstName.charAt(0).toUpperCase() : "";
  const lastInitial = lastName ? lastName.charAt(0).toUpperCase() : "";
  return `${firstInitial}${lastInitial}`;
};

const Users = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [params] = useState({
    page: Number(searchParams.get("page")) || 1, // Starts from 1
    pageSize: Number(searchParams.get("pageSize")) || 20, // Default page size
  });

  const [pagination, setPagination] = useState({
    pageIndex: params.page - 1, // Convert to zero-based index
    pageSize: params.pageSize,
  });

  // Update pageIndex
  const setPage = (newPage: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      pageIndex: newPage - 1,
    }));
  };

  const [sorted, setSorted] = useState(() => {
    const sortParam = searchParams.get("sort");
    return sortParam
      ? JSON.parse(sortParam)
      : [{ id: "createdAt", desc: true }];
  });

  const [selectedRecord, setSelectedRecord] = useState<any>({});

  const [deleteModal, setDeleteModal] = useState(false);

  const fetchUsers = () => {
    onPageChange({
      page: 1,
      sorted: [{ id: "createdAt", desc: true }],
      searchValue: "",
    });
  };

  const [searchValue, setSearchValue] = useState(
    searchParams.get("search") || ""
  );
  const defaultFilterValue: FilterValue = {
    role: "",
    agency: "",
    createdAt: {
      fromDate: null,
      toDate: null,
    },
  };

  const [filterValue, setFilterValue] = useState(() => {
    const filterParam = searchParams.get("filter");
    return filterParam ? JSON.parse(filterParam) : defaultFilterValue;
  });

  const unCheckAll = useCallback(() => {
    const checkall: any = document.getElementById("checkBoxAll");
    checkall.checked = false;
    setSelectedCheckBox([]);
  }, []);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [meta, setmeta] = useState<any>({});
  const onPageChange = useCallback(
    async ({
      page,
      sorted,
      searchValue,
    }: {
      page: number;
      sorted: Array<{ id: string; desc: boolean }>;
      searchValue: string;
    }) => {
      const params = new URLSearchParams(searchParams);
      params.set("page", String(page));
      params.set("sort", JSON.stringify(sorted));
      params.set("search", searchValue);
      params.set("filter", JSON.stringify(filterValue));

      setSearchParams(params, { replace: true });

      const sortObj = getSortObj(sorted);

      let andConditions = [];
      if (filterValue.role) {
        andConditions.push({
          role: {
            id: filterValue.role,
          },
        });
      }
      if (filterValue.agency) {
        andConditions.push({
          agency: {
            id: filterValue.agency,
          },
        });
      }
      if (filterValue.createdAt.fromDate && filterValue.createdAt.toDate) {
        andConditions.push({
          $or: [
            {
              createdAt: {
                $between: [
                  filterValue.createdAt.fromDate,
                  filterValue.createdAt.toDate,
                ],
              },
            },
          ],
        });
      }
      andConditions.push({
        $or: [
          {
            email: {
              $containsi: searchValue,
            },
          },

          {
            firstName: {
              $containsi: searchValue,
            },
          },
          {
            lastName: {
              $containsi: searchValue,
            },
          },
        ],
      });

      try {
        setLoading(true);
        unCheckAll();
        const response: any = await getUsers({
          pagination: {
            page,
            pageSize: pagination.pageSize,
          },
          sort: sortObj,
          populate: ["role", "agency", "country", "roles.permissions"],
          filters: {
            $and: andConditions,
          },
        });
        setData(normalize(response.data));
        setmeta(response.meta);
      } catch (e: any) {
        toast.error("Error while fetching data.", { autoClose: 500 });
      } finally {
        setLoading(false);
      }
    },
    [
      filterValue,
      pagination.pageSize,
      searchParams,
      setSearchParams,
      unCheckAll,
    ]
  );

  const fetchUpdatedUsers = useCallback(() => {
    onPageChange({
      page: pagination.pageIndex + 1,
      sorted: sorted,
      searchValue: searchValue,
    });
  }, [onPageChange, pagination.pageIndex, searchValue, sorted]);

  /* Delete User */
  const onClickDelete = (users: any) => {
    setSelectedRecord(users);
    setDeleteModal(true);
  };
  const [deleting, setDeleting] = useState(false);
  const handleDelete = async () => {
    if (selectedRecord) {
      setDeleting(true);
      try {
        await deleteUser(selectedRecord);
        setDeleting(false);
        toast.success("User deleted successfully.");
        fetchUpdatedUsers();
        setDeleteModal(false);
      } catch (e: any) {
        toast.error("Error while deleting data.");
      } finally {
        setDeleting(false);
      }
    }
  };

  const onClickActivate = (record: any, action: string) => {
    setSelectedRecord(record);
    setIsBlockModalOpen(action);
  };
  const [activating, setActivating] = useState(false);
  const [isBlockModalOpen, setIsBlockModalOpen] = useState("");
  const handleActivateUser = async (action: string) => {
    if (action === "activate") {
      setActivating(true);
      try {
        await activateUser(selectedRecord);
        setActivating(false);
        fetchUpdatedUsers();
        setIsBlockModalOpen("");
      } catch (e: any) {
        toast.error("Error while activating user.", { autoClose: 500 });
      }
    } else if (action === "deactivate") {
      setActivating(true);
      try {
        await deactivateUser(selectedRecord);
        setActivating(false);
        fetchUpdatedUsers();
        setIsBlockModalOpen("");
      } catch (e: any) {
        toast.error("Error while deactivating user.", { autoClose: 500 });
      } finally {
        setActivating(false);
      }
    }
  };

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const showAddModalForm = () => {
    setIsAddModalOpen(true);
  };

  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const showUpdateModalForm = (users: any) => {
    setSelectedRecord(users);
    setIsUpdateModalOpen(true);
  };

  const onClickSendPasswordReset = (record: any) => {
    setSelectedRecord(record);
    setIsPasswordResetModalOpen(true);
  };
  const [sendingPasswordReset, setSendingPasswordReset] = useState(false);
  const [isPasswordResetModalOpen, setIsPasswordResetModalOpen] =
    useState(false);
  const handleSendPasswordReset = async (record: any) => {
    setSendingPasswordReset(true);
    try {
      await sendPasswordResetLink({
        email: record?.email,
      });
      setSendingPasswordReset(false);
      setIsPasswordResetModalOpen(false);
    } catch (e: any) {
      toast.error("Error while sending password reset link.", {
        autoClose: 500,
      });
    } finally {
      setSendingPasswordReset(false);
    }
  };

  const checkedAll = useCallback(() => {
    const checkall: any = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".tableCheckbox");

    if (checkall.checked) {
      ele.forEach((ele: any) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele: any) => {
        ele.checked = false;
      });
    }
    onSelectCheckbox();
  }, []);

  const [selectedCheckBox, setSelectedCheckBox] = useState<any>([]);

  const onSelectCheckbox = () => {
    const ele = document.querySelectorAll(".tableCheckbox:checked");
    setSelectedCheckBox(ele);
  };

  /* Delete Multiple */
  const [deleteMultipleModal, setDeleteMultipleModal] = useState(false);
  const handleDeleteMultiple = async () => {
    const selectedIds = Array.from(selectedCheckBox).map((ele: any) => {
      return ele.value;
    });
    if (selectedIds.length > 0) {
      setDeleting(true);
      try {
        await Promise.all(
          selectedIds.map((id: any) => {
            return deleteUser({ id });
          })
        );
        setDeleting(false);
        toast.success("Users deleted successfully.");
        fetchUpdatedUsers();
        setDeleteMultipleModal(false);
      } catch (e: any) {
        toast.error("Error while deleting data.");
      } finally {
        setDeleting(false);
      }
    }
  };

  const columns = useMemo(
    () => [
      {
        header: (
          <input
            type="checkbox"
            id="checkBoxAll"
            className="form-check-input"
            onClick={() => checkedAll()}
          />
        ),
        cell: (cell: any) => {
          return (
            <input
              type="checkbox"
              className="tableCheckbox form-check-input"
              value={cell.getValue()}
              onChange={() => onSelectCheckbox()}
            />
          );
        },
        id: "#",
        accessorKey: "id",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Name",
        accessorKey: "firstName",
        id: "firstName",
        enableColumnFilter: false,
        cell: (user: any) => {
          const firstName = user?.row?.original?.firstName || null;
          const lastName = user?.row?.original?.lastName || null;
          const initials = getInitials(firstName, lastName);

          return (
            <>
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0 avatar-sm me-3">
                  <div className="avatar-sm rounded-circle p-1">
                    <div className="avatar-title bg-primary-subtle text-primary rounded-circle img-thumbnail">
                      {initials || ""}
                    </div>
                  </div>
                </div>
                <Link to={`#`} className="fw-medium link-primary">
                  {`${user?.row?.original?.firstName || ""} ${
                    user?.row?.original?.lastName || ""
                  }`}
                </Link>
              </div>
              {/* <div className="d-flex align-items-center">
              {user.row.original.img ? (
                <img
                  src={""}
                  alt=""
                  className="avatar-xs rounded-circle me-2"
                />
              ) : (
                <div className="flex-shrink-0 avatar-sm me-3">
                  <div className="avatar-title bg-primary-subtle text-primary rounded-circle fs-12">
                    <i className={"ri-user-line"}></i>
                    HB
                  </div>
                </div>
              )}
              <Link to={`#`} className="fw-medium link-primary">
                {`${user?.row?.original?.firstName || ""} ${
                  user?.row?.original?.lastName || ""
                }`}
              </Link>
            </div> */}
            </>
          );
        },
      },
      {
        header: "Email",
        accessorKey: "email",
        id: "email",
        hidden: true,
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <Link to="#" className="fw-medium link-primary">
              {cell.getValue()}
            </Link>
          );
        },
      },

      {
        header: "Role",
        accessorKey: "role.name",
        id: "role.name",
        enableColumnFilter: false,
      },
      {
        header: "Agency",
        accessorKey: "agency.name",
        id: "agency.name",
        enableColumnFilter: false,
      },

      {
        header: "Status",
        accessorKey: "blocked",
        id: "blocked",
        enableColumnFilter: false,
        cell: (cell: any) => {
          if (cell.getValue() === false) {
            return (
              <span className="badge text-uppercase bg-success-subtle text-success">
                Active
              </span>
            );
          } else if (cell.getValue() === true) {
            return (
              <span className="badge text-uppercase bg-danger-subtle text-danger">
                Blocked
              </span>
            );
          } else {
            return (
              <span className="badge text-uppercase bg-warning-subtle text-warning">
                N/A
              </span>
            );
          }
        },
      },

      {
        header: "Last Updated",
        accessorKey: "updatedAt",
        id: "updatedAt",
        enableColumnFilter: false,
        cell: (cell) => {
          const date = moment(new Date(cell?.getValue())).format("DD MMM Y");
          // const time = moment(new Date(cell?.getValue())).format("hh:mm A");
          return (
            <>
              {date}
              {/* <small className="text-muted">{time}</small> */}
            </>
          );
        },
      },

      {
        header: "Last Login Time",
        accessorKey: "lastLoginTime",
        id: "lastLoginTime",
        enableColumnFilter: false,
        cell: (cell) => {
          if (!cell?.getValue()) {
            return " -";
          }
          const date = moment(new Date(cell?.getValue())).format("DD MMM Y");
          const time = moment(new Date(cell?.getValue())).format("hh:mm A");
          return (
            <>
              {date}
              <small className="text-muted ms-1">{time}</small>
            </>
          );
        },
      },

      {
        header: "Action",
        id: "action",
        cell: (cellProps: any) => {
          const rowData = cellProps.row.original;
          return (
            <UncontrolledDropdown>
              <DropdownToggle
                href="#"
                className="btn btn-soft-secondary btn-sm dropdown"
                tag="button"
              >
                <i className="ri-more-fill align-middle"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end" end>
                {/* <DropdownItem href="/apps-invoices-details">
                  <i className="ri-eye-fill align-bottom me-2 text-muted"></i>{" "}
                  View
                </DropdownItem> */}

                <Permission resource="user-management" action="update">
                  <DropdownItem
                    href="#"
                    onClick={() =>
                      showUpdateModalForm({
                        id: rowData.id,
                        ...rowData,
                      })
                    }
                  >
                    <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}
                    Edit User
                  </DropdownItem>
                </Permission>
                {rowData?.blocked === false && (
                  <Permission resource="user-management" action="deactivate">
                    <DropdownItem
                      href="#"
                      onClick={() => {
                        onClickActivate(rowData, "deactivate");
                      }}
                    >
                      <i className="ri-shield-cross-fill align-bottom me-2 text-muted"></i>{" "}
                      Block User
                    </DropdownItem>
                  </Permission>
                )}

                {rowData?.blocked === true && (
                  <Permission resource="user-management" action="activate">
                    <DropdownItem
                      href="#"
                      onClick={() => {
                        onClickActivate(rowData, "activate");
                      }}
                    >
                      <i className="ri-shield-check-fill align-bottom me-2 text-muted"></i>{" "}
                      Unblock
                    </DropdownItem>
                  </Permission>
                )}

                <Permission
                  resource="users-permissions"
                  controller="auth"
                  action="forgotPassword"
                  category="plugin"
                >
                  <DropdownItem
                    href="#"
                    onClick={() => {
                      onClickSendPasswordReset(rowData);
                    }}
                  >
                    <i className="ri-mail-send-fill align-bottom me-2 text-muted"></i>{" "}
                    Send Password Reset Email
                  </DropdownItem>
                </Permission>

                <Permission resource="user-management" action="delete">
                  <DropdownItem divider />

                  <DropdownItem
                    href="#"
                    onClick={() => {
                      onClickDelete({
                        id: rowData.id,
                        ...rowData.attributes,
                      });
                    }}
                  >
                    <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
                    Delete User
                  </DropdownItem>
                </Permission>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    [checkedAll]
  );

  document.title = "Users List | CIMS";

  return (
    <React.Fragment>
      <div className="page-content">
        <SendPasswordResetModal
          show={isPasswordResetModalOpen}
          onConfirmClick={() => handleSendPasswordReset(selectedRecord)}
          onCloseClick={() => setIsPasswordResetModalOpen(false)}
          loading={sendingPasswordReset}
          action={isBlockModalOpen}
        />
        <DeleteModal
          show={deleteModal}
          onDeleteClick={() => handleDelete()}
          onCloseClick={() => setDeleteModal(false)}
          loading={deleting}
        />
        <DeleteModal
          show={deleteMultipleModal}
          onDeleteClick={() => handleDeleteMultiple()}
          onCloseClick={() => setDeleteMultipleModal(false)}
          loading={deleting}
        />
        <BlockUserModal
          show={isBlockModalOpen}
          onConfirmClick={() => handleActivateUser(isBlockModalOpen)}
          onCloseClick={() => setIsBlockModalOpen("")}
          loading={activating}
          action={isBlockModalOpen}
        />

        <Container fluid>
          <BreadCrumb
            title="Users"
            pageTitles={[{ title: "Dashboard", link: "/dashboard" }]}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <Row className="g-3">
                    <Col xxl={3} lg={6}>
                      <h6 className="text-uppercase fs-12 mb-2">Search</h6>
                      <div className="search-box">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search users..."
                          onChange={(e) => {
                            setSearchValue(e.target.value);
                            setPage(1);
                          }}
                          value={searchValue}
                        />
                        <i className="ri-search-line search-icon"></i>
                      </div>
                    </Col>
                    <Col xxl={2} lg={6}>
                      <h6 className="text-uppercase fs-12 mb-2">Select Role</h6>
                      <RoleFilter
                        onChange={(value) => {
                          setFilterValue({
                            ...filterValue,
                            role: value,
                          });
                          setPage(1);
                        }}
                        value={filterValue.role}
                      />
                    </Col>
                    <Col xxl={3} lg={6}>
                      <h6 className="text-uppercase fs-12 mb-2">
                        Select Join Date Range
                      </h6>
                      {/* @ts-ignore */}
                      <Flatpickr
                        className="form-control"
                        placeholder="Select Date Range"
                        options={{
                          mode: "range",
                          altFormat: "d M, Y",
                          dateFormat: "d M, Y",
                        }}
                        onChange={(e) => {
                          if (e[0] && e[1]) {
                            setFilterValue({
                              ...filterValue,
                              createdAt: {
                                fromDate: e[0],
                                toDate: e[1],
                              },
                            });
                            setPage(1);
                          }
                        }}
                        value={[
                          filterValue?.createdAt?.fromDate || "",
                          filterValue?.createdAt?.toDate || "",
                        ]}
                      />
                    </Col>
                    <Col xxl={2} lg={6}>
                      <h6 className="text-uppercase fs-12 mb-2">
                        Select Agency
                      </h6>
                      <AgencyFilter
                        onChange={(value) => {
                          setFilterValue({ ...filterValue, agency: value });
                          setPage(1);
                        }}
                        value={filterValue.agency}
                      />
                    </Col>
                    <Col xxl={2} lg={4}>
                      <h6 className="text-uppercase fs-12 mb-xxl-4"> {` `}</h6>
                      <button
                        onClick={() => {
                          setFilterValue(defaultFilterValue);
                          setSearchValue("");
                          setPage(1);
                          setSorted([{ id: "createdAt", desc: true }]);
                        }}
                        className="btn btn-outline-primary material-shadow-none w-100"
                      >
                        <i className="ri-equalizer-line align-bottom me-1"></i>{" "}
                        Reset Filters
                      </button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card>
                <CardHeader>
                  <Row className="align-items-center g-3">
                    <Col md={8}>
                      <h5 className="card-title mb-1">Users</h5>
                      <p className="text-muted mb-0">
                        This is a detailed list of all of the CIMS users. This
                        includes users in the RMU and UN agency focal points.
                      </p>
                    </Col>
                    <div className="col-md-auto ms-auto">
                      <div className="d-flex gap-2">
                        <Permissions
                          checks={[
                            { resource: "user-management", action: "delete" },
                          ]}
                        >
                          {selectedCheckBox.length > 0 && (
                            <UncontrolledButtonDropdown>
                              <DropdownToggle
                                color="secondary"
                                caret
                                className="material-shadow-none btn-soft-secondary"
                              >
                                Bulk Actions - {selectedCheckBox.length}{" "}
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem
                                  onClick={() => {
                                    setDeleteMultipleModal(true);
                                  }}
                                >
                                  <i className="ri-delete-bin-line align-middle me-2"></i>{" "}
                                  Delete
                                </DropdownItem>
                                {/* <DropdownItem>
                                <i className="ri-shield-cross-line align-middle me-2"></i>{" "}
                                Block
                              </DropdownItem> */}
                              </DropdownMenu>
                            </UncontrolledButtonDropdown>
                          )}
                        </Permissions>

                        <Permission resource="user-management" action="create">
                          <Link
                            to="#"
                            className="btn btn-primary"
                            onClick={(e) => {
                              e.preventDefault();
                              showAddModalForm();
                            }}
                          >
                            <i className="ri-add-line align-bottom me-1"></i>
                            Add User
                          </Link>
                        </Permission>

                        {/* <Permissions
                  checks={[
                    {
                      resource: "contract",
                      action: "import",
                    },
                    {
                      resource: "contract",
                      action: "export",
                    },
                  ]}
                >
                  <Import apiParams={apiParams} />
                </Permissions> */}
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div>
                    <TableContainer
                      loading={loading}
                      columns={columns}
                      data={data || []}
                      pagination={pagination}
                      setPagination={setPagination}
                      sorted={sorted}
                      setSorted={setSorted}
                      onPageChange={onPageChange}
                      FilterSection={() => {}}
                      totalRows={meta?.pagination?.total || 0}
                      totalPages={meta?.pagination?.pageCount || 0}
                      searchValue={searchValue}
                      divClass="table-responsive table-card mb-4"
                      tableClass="align-middle table-nowrap mb-0 table-striped"
                      theadClass="table-light table-nowrap"
                    />
                  </div>
                  <AddUser
                    toggle={() => setIsAddModalOpen((state) => !state)}
                    isModalOpen={isAddModalOpen}
                    fetchUsers={fetchUsers}
                  />
                  <UpdateUser
                    toggle={() => setIsUpdateModalOpen((state) => !state)}
                    isModalOpen={isUpdateModalOpen}
                    selectedRecord={selectedRecord}
                    fetchUpdatedUsers={fetchUpdatedUsers}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Users;
