import React, { useEffect, useState } from "react";
import { Container, Row } from "reactstrap";

import Header from "./Header";
import MainDetails from "./MainDetails";
import AdditionalDetails from "./AdditionalDetails";
import { useParams } from "react-router-dom";
import { getWBBannedItem } from "common/services/sanctions-list.service";
import BreadCrumb from "Components/Common/BreadCrumb";
import { toast } from "react-toastify";
import { normalize } from "common/lib/normalize";

const ViewWBItem = () => {
  const { id: itemId } = useParams();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>({});
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await getWBBannedItem(itemId, {});
        setData(normalize(response.data));
      } catch (error) {
        toast.error("Error while fetching data");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [itemId]);

  document.title = "World Bank Banned List | CIMS";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="World Bank Banned List Details"
            pageTitles={[
              {
                title: "Dashboard",
                link: "/",
              },
              {
                title: "World Bank Debarred List",
                link: "/sanctions-list/world-bank",
              },
            ]}
            back
          />

          <Row>
            <Header data={data} />
          </Row>
          <Row>
            <MainDetails data={data} />
            <AdditionalDetails data={data} />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ViewWBItem;
