import React, { useMemo, useEffect, useState } from "react";
import { Label, FormFeedback, FormGroup } from "reactstrap";
import Select from "react-select";

import { getRegions } from "common/services/regions.service";
import { COUNTRY_LEVEL } from "common/constants";
import { normalize } from "common/lib/normalize";

const SelectNationalityAtBirth = ({
  validation,
  setLoading,
}: {
  validation: any;
  setLoading: any;
}) => {
  const customSelectStyles = {
    control: (styles: any) => ({
      ...styles,
      borderColor:
        validation.touched.nationalityAtBirth &&
        validation.errors.nationalityAtBirth
          ? "red"
          : "#ced4da",
      "&:hover": {
        borderColor:
          validation.touched.nationalityAtBirth &&
          validation.errors.nationalityAtBirth
            ? "red"
            : "#ced4da",
      },
    }),
  };

  const [data, setData] = useState([]);

  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      try {
        const response = await getRegions({
          filters: {
            level: COUNTRY_LEVEL,
          },
        });
        setData(normalize(response.data));
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [setLoading]);

  const options = useMemo(() => {
    return data.map((el: any) => {
      return {
        value: el?.id,
        label: el?.name,
      };
    });
  }, [data]);

  return (
    <FormGroup>
      <Label htmlFor="nationalityAtBirth-field" className="form-label">
        Nationality at Birth
      </Label>
      <Select
        name="nationalityAtBirth"
        id="nationalityAtBirth"
        value={
          validation.values.nationalityAtBirth.value
            ? validation.values.nationalityAtBirth
            : null
        }
        placeholder="Select nationality at birth"
        onChange={(value: any) => {
          validation.setFieldValue("nationalityAtBirth", value);
        }}
        options={options}
        onBlur={() => validation.setFieldTouched("nationalityAtBirth", true)}
        className={
          validation.touched.nationalityAtBirth &&
          validation.errors.nationalityAtBirth
            ? "is-invalid"
            : ""
        }
        styles={customSelectStyles}
      />
      <FormFeedback>{validation.errors.nationalityAtBirth?.value}</FormFeedback>
    </FormGroup>
  );
};

export default SelectNationalityAtBirth;
