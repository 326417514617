import * as url from "helpers/url_helper";
import { APIClient } from "helpers/api_helper";

const api = new APIClient();

export const downloadSearchReport = (params: any) =>
  api.get(url.DOWNLOAD_SEARCH_REPORT, params, {
    responseType: "blob",
  });
export const downloadBulkSearchTemplate = () =>
  api.get(url.DOWNLOAD_BULK_SEARCH_TEMPLATE, {}, { responseType: "blob" });
export const bulkSearch = (data: any) =>
  api.createAndUpload(url.BULK_SEARCH, data);
export const bulkSearchProgress = (id: any, params: any) =>
  api.get(url.BULK_SEARCH_PROGRESS + "/" + id, params);

export const searchGlobalContent = (data: any) => {
  return api.get(url.SEARCH_GLOBAL_CONTENT, data);
};
