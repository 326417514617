import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import SummaryWidgets from "./SummaryWidgets";
import ContractDistribution from "./ContractDistribution";
import ContractDistributionMap from "./ContractDistributionMap";
import HeaderSection from "./HeaderSection";
import { useDispatch } from "react-redux";
import { getContractsSummary } from "slices/thunks";
import { COUNTRY_CODE_SO, LEVEL_COUNTRY } from "common/constants";
import DistributionByRegionAndType from "./DistributionByRegionAndType";
import DistributionByContractType from "./DistributionByContractType";
import ContractDistributionByRegion from "./ContractDistributionByRegion";

const COUNTRY_CODE = process.env.REACT_APP_COUNTRY_CODE;

const Dashboard = () => {
  document.title = "Dashboard | CIMS";

  const [rightColumn, setRightColumn] = useState<boolean>(true);
  const toggleRightColumn = () => {
    setRightColumn(!rightColumn);
  };

  const dispatch: any = useDispatch();
  useEffect(() => {
    dispatch(getContractsSummary({ level: LEVEL_COUNTRY }));
  }, [dispatch]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <HeaderSection rightClickBtn={toggleRightColumn} />
                <Row>
                  <SummaryWidgets />
                </Row>
                <Row>
                  <Col xl={8}>
                    <ContractDistribution />
                  </Col>
                  <Col xl={4}>
                    {(COUNTRY_CODE === COUNTRY_CODE_SO && (
                      <ContractDistributionMap />
                    )) || <ContractDistributionByRegion />}
                  </Col>

                  <DistributionByContractType />

                  <Col xl={8}>
                    <DistributionByRegionAndType />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
