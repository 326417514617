export const determineEntityType = (_meilisearch_id: string) => {
  if (_meilisearch_id.startsWith("contract-")) {
    return "contract";
  } else if (_meilisearch_id.startsWith("contractor-")) {
    return "contractor";
  } else if (_meilisearch_id.startsWith("person-")) {
    return "person";
  } else if (_meilisearch_id.startsWith("risk-assessment-")) {
    return "risk-assessment";
  } else if (_meilisearch_id.startsWith("capacity-assessment-")) {
    return "capacity-assessment";
  } else if (_meilisearch_id.startsWith("partner-verification-report-")) {
    return "partner-verification-report";
  } else if (_meilisearch_id.startsWith("eu-sanctions-list-")) {
    return "eu-sanctions-list";
  } else if (_meilisearch_id.startsWith("us-sanctions-list-")) {
    return "us-sanctions-list";
  } else if (_meilisearch_id.startsWith("uk-sanctions-list-")) {
    return "uk-sanctions-list";
  } else if (_meilisearch_id.startsWith("un-designated-list-")) {
    return "un-designated-list";
  } else if (_meilisearch_id.startsWith("ungm-list-")) {
    return "ungm-list";
  } else if (_meilisearch_id.startsWith("world-bank-banned-list-")) {
    return "world-bank-banned-list";
  } else {
    return "unknown";
  }
};
