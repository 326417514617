import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { FormFeedback, FormGroup, Label } from "reactstrap";
import { getRoles } from "common/services/users.service";
import Select from "react-select";

const UserRole = ({ validation }: { validation: any }) => {
  const dispatch: any = useDispatch();

  const [roles, setRoles] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response: any = await getRoles({
          pagination: {
            page: 1,
            pageSize: 10000,
          },
        });

        setRoles(response.roles || []);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);

  const roleOptions = useMemo(() => {
    return roles.map((el: any) => {
      return {
        value: el?.id,
        label: el?.name + " - " + el?.description,
      };
    });
  }, [roles]);

  // Custom styles for react-select
  const customSelectStyles = {
    control: (styles: any) => ({
      ...styles,
      borderColor:
        validation.touched.role && validation.errors.role ? "red" : "#ced4da",
      "&:hover": {
        borderColor:
          validation.touched.role && validation.errors.role ? "red" : "#ced4da",
      },
    }),
  };

  return (
    <>
      <FormGroup>
        <Label htmlFor="role-field" className="form-label">
          Role
        </Label>
        <Select
          name="role"
          id="role"
          value={validation.values.role.value ? validation.values.role : null}
          placeholder="Select role"
          onChange={(value: any) => {
            validation.setFieldValue("role", value);
            // Reset the error when a value is selected
            validation.setFieldError("role", "");
          }}
          options={roleOptions}
          onBlur={() => validation.setFieldTouched("role", true)}
          className={
            validation.touched.role && validation.errors.role
              ? "is-invalid"
              : ""
          }
          styles={customSelectStyles}
          loading={loading}
        />
        <FormFeedback>{validation.errors.role?.value}</FormFeedback>
      </FormGroup>
    </>
  );
};

export default UserRole;
