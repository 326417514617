import React from "react";
import {
  Label,
  Input,
  FormFeedback,
  Row,
  Col,
  CardBody,
  Card,
  Button,
} from "reactstrap";

interface PhysicalAddressInputProps {
  validation: any; // Replace 'any' with the appropriate type for the 'validation' parameter
}

const PhysicalAddressInput = ({ validation }: PhysicalAddressInputProps) => {
  return (
    <>
      {validation.values.physicalAddress.map(
        (physicalAddress: any, index: number) => {
          return (
            <Card className="bg-light" key={index}>
              <CardBody>
                <Row>
                  <Col md={12} className="mb-3">
                    <Label htmlFor={`address-${index}`} className="form-label">
                      Address
                    </Label>
                    <Input
                      name={`physicalAddress[${index}].address`}
                      id={`address-${index}`}
                      className="form-control"
                      placeholder="Enter physical address"
                      type="textarea"
                      validate={{ required: { value: true } }}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={physicalAddress.address || ""}
                      invalid={
                        validation.touched.physicalAddress?.[index]?.address &&
                        validation.errors.physicalAddress?.[index]?.address
                          ? true
                          : false
                      }
                    />
                    {validation.touched.physicalAddress?.[index]?.address &&
                      validation.errors.physicalAddress?.[index]?.address && (
                        <FormFeedback type="invalid">
                          {validation.errors.physicalAddress?.[index]?.address}
                        </FormFeedback>
                      )}
                  </Col>
                  <Col md={6} className="mb-3">
                    <Label htmlFor={`city-${index}`} className="form-label">
                      City
                    </Label>
                    <Input
                      name={`physicalAddress[${index}].city`}
                      id={`city-${index}`}
                      className="form-control"
                      placeholder="Enter city"
                      type="text"
                      validate={{ required: { value: true }, email: true }}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={physicalAddress.city || ""}
                      invalid={
                        validation.touched.physicalAddress?.[index]?.city &&
                        validation.errors.physicalAddress?.[index]?.city
                          ? true
                          : false
                      }
                    />
                    {validation.touched.physicalAddress?.[index]?.city &&
                      validation.errors.physicalAddress?.[index]?.city && (
                        <FormFeedback>
                          {validation.errors.physicalAddress[index].city}
                        </FormFeedback>
                      )}
                  </Col>
                  <Col md={6} className="mb-3">
                    <Label htmlFor={`country-${index}`} className="form-label">
                      Country
                    </Label>
                    <Input
                      name={`physicalAddress[${index}].country`}
                      id={`country-${index}`}
                      className="form-control"
                      placeholder="Enter country"
                      type="text"
                      validate={{ required: { value: true } }}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={physicalAddress.country || ""}
                      invalid={
                        validation.touched.physicalAddress?.[index]?.country &&
                        validation.errors.physicalAddress?.[index]?.country
                          ? true
                          : false
                      }
                    />
                    {validation.touched.physicalAddress?.[index]?.country &&
                      validation.errors.physicalAddress?.[index]?.country && (
                        <FormFeedback>
                          {validation.errors.physicalAddress[index].country}
                        </FormFeedback>
                      )}
                  </Col>

                  <div className="mb-0 d-flex justify-content-end">
                    <Button
                      className="btn btn-sm btn-soft-danger waves-effect waves-light material-shadow-none"
                      onClick={() => {
                        validation.setFieldValue(
                          "physicalAddress",
                          validation.values.physicalAddress.filter(
                            (_: any, physicalAddressIndex: number) =>
                              physicalAddressIndex !== index
                          )
                        );
                      }}
                    >
                      {/* <i className="ri-delete-bin-fill me-2"></i> */}
                      Remove
                    </Button>
                  </div>
                </Row>
              </CardBody>
            </Card>
          );
        }
      )}

      <button
        className="btn btn-soft-secondary  waves-effect waves-light material-shadow-none"
        disabled={validation.values.physicalAddress.length === 3}
        onClick={(e) => {
          e.preventDefault();
          if (validation.values.physicalAddress.length < 3) {
            const newPhysicalAddress = {
              address: "",
              city: "",
              country: "",
            };
            validation.setFieldValue("physicalAddress", [
              ...validation.values.physicalAddress,
              newPhysicalAddress,
            ]);
          }
        }}
      >
        Add
      </button>
    </>
  );
};

export default PhysicalAddressInput;
