import React, { useState } from "react";
import { Modal, ModalHeader } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { updatePerson } from "common/services/persons.service";
import { toast } from "react-toastify";
import AliasNamesForm from "./AliasNamesForm";

const UpdateAliasNames: React.FC<{
  toggle: () => void;
  isModalOpen: boolean;
  fetchData: () => void;
  person: any;
}> = ({ toggle, isModalOpen, person, fetchData }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const aliasNames = person?.alias || [];

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      alias: (aliasNames.length > 0 &&
        aliasNames.map((item: any) => item.name || "")) || [""],
    },
    validationSchema: Yup.object({
      alias: Yup.array().of(Yup.string().required("Required")),
    }),
    onSubmit: (values: any) => {
      const data = {
        id: person.id,
        data: {
          alias:
            Array.isArray(values["alias"]) && values["alias"].length > 0
              ? values["alias"].map((el) => {
                  return {
                    name: el,
                  };
                })
              : [],
        },
      };

      setLoading(true);
      const saveData = async () => {
        try {
          await updatePerson(data);
          validation.resetForm();
          toggle();
          toast.success("Alias names updated successfully", {
            autoClose: 1000,
          });
        } catch (err: any) {
          toast.error("Error while updating alias names", {
            autoClose: 1000,
          });
        } finally {
          setLoading(false);
          fetchData();
        }
      };
      saveData();
    },
  });

  return (
    <Modal
      id="showModal"
      size="xl"
      isOpen={isModalOpen}
      toggle={() => {
        toggle();
        validation.resetForm();
      }}
      centered
    >
      <ModalHeader className="bg-light p-3" toggle={toggle}>
        Update Alias Names
        <small
          color="light"
          className="badge bg-primary-subtle align-bottom ms-1 text-primary"
        >
          {validation?.values?.alias?.length || 0}
        </small>
      </ModalHeader>

      <AliasNamesForm
        toggle={toggle}
        validation={validation}
        isUpdate
        loading={loading}
        setLoading={setLoading}
      />
    </Modal>
  );
};

export default UpdateAliasNames;
