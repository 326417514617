import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Collapse,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import * as moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import UpdateAffiliationsToPersons from "./UpdateAffiliationsToPersons";
import { getContractor as onGetContractor } from "slices/thunks";
import Permission from "Components/Common/Permission";

const AffiliationsToPerson = ({ contractor }: { contractor: any }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const showUpdateModal = () => {
    setIsUpdateModalOpen(true);
  };

  const personAffiliations = contractor?.personAffiliations || [];

  return (
    <Card>
      <CardHeader
        className={`d-flex align-items-center ${
          isOpen ? "border-bottom-dashed" : ""
        }`}
        onClick={toggleCollapse}
        style={{ cursor: "pointer" }}
      >
        <div className="text-muted flex-grow-1 d-flex align-items-center">
          <h6 className="mb-0 fw-semibold text-uppercase">
            Affiliations to Person
            <small
              color="light"
              className="badge bg-primary-subtle align-bottom ms-1 text-primary"
            >
              {personAffiliations.length}
            </small>
          </h6>
        </div>
        <div className="flex-shrink-0 d-flex align-items-center">
          <Permission
            resource={"contractor"}
            action={"updateAffiliationsToPersons"}
          >
            <Link
              to="#"
              onClick={(e) => {
                e.stopPropagation();
                showUpdateModal();
              }}
              className="link-primary link-offset-2 text-decoration-underline link-underline-opacity-25 link-underline-opacity-100-hover me-3"
            >
              <i className="ri-pencil-line"></i> Edit
            </Link>
          </Permission>
          {/* {isOpen ? (
            <i className="ri-arrow-up-s-line fs-20 text-muted"></i>
          ) : (
            <i className="ri-arrow-down-s-line fs-20 text-muted" />
          )} */}
        </div>
      </CardHeader>
      <Collapse isOpen={isOpen}>
        <CardBody className="card-body">
          <ListGroup flush>
            {personAffiliations.map((affiliation: any, index: number) => (
              <ListGroupItem
                tag="a"
                to="#"
                className="list-group-item-action"
                onClick={() => {
                  navigate(`/persons/${affiliation?.person?.id}`);
                }}
                key={index}
                style={{ cursor: "pointer" }}
              >
                <div className="float-end">
                  <div>
                    <i className="ri-time-line align-bottom me-1"></i>{" "}
                    {moment(new Date(affiliation?.updatedAt)).format(
                      "DD MMM, Y"
                    )}
                  </div>
                </div>
                <div className="d-flex mb-2 align-items-center">
                  <div className="flex-shrink-0">
                    <div className="avatar-sm  rounded-circle">
                      <div className="avatar-title bg-primary-subtle text-primary rounded-circle fs-24">
                        <i className="ri-user-line text-primary"></i>
                      </div>
                    </div>
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h5 className="list-title fs-15 mb-1">
                      {`${affiliation.person?.fullName || ""}`}
                    </h5>
                    <p className="list-text mb-0 fs-12">
                      <span className="me-1">Nature of Relationship:</span>
                      {affiliation?.natureOfRelationship ||
                        "Relationship not defined"}
                    </p>
                  </div>
                </div>
              </ListGroupItem>
            ))}
          </ListGroup>
        </CardBody>
      </Collapse>
      <UpdateAffiliationsToPersons
        isModalOpen={isUpdateModalOpen}
        toggle={() => setIsUpdateModalOpen(!isUpdateModalOpen)}
        data={contractor?.personAffiliations || []}
        fetchData={() => {
          dispatch(
            onGetContractor({
              id: contractor?.id,
            })
          );
        }}
        contractorId={contractor?.id}
      />
    </Card>
  );
};

export default AffiliationsToPerson;
