import { ICON_CALENDAR } from "common/constants";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import * as moment from "moment";

const SanctionItem = ({
  data,
  index,
  entityType,
}: {
  data: any;
  index: number;
  entityType: any;
}) => {
  let details = [];
  let link = "#";
  if (entityType === "eu-sanctions-list") {
    link = `/sanctions-list/eu/${data?.id}`;
    data?.firstName && details.push(`First Name: ${data.firstName}`);
    data?.middleName && details.push(`Middle Name: ${data.middleName}`);
    data?.lastName && details.push(`Middle Name: ${data.lastName}`);
    data?.programme && details.push(`Programme: ${data.programme}`);
    data?.reference && details.push(`Reference: ${data.reference}`);
    data?.aliasNames && details.push(`Alias Names: ${data.aliasNames}`);
    data?.gender && details.push(`Gender: ${data?.gender}`);
  } else if (entityType === "us-sanctions-list") {
    link = `/sanctions-list/us/${data?.id}`;
    data?.firstName && details.push(`First Name: ${data.firstName}`);
    data?.lastName && details.push(`Middle Name: ${data.lastName}`);
    data?.aliasNames && details.push(`Alias Names: ${data.aliasNames}`);
    data?.program && details.push(`Program: ${data.program}`);
    data?.nationality && details.push(`Nationality: ${data?.nationality}`);
    data?.reference && details.push(`Reference: ${data.reference}`);
    data?.designateType &&
      details.push(`Designate Type: ${data.designateType}`);
    data?.citizenship && details.push(`Citizenship: ${data.citizenship}`);
  } else if (entityType === "uk-sanctions-list") {
    link = `/sanctions-list/uk/${data?.id}`;
    data?.fullName && details.push(`Full Name: ${data.fullName}`);
    data?.aliasNames && details.push(`Alias Names: ${data.aliasNames}`);
    data?.uniqueID && details.push(`Unique ID: ${data.uniqueID}`);
    data?.unReferenceNumber &&
      details.push(`UN Reference Number: ${data.unReferenceNumber}`);
    data?.regime && details.push(`Regime: ${data.regime}`);
    data?.country && details.push(`Country: ${data.country}`);
    data?.gender && details.push(`Gender: ${data?.gender}`);
    data?.nationality && details.push(`Nationality: ${data.nationality}`);
  } else if (entityType === "un-designated-list") {
    link = `/sanctions-list/un-designated/${data?.id}`;
    data?.title && details.push(`Full Name: ${data.title}`);
    data?.reference && details.push(`Reference: ${data.reference}`);
    data?.aliasNames && details.push(`Alias Names: ${data.aliasNames}`);
    data?.citizenship && details.push(`Citizenship: ${data.citizenship}`);
    data?.designation && details.push(`Designation: ${data.designation}`);
    data?.dateOfBirth && details.push(`Date of Birth: ${data.dateOfBirth}`);
    data?.placeOfBirth && details.push(`Place of Birth: ${data.placeOfBirth}`);
    data?.address && details.push(`Address: ${data.address}`);
  } else if (entityType === "world-bank-banned-list") {
    link = `/sanctions-list/world-bank/${data?.id}`;
    data?.companyName && details.push(`Company Name: ${data.companyName}`);
    data?.country && details.push(`Country: ${data.country}`);
    data?.contactName && details.push(`Contact Name: ${data.contactName}`);
    data?.contactEmail && details.push(`Contact Email: ${data.contactEmail}`);
    data?.sanctionStartDate &&
      details.push(`Sanction Start Date: ${data.sanctionStartDate}`);
    data?.sanctionEndDate &&
      details.push(`Sanction End Date: ${data.sanctionEndDate}`);
  } else if (entityType === "ungm-list") {
    link = `/sanctions-list/ungm/${data?.id}`;
    data?.companyName && details.push(`Company Name: ${data.companyName}`);
    data?.country && details.push(`Country: ${data.country}`);
    data?.contactName && details.push(`Contact Name: ${data.contactName}`);
    data?.contactEmail && details.push(`Contact Email: ${data.contactEmail}`);
    data?.sanctionStartDate &&
      details.push(`Sanction Start Date: ${data.sanctionStartDate}`);
    data?.sanctionEndDate &&
      details.push(`Sanction End Date: ${data.sanctionEndDate}`);
  }

  const detailsString = useMemo(() => details.join(", "), [details]);
  return (
    <div className={index === 0 ? "pb-3" : "py-3"}>
      <h5 className="mb-1">
        <Link to={link}>{data?.title || data?.companyName}</Link>
      </h5>
      <p className="text-success mb-2">
        <span className="me-2">
          {entityType === "eu-sanctions-list" && "EU Sanctions List"}
          {entityType === "us-sanctions-list" && "US Sanctions List"}
          {entityType === "uk-sanctions-list" && "UK Sanctions List"}
          {entityType === "un-designated-list" && "UN Designated List"}
          {entityType === "ungm-list" && "UNGM List"}
          {entityType === "world-bank-banned-list" && "World Bank Banned List"}
        </span>
        {/* <span className="badge bg-warning-subtle text-warning fs-11 me-2">
          SEMG Mention
        </span> */}
        {entityType === "eu-sanctions-list" && (
          <span className="badge bg-danger-subtle text-danger fs-11 me-2">
            EU Sanction
          </span>
        )}
        {entityType === "us-sanctions-list" && (
          <span className="badge bg-danger-subtle text-danger fs-11 me-2">
            US Sanction
          </span>
        )}
        {entityType === "uk-sanctions-list" && (
          <span className="badge bg-danger-subtle text-danger fs-11 me-2">
            UK Sanction
          </span>
        )}
        {entityType === "un-designated-list" && (
          <span className="badge bg-danger-subtle text-danger fs-11 me-2">
            UN Designated
          </span>
        )}
        {entityType === "world-bank-banned-list" && (
          <span className="badge bg-danger-subtle text-danger fs-11 me-2">
            World Bank Banned List
          </span>
        )}
        {entityType === "ungm-list" && (
          <span className="badge bg-danger-subtle text-danger fs-11 me-2">
            UNGM Debarred List
          </span>
        )}
      </p>
      <p className="text-muted mb-2">{`... ${detailsString} ...`}</p>
      <ul className="list-inline d-flex align-items-center g-3 text-muted fs-14 mb-0">
        <li className="list-inline-item">
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0">
              <i className={ICON_CALENDAR}></i>
            </div>
            <div className="flex-grow-1 fs-13 ms-1">
              <span className="fw-medium">
                {moment(new Date(data?.updatedAt)).format("DD MMM  Y")}
              </span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default SanctionItem;
