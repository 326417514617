import React, { useCallback, useMemo, useState } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { isContractActive } from "common/lib/contractOptions";
import { isAllowed } from "common/lib/permissionUtils";
import { getSortObj } from "common/lib/tableDataOptions";
import TableContainer from "Components/Common/TableContainer";
import { useProfile } from "Components/Hooks/UserHooks";
import moment from "moment";
import AgencyFilter from "pages/Contracts/AgencyFilter";
import ContractTypeFilter from "pages/Contracts/ContractTypeFilter";
import { normalize } from "common/lib/normalize";
import { toast } from "react-toastify";
import { getContracts } from "common/services/contracts.service";
import Flatpickr from "react-flatpickr";

interface DateRange {
  fromDate: Date | null;
  toDate: Date | null;
}

interface FilterValue {
  contractType: string;
  agency: string;
  dateRange: DateRange;
}

// @ts-ignore
const TextWrapper: React.FC<{ value: string }> = ({ value }) => {
  if (!value) return null;
  // const matches = value.match(/.{1,40}/g);
  // const wrappedText = matches ? matches.join("\n") : ""; // Handle null case
  return <div style={{ whiteSpace: "pre-wrap", width: "220px" }}>{value}</div>;
};

const Contractstab = () => {
  const { id: contractorId } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();

  const [params] = useState({
    page: Number(searchParams.get("page")) || 1, // Starts from 1
    pageSize: Number(searchParams.get("pageSize")) || 20, // Default page size
  });

  const [pagination, setPagination] = useState({
    pageIndex: params.page - 1, // Convert to zero-based index
    pageSize: params.pageSize,
  });

  // Update pageIndex
  const setPage = (newPage: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      pageIndex: newPage - 1,
    }));
  };

  const [sorted, setSorted] = useState(() => {
    const sortParam = searchParams.get("sort");
    return sortParam
      ? JSON.parse(sortParam)
      : [{ id: "createdAt", desc: true }];
  });

  const [searchValue, setSearchValue] = useState(
    searchParams.get("search") || ""
  );
  const defaultFilterValue: FilterValue = {
    contractType: "",
    agency: "",
    dateRange: {
      fromDate: null,
      toDate: null,
    },
  };
  const [filterValue, setFilterValue] = useState(() => {
    const filterParam = searchParams.get("filter");
    return filterParam ? JSON.parse(filterParam) : defaultFilterValue;
  });

  const [apiParams, setApiParams] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState<any>({});
  const onPageChange = useCallback(
    async ({
      page,
      sorted,
      searchValue,
      filterValue,
    }: {
      page: number;
      sorted: Array<{ id: string; desc: boolean }>;
      searchValue: string;
      filterValue?: any;
    }) => {
      const params = new URLSearchParams(searchParams);
      params.set("page", String(page));
      params.set("sort", JSON.stringify(sorted));
      params.set("search", searchValue);
      params.set("filter", JSON.stringify(filterValue));
      setSearchParams(params, { replace: true });

      const sortObj = getSortObj(sorted);

      let andConditions = [];

      andConditions.push({
        contractor: {
          id: {
            $eq: contractorId,
          },
        },
      });

      if (filterValue?.agency) {
        andConditions.push({
          agency: {
            id: {
              $eq: filterValue.agency,
            },
          },
        });
      }
      if (filterValue?.contractType) {
        andConditions.push({
          contractType: {
            id: {
              $eq: filterValue.contractType,
            },
          },
        });
      }
      if (filterValue?.dateRange?.fromDate && filterValue?.dateRange?.toDate) {
        andConditions.push({
          $or: [
            {
              startDate: {
                $between: [
                  filterValue.dateRange.fromDate,
                  filterValue.dateRange.toDate,
                ],
              },
            },
            {
              endDate: {
                $between: [
                  filterValue.dateRange.fromDate,
                  filterValue.dateRange.toDate,
                ],
              },
            },
          ],
        });
      }

      andConditions.push({
        $or: [
          {
            contractTitle: {
              $containsi: searchValue,
            },
          },
          {
            contractor: {
              contractorName: {
                $containsi: searchValue,
              },
            },
          },
        ],
      });

      console.log("On Page Change Called");

      try {
        setLoading(true);
        const apiParams = {
          pagination: {
            page,
            pageSize: pagination.pageSize,
          },
          sort: sortObj,
          populate: [
            "agency",
            "contractType",
            "contractRating",
            "subContractedTo",
            "contractor",
            "location",
          ],
          filters: {
            $and: andConditions,
          },
        };
        setApiParams(apiParams);
        const response: any = await getContracts(apiParams);
        setData(normalize(response.data));
        setMeta(response?.meta);
      } catch (e: any) {
        toast.error("Error while fetching data", {});
      } finally {
        setLoading(false);
      }
    },
    [contractorId, pagination.pageSize, searchParams, setSearchParams]
  );

  const { userProfile } = useProfile();
  const [columnVisibility] = useState({
    checkBoxColumn: isAllowed(userProfile?.permissions, [
      {
        resource: "contract",
        action: "delete",
      },
    ]),
    updatedAt: isAllowed(userProfile?.permissions, [
      {
        resource: "contract",
        action: "update",
      },
    ]),
  });

  const columns = useMemo(
    () => [
      {
        header: "Contract Title",
        accessorKey: "contractTitle",
        id: "contractTitle",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <div style={{ whiteSpace: "pre-wrap", width: "300px" }}>
              <Link
                to={`/contracts/${cell.row.original?.id}`}
                className="fw-medium link-primary"
              >
                {cell.getValue()}
              </Link>
            </div>
          );
        },
      },

      {
        header: "Contractor Name",
        accessorKey: "contractor.contractorName",
        enableColumnFilter: false,
        id: "contractor.contractorName",
        cell: (cell: any) => (
          <TextWrapper value={cell.getValue() || "Not Provided"} />
        ),
      },
      {
        header: "UN Agency",
        accessorKey: "agency.name",
        id: "agency.name",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return <>{cell.getValue() || "Not Provided"}</>;
        },
      },
      {
        header: "Status",
        accessorKey: "status",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cell: any) => {
          const status = isContractActive(
            cell.row.original.startDate || "",
            cell.row.original.endDate || ""
          );
          return (
            <React.Fragment>
              {status === null ? (
                <span className="badge bg-warning-subtle text-warning fs-11">
                  N/A
                </span>
              ) : status ? (
                <span className="badge bg-success-subtle text-success fs-11">
                  Active
                </span>
              ) : status === false ? (
                <span className="badge bg-danger-subtle text-danger fs-11">
                  Not Active
                </span>
              ) : null}
            </React.Fragment>
          );
        },
      },
      {
        header: "Amount",
        accessorKey: "contractValue",
        enableColumnFilter: false,
        id: "contractValue",
        cell: (cell: any) => {
          return (
            <>
              {cell.getValue()
                ? `$ ${cell.getValue().toLocaleString()}`
                : "Not Provided"}
            </>
          );
        },
      },

      {
        header: "Contract Type",
        accessorKey: "contractType.name",
        enableColumnFilter: false,
        id: "contractType.name",
        cell: (cell: any) => {
          return <>{cell.getValue() || "Not Provided"}</>;
        },
      },

      {
        header: "Start Date",
        accessorKey: "startDate",
        id: "startDate",
        enableColumnFilter: false,
        cell: (cell: any) => {
          if (cell.getValue() === null) return "Not Provided";
          const date = moment(new Date(cell?.getValue())).format("DD MMM Y");
          return <>{date}</>;
        },
      },

      {
        header: "End Date",
        accessorKey: "endDate",
        id: "endDate",
        enableColumnFilter: false,
        cell: (cell: any) => {
          if (cell.getValue() === null) return "Not Provided";
          const date = moment(new Date(cell?.getValue())).format("DD MMM Y");
          return <>{date}</>;
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <Row className="g-3">
            <Col xxl={3} lg={6}>
              <h6 className="text-uppercase fs-12 mb-2">Search</h6>
              <div className="search-box">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search contracts..."
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                    setPage(1);
                  }}
                  value={searchValue}
                />
                <i className="ri-search-line search-icon"></i>
              </div>
            </Col>
            <Col xxl={2} lg={6}>
              <h6 className="text-uppercase fs-12 mb-2">Contract Type</h6>
              <ContractTypeFilter
                onChange={(value) => {
                  setFilterValue({ ...filterValue, contractType: value });
                  setPage(1);
                }}
                value={filterValue.contractType}
              />
            </Col>
            <Col xxl={3} lg={6}>
              <h6 className="text-uppercase fs-12 mb-2">Select Status</h6>
              {/* @ts-ignore */}
              <Flatpickr
                className="form-control"
                placeholder="Select Date Range"
                options={{
                  mode: "range",
                  altFormat: "d M, Y",
                  dateFormat: "d M, Y",
                }}
                onChange={(e) => {
                  if (e[0] && e[1]) {
                    setFilterValue({
                      ...filterValue,
                      dateRange: {
                        fromDate: e[0],
                        toDate: e[1],
                      },
                    });
                    setPage(1);
                  }
                }}
                value={[
                  filterValue?.dateRange?.fromDate || "",
                  filterValue?.dateRange?.toDate || "",
                ]}
              />
            </Col>
            <Col xxl={2} lg={6}>
              <h6 className="text-uppercase fs-12 mb-2">Select Agency</h6>
              <AgencyFilter
                onChange={(value) => {
                  setFilterValue({ ...filterValue, agency: value });
                  setPage(1);
                }}
                value={filterValue.agency}
              />
            </Col>
            <Col xxl={2} lg={4}>
              <h6 className="text-uppercase fs-12 mb-xxl-4"> {` `}</h6>
              <button
                onClick={() => {
                  setFilterValue(defaultFilterValue);
                  setSearchValue("");
                  setPage(1);
                  setSorted([{ id: "createdAt", desc: true }]);
                }}
                className="btn btn-outline-primary material-shadow-none w-100"
              >
                <i className="ri-equalizer-line align-bottom me-1"></i> Reset
                Filters
              </button>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card>
        <CardHeader>
          <Row className="align-items-center g-3">
            <Col md={8}>
              <h5 className="card-title mb-1">Contracts</h5>
              <p className="text-muted mb-0">
                This is a detailed list of contracts awarded to contractors,
                vendors, and implementing partners by various UN agencies.
              </p>
            </Col>
            <div className="col-md-auto ms-auto">
              <div className="d-flex gap-2"></div>
            </div>
          </Row>
        </CardHeader>
        <CardBody>
          <TableContainer
            loading={loading}
            columns={columns}
            data={data || []}
            pagination={pagination}
            setPagination={setPagination}
            sorted={sorted}
            setSorted={setSorted}
            onPageChange={onPageChange}
            FilterSection={() => {}}
            totalRows={meta?.pagination?.total || 0}
            totalPages={meta?.pagination?.pageCount || 0}
            searchValue={searchValue}
            filterValue={filterValue}
            columnVisibility={columnVisibility}
            divClass="table-responsive table-card mb-4"
            tableClass="align-middle table-nowrap mb-0 table-striped"
            theadClass="table-light table-nowrap"
          />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default Contractstab;
