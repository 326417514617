import React from "react";
import {
  Label,
  Input,
  FormFeedback,
  Row,
  Col,
  CardBody,
  Card,
  Button,
} from "reactstrap";
import Flatpickr from "react-flatpickr";

interface ContractorRegistrationProps {
  validation: any; // Replace 'any' with the appropriate type for the 'validation' parameter
}

const ContractorRegistration = ({
  validation,
}: ContractorRegistrationProps) => {
  return (
    <>
      {validation.values.contractorRegistration.map(
        (contractor: any, index: number) => {
          return (
            <Card className="bg-light" key={index}>
              <CardBody>
                <Row>
                  <Col md={6} className="mb-3">
                    <Label
                      htmlFor={`registrationName-${index}`}
                      className="form-label"
                    >
                      Registration Name
                    </Label>
                    <Input
                      name={`contractorRegistration[${index}].registrationName`}
                      id={`registrationName-${index}`}
                      className="form-control"
                      placeholder="Enter registration name"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={contractor.registrationName}
                      invalid={
                        validation.touched.contractorRegistration?.[index]
                          ?.registrationName &&
                        !!validation.errors.contractorRegistration?.[index]
                          ?.registrationName
                      }
                    />
                    {validation.touched.contractorRegistration?.[index]
                      ?.registrationName &&
                    validation.errors.contractorRegistration?.[index]
                      ?.registrationName ? (
                      <FormFeedback type="invalid">
                        {
                          validation.errors.contractorRegistration[index]
                            .registrationName
                        }
                      </FormFeedback>
                    ) : null}
                  </Col>
                  <Col md={6} className="mb-3">
                    <Label
                      htmlFor={`registrationType-${index}`}
                      className="form-label"
                    >
                      Registration Type
                    </Label>
                    <Input
                      name={`contractorRegistration[${index}].registrationType`}
                      id={`registrationType-${index}`}
                      className="form-control"
                      placeholder="Enter registration type"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={contractor.registrationType}
                      invalid={
                        validation.touched.contractorRegistration?.[index]
                          ?.registrationType &&
                        !!validation.errors.contractorRegistration?.[index]
                          ?.registrationType
                      }
                    />
                    {validation.touched.contractorRegistration?.[index]
                      ?.registrationType &&
                      validation.errors.contractorRegistration?.[index]
                        ?.registrationType && (
                        <FormFeedback>
                          {
                            validation.errors.contractorRegistration[index]
                              .registrationType
                          }
                        </FormFeedback>
                      )}
                  </Col>
                  <Col md={6} className="mb-3">
                    <Label
                      htmlFor={`registrationNumber-${index}`}
                      className="form-label"
                    >
                      Registration Number
                    </Label>
                    <Input
                      name={`contractorRegistration[${index}].registrationNumber`}
                      id={`registrationNumber-${index}`}
                      className="form-control"
                      placeholder="Enter registration number"
                      type="text"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={contractor.registrationNumber}
                      invalid={
                        validation.touched.contractorRegistration?.[index]
                          ?.registrationNumber &&
                        !!validation.errors.contractorRegistration?.[index]
                          ?.registrationNumber
                      }
                    />
                    {validation.touched.contractorRegistration?.[index]
                      ?.registrationNumber &&
                      validation.errors.contractorRegistration?.[index]
                        ?.registrationNumber && (
                        <FormFeedback>
                          {
                            validation.errors.contractorRegistration[index]
                              .registrationNumber
                          }
                        </FormFeedback>
                      )}
                  </Col>
                  <Col md={6} className="mb-3">
                    <Label
                      htmlFor={`lengthOfValidity-${index}`}
                      className="form-label"
                    >
                      Length of Validity
                    </Label>
                    <Input
                      name={`contractorRegistration[${index}].lengthOfValidity`}
                      id={`lengthOfValidity-${index}`}
                      type="text"
                      placeholder="Length of Validity"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={contractor.lengthOfValidity}
                      invalid={
                        validation.touched.contractorRegistration?.[index]
                          ?.lengthOfValidity &&
                        !!validation.errors.contractorRegistration?.[index]
                          ?.lengthOfValidity
                      }
                    />
                    {validation.touched.contractorRegistration?.[index]
                      ?.lengthOfValidity &&
                      validation.errors.contractorRegistration?.[index]
                        ?.lengthOfValidity && (
                        <FormFeedback>
                          {
                            validation.errors.contractorRegistration[index]
                              .lengthOfValidity
                          }
                        </FormFeedback>
                      )}
                  </Col>
                  <Col md={6} className="mb-3">
                    <Label
                      htmlFor={`countryOfRegistration-${index}`}
                      className="form-label"
                    >
                      Country of Registration
                    </Label>
                    <Input
                      name={`contractorRegistration[${index}].countryOfRegistration`}
                      id={`countryOfRegistration-${index}`}
                      type="text"
                      placeholder="Country of Registration"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={contractor.countryOfRegistration}
                      invalid={
                        validation.touched.contractorRegistration?.[index]
                          ?.countryOfRegistration &&
                        !!validation.errors.contractorRegistration?.[index]
                          ?.countryOfRegistration
                      }
                    />
                    {validation.touched.contractorRegistration?.[index]
                      ?.countryOfRegistration &&
                      validation.errors.contractorRegistration?.[index]
                        ?.countryOfRegistration && (
                        <FormFeedback>
                          {
                            validation.errors.contractorRegistration[index]
                              .countryOfRegistration
                          }
                        </FormFeedback>
                      )}
                  </Col>
                  <Col md={6} className="mb-3">
                    <Label
                      htmlFor={`registrationDate-${index}`}
                      className="form-label"
                    >
                      Registration Date
                    </Label>
                    {/* @ts-ignore */}
                    <Flatpickr
                      name={`contractorRegistration[${index}].registrationDate`}
                      id={`registrationDate-${index}`}
                      className="form-control"
                      placeholder="Select end date"
                      options={{
                        enableTime: false,
                        altInput: false,
                        altFormat: "d M, Y",
                        dateFormat: "d M, Y",
                      }}
                      onChange={(e) => {
                        validation.setFieldValue(
                          `contractorRegistration[${index}].registrationDate`,
                          e[0]
                        );
                      }}
                      value={
                        validation.values?.contractorRegistration[index]
                          ?.registrationDate || ""
                      }
                    />

                    {validation.touched?.contractorRegistration &&
                    validation.touched?.contractorRegistration[index]
                      ?.registrationDate &&
                    validation.errors?.contractorRegistration &&
                    validation.errors?.contractorRegistration[index]
                      ?.registrationDate ? (
                      <FormFeedback type="invalid">
                        {
                          validation.errors?.contractorRegistration[index]
                            ?.registrationDate
                        }
                      </FormFeedback>
                    ) : null}

                    {/* <Input
                  
        
                
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={contractor.registrationDate}
                      invalid={
                        validation.touched.contractorRegistration?.[index]
                          ?.registrationDate &&
                        !!validation.errors.contractorRegistration?.[index]
                          ?.registrationDate
                      }
                    />
                    {validation.touched.contractorRegistration?.[index]
                      ?.registrationDate &&
                      validation.errors.contractorRegistration?.[index]
                        ?.registrationDate && (
                        <FormFeedback>
                          {
                            validation.errors.contractorRegistration[index]
                              .registrationDate
                          }
                        </FormFeedback>
                      )} */}
                  </Col>
                  <div className="mb-0 d-flex justify-content-end">
                    <Button
                      className="btn btn-sm btn-soft-danger waves-effect waves-light material-shadow-none"
                      onClick={() => {
                        validation.setFieldValue(
                          "contractorRegistration",
                          validation.values.contractorRegistration.filter(
                            (_: any, contactractorRegistrationIndex: number) =>
                              contactractorRegistrationIndex !== index
                          )
                        );
                      }}
                    >
                      {/* <i className="ri-delete-bin-fill me-2"></i> */}
                      Remove
                    </Button>
                  </div>
                </Row>
              </CardBody>
            </Card>
          );
        }
      )}
      <button
        className="btn btn-soft-secondary waves-effect waves-light material-shadow-none"
        disabled={validation.values.contractorRegistration.length === 3}
        onClick={(e) => {
          e.preventDefault();
          if (validation.values.contractorRegistration.length < 3) {
            const newContractorRegistration = {
              registrationName: "",
              registrationType: "",
              registrationNumber: "",
              lengthOfValidity: "",
              countryOfRegistration: "",
              registrationDate: "",
            };

            const contractorRegistrations =
              validation.values.contractorRegistration;
            validation.setFieldValue("contractorRegistration", [
              ...contractorRegistrations,
              newContractorRegistration,
            ]);
          }
        }}
      >
        Add
      </button>
    </>
  );
};

export default ContractorRegistration;
