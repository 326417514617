import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, Col } from "reactstrap";
import { getContractors } from "common/services/contractors.service";
import { getSortObj } from "common/lib/tableDataOptions";
import { normalize } from "common/lib/normalize";
import moment from "moment";
import { Debarred, SEMGMention } from "pages/Contractors/ContractorsCol";
import Skeleton from "react-loading-skeleton";

const TableSkeleton = ({ rows = 5 }) => {
  return (
    <tbody>
      {Array.from({ length: rows }).map((_, key) => (
        <tr key={key}>
          <td>
            <div style={{ whiteSpace: "pre-wrap", width: "300px" }}>
              <Skeleton width={250} height={20} />
            </div>
          </td>
          <td>
            <div className="d-flex align-items-center">
              <Skeleton width={150} height={20} />
            </div>
          </td>
          <td>
            <Skeleton width={50} height={20} />
          </td>
          <td>
            <Skeleton width={100} height={20} />
          </td>
        </tr>
      ))}
    </tbody>
  );
};

const RecentlyFlagged = () => {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const sortObj = getSortObj([
          {
            id: "performanceRatings.updatedAt",
            desc: true,
          },
          {
            id: "semgMentions.updatedAt",
            desc: true,
          },
        ]);
        const response = await getContractors({
          pagination: {
            page: 1,
            pageSize: 7,
          },
          sort: sortObj,
          fields: ["contractorName", "acronym", "updatedAt"],
          populate: {
            organizationType: "name",
            semgMentions: {
              sort: { updatedAt: "desc" },
            },
            performanceRatings: {
              populate: {
                performanceRatingType: true,
              },
              sort: { updatedAt: "desc" },
            },
          },
          filters: {
            $or: [
              // {
              //   semgMentions: {
              //     id: {
              //       $notNull: true,
              //     },
              //   },
              // },

              {
                performanceRatings: {
                  performanceRatingType: {
                    isFlag: true,
                  },
                },
              },
            ],
          },
        });
        setData(normalize(response.data));
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <Col xl={8} className="">
        <Card className="card-height-100">
          <CardHeader className="align-items-center d-flex">
            <h4 className="card-title mb-0 flex-grow-1">
              Recently Flagged Contractors
            </h4>
            <div className="flex-shrink-0"></div>
          </CardHeader>

          <CardBody>
            <div className="table-responsive table-card">
              <table className="table  table-centered align-middle table-nowrap mb-0 table-striped">
                <thead className="text-muted table-light">
                  <tr>
                    <th scope="col">Contractor Name</th>
                    <th scope="col">Organization Type</th>
                    <th scope="col">Flag Status</th>
                    <th scope="col">Date Flagged</th>
                  </tr>
                </thead>

                {loading ? (
                  <TableSkeleton rows={7} />
                ) : (
                  <tbody>
                    {(data || []).map((item: any, key) => {
                      const statuses = [];
                      let debarred = false;
                      let semgMention = false;
                      if (
                        statuses.length === 0 &&
                        item?.performanceRatings?.some(
                          (rating: any) => rating?.performanceRatingType?.isFlag
                        )
                      ) {
                        debarred = true;
                        statuses.push(<Debarred />);
                      }
                      if (
                        statuses.length === 0 &&
                        item?.semgMentions &&
                        item?.semgMentions?.length > 0
                      ) {
                        semgMention = true;
                        statuses.push(<SEMGMention />);
                        statuses.push(<br />);
                      }

                      if (statuses.length === 0) {
                        statuses.push(
                          <span className="badge bg-primary-subtle text-primary fs-11 me-2">
                            N/A
                          </span>
                        );
                      }
                      return (
                        <tr key={item?.id}>
                          <td>
                            <div
                              style={{ whiteSpace: "pre-wrap", width: "300px" }}
                            >
                              <Link
                                to={`/contractors/${item.id}`}
                                className="fw-medium link-primary"
                              >
                                {item.contractorName}
                              </Link>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex align-items-center">
                              <div className="flex-grow-1">
                                {item?.organizationType?.name}
                              </div>
                            </div>
                          </td>
                          <td>{statuses[0]}</td>
                          <td>
                            {debarred
                              ? moment(
                                  item?.performanceRatings[0]?.updatedAt
                                ).format("DD MMM, YYYY")
                              : semgMention
                              ? moment(item?.semgMentions[0]?.updatedAt).format(
                                  "DD MMM, YYYY"
                                )
                              : "N/A"}
                          </td>
                          {/* <td>{item.contracts}</td> */}
                        </tr>
                      );
                    })}
                  </tbody>
                )}
              </table>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default RecentlyFlagged;
