import React, { useState } from "react";
import { Modal, ModalHeader } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { updateOrganizationType } from "common/services/contractors.service";
import Form from "./Form";
import { toast } from "react-toastify";

const UpdateOrganizationType: React.FC<{
  toggle: () => void;
  isModalOpen: boolean;
  fetchData: () => void;
  selectedRecord: any;
}> = ({ toggle, isModalOpen, fetchData, selectedRecord }) => {
  const [loading, setLoading] = useState<boolean>(false);
  // validation

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: selectedRecord?.name,
      category: {
        value: selectedRecord?.category?.id,
        label: selectedRecord?.category?.name,
      },
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please enter name"),
      category: Yup.object().shape({
        value: Yup.string().nullable(),
        label: Yup.string(),
      }),
    }),
    onSubmit: async (values: any) => {
      const data = {
        id: selectedRecord?.id,
        data: {
          name: values["name"],
          category: values["category"]["value"] || null,
        },
      };

      setLoading(true);
      try {
        await updateOrganizationType(data);
        fetchData();
        validation.resetForm();
        toggle();
        toast.success("Organization type updated successfully", {});
      } catch (e: any) {
        toast.error("Organization type update failed", {});
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <Modal
      id="showModal"
      size="lg"
      isOpen={isModalOpen}
      toggle={toggle}
      centered
    >
      <ModalHeader className="bg-light p-3" toggle={toggle}>
        Update Organization Type
      </ModalHeader>

      <Form
        validation={validation}
        isUpdate
        loading={loading}
        toggle={toggle}
      />
    </Modal>
  );
};

export default UpdateOrganizationType;
