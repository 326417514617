import React, { useState, useMemo, useCallback } from "react";

import {
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Row,
  Card,
  Col,
  CardBody,
  CardHeader,
  UncontrolledButtonDropdown,
} from "reactstrap";

import DeleteModal from "../../../Components/Common/DeleteModal";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import TableContainer from "../../../Components/Common/TableContainer";

import { Link, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import AddPVR from "./AddPVR";
import { getSortObj } from "common/lib/tableDataOptions";
import * as moment from "moment";
import FileIcon from "Components/Common/FileIcon";
import { deletePvr, getPvrs } from "common/services/pvr.service";
import { normalize } from "common/lib/normalize";
import UpdatePVR from "./UpdatePVR";
import Permission from "Components/Common/Permission";
import Flatpickr from "react-flatpickr";
import AgencyFilter from "pages/Contracts/AgencyFilter";
import Permissions from "Components/Common/Permissions";
import Import from "pages/Assessments/PVRs/Import";
import { isAllowed } from "common/lib/permissionUtils";
import { useProfile } from "Components/Hooks/UserHooks";

interface DateRange {
  fromDate: Date | null;
  toDate: Date | null;
}

interface FilterValue {
  dateRange: DateRange;
  agency: any;
}

const PVRs = (props: any) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [params] = useState({
    page: Number(searchParams.get("page")) || 1, // Starts from 1
    pageSize: Number(searchParams.get("pageSize")) || 20, // Default page size
  });

  const [pagination, setPagination] = useState({
    pageIndex: params.page - 1, // Convert to zero-based index
    pageSize: params.pageSize,
  });

  // Update pageIndex
  const setPage = (newPage: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      pageIndex: newPage - 1,
    }));
  };

  // Update pageSize
  // const setPageSize = (newPageSize: number) => {
  //   setPagination((prevPagination) => ({
  //     ...prevPagination,
  //     pageSize: newPageSize,
  //   }));
  // };

  const [sorted, setSorted] = useState(() => {
    const sortParam = searchParams.get("sort");
    return sortParam
      ? JSON.parse(sortParam)
      : [{ id: "createdAt", desc: true }];
  });

  const [selectedRecord, setSelectedRecord] = useState<any>({});

  const [data, setData] = useState([]);
  const [meta, setMeta] = useState<any>({});
  const [loading, setLoading] = useState(false);

  const fetchData = () => {
    onPageChange({
      page: 1,
      sorted: [{ id: "createdAt", desc: true }],
      searchValue: "",
      filterValue: defaultFilterValue,
    });
  };

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const showAddModalForm = () => {
    setIsAddModalOpen(true);
  };

  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const showUpdateModalForm = (record: any) => {
    setSelectedRecord(record);
    setIsUpdateModalOpen(true);
  };

  const [deleteModal, setDeleteModal] = useState(false);

  const defaultFilterValue: FilterValue = {
    agency: "",
    dateRange: {
      fromDate: null,
      toDate: null,
    },
  };
  const [searchValue, setSearchValue] = useState(
    searchParams.get("search") || ""
  );
  const [filterValue, setFilterValue] = useState(() => {
    const filterParam = searchParams.get("filter");
    return filterParam ? JSON.parse(filterParam) : defaultFilterValue;
  });

  const unCheckAll = useCallback(() => {
    const checkall: any = document.getElementById("checkBoxAll");
    if (checkall) checkall.checked = false;
    setSelectedCheckBox([]);
  }, []);

  const [apiParams, setApiParams] = useState<any>({});
  const onPageChange = useCallback(
    async ({
      page,
      sorted,
      searchValue,
      filterValue,
    }: {
      page: number;
      sorted: Array<{ id: string; desc: boolean }>;
      searchValue: string;
      filterValue?: FilterValue;
    }) => {
      const params = new URLSearchParams(searchParams);
      params.set("page", String(page));
      params.set("sort", JSON.stringify(sorted));
      params.set("search", searchValue);
      params.set("filter", JSON.stringify(filterValue));
      setSearchParams(params, { replace: true });

      const sortObj = getSortObj(sorted);

      let andConditions = [];

      if (filterValue?.agency) {
        andConditions.push({
          agency: {
            id: filterValue?.agency,
          },
        });
      }

      if (filterValue?.dateRange?.fromDate && filterValue?.dateRange?.toDate) {
        andConditions.push({
          verificationDate: {
            $gte: filterValue.dateRange.fromDate,
            $lte: filterValue.dateRange.toDate,
          },
        });
      }

      andConditions.push({
        $or: [
          {
            title: {
              $containsi: searchValue,
            },
          },
          {
            contractor: {
              contractorName: {
                $containsi: searchValue,
              },
            },
          },
        ],
      });

      try {
        setLoading(true);
        unCheckAll();
        const apiParams = {
          pagination: {
            page,
            pageSize: pagination.pageSize,
          },
          sort: sortObj,
          populate: [
            "contractor",
            "contractor.organizationType",
            "agency",
            "conductedBy",
            "pvrFile",
          ],
          filters: {
            $and: andConditions,
          },
        };
        setApiParams(apiParams);
        const response: any = await getPvrs(apiParams);
        setData(normalize(response.data));
        setMeta(response.meta);
      } catch (err: any) {
        toast.error("Error while fetching data", {
          autoClose: 1000,
        });
      } finally {
        setLoading(false);
      }
    },
    [pagination.pageSize, searchParams, setSearchParams, unCheckAll]
  );

  const onClickDelete = useCallback(
    (pvr: any) => {
      setSelectedRecord(pvr);
      setDeleteModal(true);
    },
    [setSelectedRecord, setDeleteModal]
  );

  const fetchUpdatedData = useCallback(() => {
    onPageChange({
      page: pagination.pageIndex + 1,
      sorted: sorted,
      searchValue: searchValue,
    });
  }, [onPageChange, pagination.pageIndex, searchValue, sorted]);

  const [deleting, setDeleting] = useState(false);
  const handleDelete = async () => {
    if (selectedRecord) {
      setDeleting(true);
      try {
        await deletePvr(selectedRecord);
        fetchUpdatedData();
        setDeleteModal(false);
      } catch (err: any) {
        toast.error("Error while deleting PVR", {
          autoClose: 1000,
        });
      } finally {
        setDeleting(false);
      }
    }
  };

  const checkedAll = useCallback(() => {
    const checkall: any = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".tableCheckbox");

    if (checkall.checked) {
      ele.forEach((ele: any) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele: any) => {
        ele.checked = false;
      });
    }
    onSelectCheckbox();
  }, []);

  const [selectedCheckBox, setSelectedCheckBox] = useState<any>([]);

  const onSelectCheckbox = () => {
    const ele = document.querySelectorAll(".tableCheckbox:checked");
    setSelectedCheckBox(ele);
  };

  /* Delete Multiple */
  const [deleteMultipleModal, setDeleteMultipleModal] = useState(false);
  const handleDeleteMultiple = async () => {
    const selectedIds = Array.from(selectedCheckBox).map((ele: any) => {
      return ele.value;
    });
    if (selectedIds.length > 0) {
      setDeleting(true);
      try {
        await Promise.all(
          selectedIds.map((id: any) => {
            return deletePvr({ id });
          })
        );
        setDeleting(false);
        toast.success("PVRs deleted successfully.");
        fetchUpdatedData();
        setDeleteMultipleModal(false);
      } catch (e: any) {
        toast.error("Error while deleting data.");
      } finally {
        setDeleting(false);
      }
    }
  };

  const { userProfile } = useProfile();
  const [columnVisibility] = useState({
    checkBoxColumn: isAllowed(userProfile?.permissions, [
      {
        resource: "partner-verification-report",
        action: "delete",
      },
    ]),
  });

  const columns = useMemo(
    () => [
      {
        header: (
          <input
            type="checkbox"
            id="checkBoxAll"
            className="form-check-input"
            onClick={() => checkedAll()}
          />
        ),
        cell: (cell: any) => {
          return (
            <input
              type="checkbox"
              className="tableCheckbox form-check-input"
              value={cell.getValue()}
              onChange={() => onSelectCheckbox()}
            />
          );
        },
        id: "checkBoxColumn",
        accessorKey: "id",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Report Title",
        accessorKey: "title",
        id: "title",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const rowData = cell.row.original;
          return (
            <>
              <div
                className="d-flex align-items-center"
                style={{
                  whiteSpace: "pre-wrap",
                  width: "550px",
                }}
              >
                <div className="flex-shrink-0 me-3">
                  <div className="avatar-sm">
                    <div className="avatar-title border bg-light text-danger rounded fs-24">
                      <FileIcon
                        fileName={
                          rowData?.pvrFile &&
                          Array.isArray(rowData?.pvrFile) &&
                          rowData?.pvrFile[0]?.name
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-grow-1">
                  <h5 className="fs-14 mb-1">
                    <Permission
                      alternative={
                        <Link to={"#"} className="text-body">
                          {cell.getValue()}
                        </Link>
                      }
                      action="findOne"
                      resource="partner-verification-report"
                    >
                      <Link
                        to={`/assessments/pvrs/${rowData.id}`}
                        className="text-body"
                      >
                        {cell.getValue()}
                      </Link>
                    </Permission>
                  </h5>
                  <p className="text-muted mb-0">
                    Contractor :{" "}
                    <span className="fw-medium">
                      {" "}
                      {cell.row.original?.contractor?.contractorName || ""}
                    </span>
                  </p>
                </div>
              </div>
            </>
          );
        },
      },
      // {
      //   header: "Title",
      //   accessorKey: "title",
      //   id: "title",
      //   enableColumnFilter: false,
      // },

      {
        header: "Requesting Agency",
        accessorKey: "agency.name",
        id: "agency.name",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <Link to="#" onClick={(e) => e.preventDefault()}>
              {cell.getValue()}
            </Link>
          );
        },
      },

      {
        header: "Assessment Date",
        accessorKey: "verificationDate",
        id: "verificationDate",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const date = moment(new Date(cell?.getValue())).format("DD MMM Y");
          return <>{date}</>;
        },
      },
      {
        header: "Action",
        cell: (cell: any) => {
          const rowData = cell.row.original;
          return (
            <UncontrolledDropdown>
              <DropdownToggle
                href="#"
                className="btn btn-soft-secondary btn-sm"
                tag="button"
              >
                <i className="ri-more-fill" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <Permission
                  resource="partner-verification-report"
                  action="findOne"
                >
                  <DropdownItem
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      showUpdateModalForm(rowData);
                    }}
                  >
                    <i className="ri-eye-fill align-bottom me-2 text-muted"></i>{" "}
                    View
                  </DropdownItem>
                </Permission>

                <Permission
                  resource="partner-verification-report"
                  action="update"
                >
                  <DropdownItem
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      showUpdateModalForm(rowData);
                    }}
                  >
                    <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}
                    Edit
                  </DropdownItem>
                </Permission>

                <Permission
                  resource="partner-verification-report"
                  action="delete"
                >
                  <DropdownItem divider />
                  <DropdownItem
                    href="#"
                    onClick={() => {
                      onClickDelete(rowData);
                    }}
                  >
                    <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
                    Delete
                  </DropdownItem>
                </Permission>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    [checkedAll, onClickDelete]
  );
  document.title = "Partner Verification Reports | CIMS";

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb
          title="Partner Verification Reports"
          pageTitles={[
            { title: "Dashboard", link: "/dashboard" },
            {
              title: "Assessments",
              link: "/assessments/pvrs",
            },
          ]}
        />

        <Row>
          <Col xl={12} lg={12}>
            <Card>
              <CardHeader>
                <Row className="align-items-center g-3">
                  <Col md={8}>
                    <h5 className="card-title mb-1">
                      Partner Verification Reports
                    </h5>
                    <p className="text-muted mb-0">
                      This page shows a list of partner verification reports
                      conducted by the Risk Management Unit on behalf of UN
                      Agencies.
                    </p>
                  </Col>
                  <div className="col-md-auto ms-auto">
                    <div className="d-flex gap-2">
                      <Permissions
                        checks={[
                          {
                            resource: "partner-verification-report",
                            action: "delete",
                          },
                        ]}
                      >
                        {selectedCheckBox.length > 0 && (
                          <UncontrolledButtonDropdown>
                            <DropdownToggle
                              color="secondary"
                              caret
                              className="material-shadow-none btn-soft-secondary"
                            >
                              Bulk Actions - {selectedCheckBox.length}
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem
                                onClick={() => {
                                  setDeleteMultipleModal(true);
                                }}
                              >
                                <i className="ri-delete-bin-line align-middle me-2"></i>{" "}
                                Delete
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledButtonDropdown>
                        )}
                      </Permissions>
                      <Permission
                        resource="partner-verification-report"
                        action="create"
                      >
                        <Link
                          to="#"
                          className="btn btn-primary"
                          onClick={(e) => {
                            e.preventDefault();
                            showAddModalForm();
                          }}
                        >
                          <i className="ri-add-line align-bottom me-1"></i>
                          Add PVR
                        </Link>
                      </Permission>
                      <Permissions
                        checks={[
                          {
                            resource: "partner-verification-report",
                            action: "import",
                          },
                          {
                            resource: "partner-verification-report",
                            action: "export",
                          },
                        ]}
                      >
                        <Import apiParams={apiParams} />
                      </Permissions>
                    </div>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <form>
                  <Row>
                    <Col sm={4}>
                      <h6 className="text-uppercase fs-12 mb-2">Search</h6>
                      <div className={"search-box me-2 d-inline-block col-12"}>
                        <input
                          onChange={(e) => {
                            setSearchValue(e.target.value);
                            setPage(1);
                          }}
                          id="search-bar-0"
                          type="text"
                          className="form-control search /"
                          placeholder={"Search pvrs"}
                          value={searchValue || ""}
                        />
                        <i className="bx bx-search-alt search-icon"></i>
                      </div>
                    </Col>
                    <Col sm={3}>
                      <h6 className="text-uppercase fs-12 ">Agency</h6>
                      <AgencyFilter
                        onChange={(value) => {
                          setFilterValue({
                            ...filterValue,
                            agency: value,
                          });
                          setPage(1);
                        }}
                        value={filterValue.agency}
                      />
                    </Col>
                    <Col sm={3}>
                      <h6 className="text-uppercase fs-12">Date Range</h6>
                      {/* @ts-ignore */}
                      <Flatpickr
                        className="form-control"
                        placeholder="Select Date Range"
                        options={{
                          mode: "range",
                          altFormat: "d M, Y",
                          dateFormat: "d M, Y",
                        }}
                        onChange={(e) => {
                          if (e[0] && e[1]) {
                            setFilterValue({
                              ...filterValue,
                              dateRange: {
                                fromDate: e[0],
                                toDate: e[1],
                              },
                            });
                            setPage(1);
                          }
                        }}
                        value={[
                          filterValue?.dateRange?.fromDate || "",
                          filterValue?.dateRange?.toDate || "",
                        ]}
                      />
                    </Col>

                    <Col sm={2}>
                      <h6 className="text-uppercase fs-12 mb-xxl-4"> {` `}</h6>
                      <button
                        color="primary"
                        className="btn btn-outline-primary material-shadow-none w-100"
                        onClick={(e) => {
                          e.preventDefault();
                          setSearchValue("");
                          setFilterValue(defaultFilterValue);
                          setPage(1);
                          setSorted([{ id: "createdAt", desc: true }]);
                        }}
                      >
                        <i className="ri-equalizer-line me-1 align-bottom"></i>
                        Reset Filters
                      </button>
                    </Col>
                  </Row>
                </form>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <TableContainer
                  loading={loading}
                  columns={columns}
                  data={data || []}
                  pagination={pagination}
                  setPagination={setPagination}
                  sorted={sorted}
                  setSorted={setSorted}
                  onPageChange={onPageChange}
                  FilterSection={() => {}}
                  totalRows={meta?.pagination?.total || 0}
                  totalPages={meta?.pagination?.pageCount || 0}
                  searchValue={searchValue}
                  filterValue={filterValue}
                  columnVisibility={columnVisibility}
                  divClass="table-responsive table-card mb-4"
                  tableClass="align-middle table-nowrap mb-0 table-striped"
                  theadClass=" table-nowrap"
                  thClass={"pt-3"}
                />
                <AddPVR
                  toggle={() => setIsAddModalOpen((state) => !state)}
                  isModalOpen={isAddModalOpen}
                  fetchData={fetchData}
                />
                <UpdatePVR
                  toggle={() => setIsUpdateModalOpen((state) => !state)}
                  isModalOpen={isUpdateModalOpen}
                  selectedRecord={selectedRecord}
                  fetchUpdatedData={fetchUpdatedData}
                />
                <DeleteModal
                  show={deleteModal}
                  onDeleteClick={() => handleDelete()}
                  onCloseClick={() => setDeleteModal(false)}
                  loading={deleting}
                />
                <DeleteModal
                  show={deleteMultipleModal}
                  onDeleteClick={() => handleDeleteMultiple()}
                  onCloseClick={() => setDeleteMultipleModal(false)}
                  loading={deleting}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PVRs;
