import { ICON_AGENCIES, ICON_CALENDAR } from "common/constants";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import * as moment from "moment";

const AssessmentItem = ({
  data,
  index,
  entityType,
}: {
  data: any;
  index: number;
  entityType: string;
}) => {
  const details = [
    data?.updatedAt &&
      moment(new Date(data.updatedAt)).format("ddd, DD MMM, Y"),
    data?.contractor?.contractorName &&
      `Contractor: ${data.contractor.contractorName}`,
  ].filter(Boolean);

  const detailsString = useMemo(() => details.join(", "), [details]);
  return (
    <div className={index === 0 ? "pb-3" : "py-3"}>
      <h5 className="mb-1">
        {entityType === "risk-assessment" && (
          <Link to={`/assessments/risk-assessments/${data?.id}`}>
            {data.reportTitle || data?.title}
          </Link>
        )}
        {entityType === "capacity-assessment" && (
          <Link to={`/assessments/capacity-assessments/${data?.id}`}>
            {data.reportTitle || data?.title}
          </Link>
        )}
        {entityType === "partner-verification-report" && (
          <Link to={`/assessments/pvrs/${data?.id}`}>
            {data.reportTitle || data?.title}
          </Link>
        )}
      </h5>
      <p className="text-success mb-2">
        {entityType === "risk-assessment" && "Risk Assessment"}
        {entityType === "capacity-assessment" && "Capacity Assessment"}
        {entityType === "partner-verification-report" &&
          "Partner Verification Report"}
      </p>
      <p className="text-muted mb-2">{`... ${detailsString} ...`}</p>
      <ul className="list-inline d-flex align-items-center g-3 text-muted fs-14 mb-0">
        <li className="list-inline-item me-3">
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0">
              <i className={ICON_CALENDAR}></i>
            </div>
            <div className="flex-grow-1 fs-13 ms-1">
              <span className="fw-medium">
                {" "}
                {moment(new Date(data?.updatedAt)).format("DD MMM Y")}
              </span>
            </div>
          </div>
        </li>
        <li className="list-inline-item">
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0">
              <i className={ICON_AGENCIES}></i>
            </div>
            <div className="flex-grow-1 fs-13 ms-1">
              <span className="fw-medium">{data?.agency?.name}</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default AssessmentItem;
