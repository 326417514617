import React from "react";
import resultsNotFound from "assets/images/verification-img.png";

const ResultsNotFound = () => {
  return (
    <div
      className="d-flex align-items-center justify-content-center border border-dashed p-2 rounded"
      style={{ height: "580px" }}
    >
      <div className="text-center pt-5 pb-5">
        {/* <img src={resultsNotFound} alt="" height="150" className="" /> */}
        <i className="ri ri-search-2-line display-5 text-gray-300"></i>
        <h5 className="mt-4">Sorry! No Results Found</h5>
      </div>
    </div>
  );
};

export default ResultsNotFound;
