import React, { useCallback, useMemo, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledButtonDropdown,
  UncontrolledDropdown,
} from "reactstrap";

import TableContainer from "Components/Common/TableContainer";
import { getPersons, deletePerson } from "common/services/persons.service";
import { getSortObj } from "common/lib/tableDataOptions";
import * as moment from "moment";
import Permission from "Components/Common/Permission";
import Permissions from "Components/Common/Permissions";
import DeleteModal from "Components/Common/DeleteModal";
import { normalize } from "common/lib/normalize";
import { toast } from "react-toastify";
import { useProfile } from "Components/Hooks/UserHooks";
import { isAllowed } from "common/lib/permissionUtils";

interface FilterValue {
  gender: string;
}

const AllPersons = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [params] = useState({
    page: Number(searchParams.get("page")) || 1, // Starts from 1
    pageSize: Number(searchParams.get("pageSize")) || 20, // Default page size
  });

  const [pagination, setPagination] = useState({
    pageIndex: params.page - 1, // Convert to zero-based index
    pageSize: params.pageSize,
  });

  // Update pageIndex
  const setPage = (newPage: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      pageIndex: newPage - 1,
    }));
  };

  const [sorted, setSorted] = useState(() => {
    const sortParam = searchParams.get("sort");
    return sortParam
      ? JSON.parse(sortParam)
      : [{ id: "createdAt", desc: true }];
  });

  const [selectedRecord, setSelectedRecord] = useState<any>({});

  const [searchValue, setSearchValue] = useState(
    searchParams.get("search") || ""
  );
  const defaultFilterValue: FilterValue = {
    gender: "",
  };
  const [filterValue, setFilterValue] = useState(() => {
    const filterParam = searchParams.get("filter");
    return filterParam ? JSON.parse(filterParam) : defaultFilterValue;
  });

  const unCheckAll = useCallback(() => {
    const checkall: any = document.getElementById("checkBoxAll");
    if (checkall) checkall.checked = false;
    setSelectedCheckBox([]);
  }, []);

  const [apiParams, setApiParams] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState<any>({});
  const onPageChange = useCallback(
    async ({
      page,
      sorted,
      searchValue,
      filterValue,
    }: {
      page: number;
      sorted: Array<{ id: string; desc: boolean }>;
      searchValue: string;
      filterValue?: any;
    }) => {
      const params = new URLSearchParams(searchParams);
      params.set("page", String(page));
      params.set("sort", JSON.stringify(sorted));
      params.set("search", searchValue);
      params.set("filter", JSON.stringify(filterValue));
      setSearchParams(params, { replace: true });

      const sortObj = getSortObj(sorted);

      let andConditions = [];
      if (filterValue?.gender) {
        andConditions.push({
          gender: {
            $eq: filterValue.gender,
          },
        });
      }

      andConditions.push({
        $or: [
          {
            fullName: {
              $containsi: searchValue,
            },
          },
        ],
      });

      try {
        setLoading(true);
        unCheckAll();

        const apiParams = {
          pagination: {
            page,
            pageSize: pagination.pageSize,
          },
          sort: sortObj,
          populate: [
            "alias",
            "contactDetails",
            "flaggedBy",
            "createdby",
            "updatedby",
            "currentNationality",
          ],
          filters: {
            $and: andConditions,
          },
        };
        setApiParams(apiParams);
        const response: any = await getPersons(apiParams);
        setData(normalize(response.data));
        setMeta(response?.meta);
      } catch (e: any) {
        toast.error("Error while fetching data", {});
      } finally {
        setLoading(false);
      }
    },
    [pagination.pageSize, searchParams, setSearchParams, unCheckAll]
  );

  console.log("data", data);

  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = useCallback(
    (data: any) => {
      setSelectedRecord(data);
      setDeleteModal(true);
    },
    [setSelectedRecord, setDeleteModal]
  );

  const fetchUpdatedData = useCallback(() => {
    onPageChange({
      page: pagination.pageIndex + 1,
      sorted: sorted,
      searchValue: searchValue,
    });
  }, [onPageChange, pagination.pageIndex, sorted, searchValue]);

  const [deleting, setDeleting] = useState(false);
  const handleDelete = async () => {
    if (selectedRecord) {
      setDeleting(true);
      try {
        await deletePerson({
          id: selectedRecord.id,
        });
        fetchUpdatedData();
        setDeleting(false);
        setDeleteModal(false);
        toast.success("Person deleted successfully");
      } catch (e: any) {
        toast.error("Error while deleting person");
      } finally {
        setDeleting(false);
      }
    }
  };

  const checkedAll = useCallback(() => {
    const checkall: any = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".tableCheckbox");

    if (checkall.checked) {
      ele.forEach((ele: any) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele: any) => {
        ele.checked = false;
      });
    }
    onSelectCheckbox();
  }, []);

  const [selectedCheckBox, setSelectedCheckBox] = useState<any>([]);

  const onSelectCheckbox = () => {
    const ele = document.querySelectorAll(".tableCheckbox:checked");
    setSelectedCheckBox(ele);
  };

  /* Delete Multiple */
  const [deleteMultipleModal, setDeleteMultipleModal] = useState(false);
  const handleDeleteMultiple = async () => {
    const selectedIds = Array.from(selectedCheckBox).map((ele: any) => {
      return ele.value;
    });
    if (selectedIds.length > 0) {
      setDeleting(true);
      try {
        await Promise.all(
          selectedIds.map((id: any) => {
            return deletePerson({ id });
          })
        );
        setDeleting(false);
        toast.success("Persons deleted successfully.");
        fetchUpdatedData();
        setDeleteMultipleModal(false);
      } catch (e: any) {
        toast.error("Error while deleting data.");
      } finally {
        setDeleting(false);
      }
    }
  };

  const { userProfile } = useProfile();
  const [columnVisibility] = useState({
    checkBoxColumn: isAllowed(userProfile?.permissions, [
      {
        resource: "person",
        action: "delete",
      },
    ]),
  });

  const columns = useMemo(
    () => [
      {
        header: (
          <input
            type="checkbox"
            id="checkBoxAll"
            className="form-check-input"
            onClick={() => checkedAll()}
          />
        ),
        cell: (cell: any) => {
          return (
            <input
              type="checkbox"
              className="tableCheckbox form-check-input"
              value={cell.getValue()}
              onChange={() => onSelectCheckbox()}
            />
          );
        },
        id: "checkBoxColumn",
        accessorKey: "id",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Name",
        accessorKey: "firstName",
        id: "firstName",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const rowData = cell.row.original;

          return (
            <>
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0 avatar-xs me-3">
                  <div className="avatar-title bg-primary-subtle text-primary rounded-circle fs-16">
                    <i className={"ri-user-line"}></i>
                  </div>
                </div>
                <Link
                  to={`/persons/${cell.row.original?.id}`}
                  className="fw-medium link-primary"
                >
                  {`${rowData?.fullName || ""}`}
                </Link>
              </div>
            </>
          );
        },
      },
      {
        header: "Gender",
        accessorKey: "gender",
        enableColumnFilter: false,
        id: "gender",
        cell: (cell: any) => {
          return <>{cell.getValue() || "Not Provided"}</>;
        },
      },
      {
        header: "Nationality",
        accessorKey: "currentNationality.name",
        id: "currentNationality.name",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cell: any) => {
          return <>{cell.getValue() || "Not Provided"}</>;
        },
      },
      {
        header: "Date Added",
        accessorKey: "createdAt",
        id: "createdAt",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const date = moment(new Date(cell?.getValue())).format("DD MMM Y");
          return <>{date}</>;
        },
      },
      {
        header: "Action",
        cell: (cellProps: any) => {
          const rowData = cellProps.row.original;
          return (
            <UncontrolledDropdown>
              <DropdownToggle
                href="#"
                className="btn btn-soft-secondary btn-sm dropdown"
                tag="button"
              >
                <i className="ri-more-fill align-middle"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <Permission resource="person" action="findOne">
                  <DropdownItem
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(`/persons/${rowData.id}`);
                    }}
                  >
                    <i className="ri-eye-fill align-bottom me-2 text-muted"></i>{" "}
                    View
                  </DropdownItem>
                </Permission>

                <Permission resource="person" action="delete">
                  <DropdownItem divider />
                  <DropdownItem
                    href="#"
                    onClick={() => {
                      onClickDelete(rowData);
                    }}
                  >
                    <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>{" "}
                    Delete
                  </DropdownItem>
                </Permission>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    [checkedAll, navigate, onClickDelete]
  );

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <Row className="g-3">
            <Col xxl={7} lg={6}>
              <h6 className="text-uppercase fs-12 mb-2">Search</h6>
              <div className="search-box">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search people..."
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                    setPage(1);
                  }}
                  value={searchValue}
                />
                <i className="ri-search-line search-icon"></i>
              </div>
            </Col>
            <Col xxl={3} lg={6}>
              <h6 className="text-uppercase fs-12 mb-2">Select Gender</h6>
              <select
                className="form-control"
                data-choices
                data-choices-search-false
                name="choices-single-default2"
                id="choices-single-default2"
                onChange={(e: any) => {
                  setFilterValue({ ...filterValue, gender: e.target.value });
                  setPage(1);
                }}
                value={filterValue.gender}
              >
                <option value="">Select Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
            </Col>

            <Col xxl={2} lg={4}>
              <h6 className="text-uppercase fs-12 mb-xxl-4"> {` `}</h6>
              <button
                onClick={() => {
                  setFilterValue(defaultFilterValue);
                  setSearchValue("");
                  setPage(1);
                  setSorted([{ id: "createdAt", desc: true }]);
                }}
                className="btn btn-outline-primary material-shadow-none w-100"
              >
                <i className="ri-equalizer-line align-bottom me-1"></i> Reset
                Filters
              </button>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <Row className="align-items-center g-3">
            <Col md={9}>
              <h5 className="card-title mb-1">Persons</h5>
              <p className="mb-0 text-muted">
                This list constitutes of all individuals inclusive of the ones
                identified in Panel of Experts reports (POE) and Interpol List.
              </p>
            </Col>
            <div className="col-md-auto ms-auto">
              <div className="d-flex gap-2">
                <Permissions
                  checks={[{ resource: "person", action: "delete" }]}
                >
                  {selectedCheckBox.length > 0 && (
                    <UncontrolledButtonDropdown>
                      <DropdownToggle
                        color="secondary"
                        caret
                        className="material-shadow-none btn-soft-secondary"
                      >
                        Bulk Actions - {selectedCheckBox.length}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={() => {
                            setDeleteMultipleModal(true);
                          }}
                        >
                          <i className="ri-delete-bin-line align-middle me-2"></i>{" "}
                          Delete
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledButtonDropdown>
                  )}
                </Permissions>
                <Permission resource="person" action="create">
                  <Link to="/persons/add" className="btn btn-primary">
                    <i className="ri-add-line align-bottom me-1"></i>
                    Add Person
                  </Link>
                </Permission>
              </div>
            </div>
          </Row>
        </CardHeader>
        <CardBody>
          <TableContainer
            loading={loading}
            columns={columns}
            data={data || []}
            pagination={pagination}
            setPagination={setPagination}
            sorted={sorted}
            setSorted={setSorted}
            onPageChange={onPageChange}
            FilterSection={() => {}}
            totalRows={meta?.pagination?.total || 0}
            totalPages={meta?.pagination?.pageCount || 0}
            searchValue={searchValue}
            filterValue={filterValue}
            columnVisibility={columnVisibility}
            divClass="table-responsive table-card mb-4"
            tableClass="align-middle table-nowrap mb-0 table-striped"
            theadClass="table-light table-nowrap"
          />
          <DeleteModal
            show={deleteModal}
            onDeleteClick={() => handleDelete()}
            onCloseClick={() => setDeleteModal(false)}
            loading={deleting}
          />
          <DeleteModal
            show={deleteMultipleModal}
            onDeleteClick={() => handleDeleteMultiple()}
            onCloseClick={() => setDeleteMultipleModal(false)}
            loading={deleting}
          />
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default AllPersons;
