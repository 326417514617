export const isAllowed = (
  permissions: any,
  checks: Array<{ resource: string; action: string; controller?: string }>
): boolean => {
  return (
    checks &&
    checks.some(
      ({
        resource,
        action,
        controller,
      }: {
        resource: string;
        action: string;
        controller?: string;
      }) => {
        return permissions?.[`api::${resource}`]?.controllers?.[
          controller || resource
        ]?.[action]?.enabled;
      }
    )
  );
};
