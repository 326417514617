import React, { useState } from "react";
import { Modal, ModalHeader } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { updateContractor } from "common/services/contractors.service";
import { toast } from "react-toastify";
import ContractorForm from "./ContractorForm";

const UpdateContractor: React.FC<{
  toggle: () => void;
  isModalOpen: boolean;
  fetchData: () => void;
  contractor: any;
}> = ({ toggle, isModalOpen, contractor, fetchData }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const aliasNames = contractor?.previousOrAliasName;

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      contractorName: contractor?.contractorName || "",
      acronym: contractor?.acronym || "",
      organizationType: {
        value: contractor?.organizationType?.id || "",
        label: contractor?.organizationType?.name || "",
      },
      previousOrAliasName: (aliasNames.length > 0 &&
        aliasNames.map((item: any) => item.name)) || [""],
    },
    validationSchema: Yup.object({
      contractorName: Yup.string().required("Please enter contractor name"),
      acronym: Yup.string(),
      organizationType: Yup.object().shape({
        value: Yup.number().required("Please select organization type"),
      }),
      previousOrAliasName: Yup.array().of(Yup.string()),
    }),
    onSubmit: (values: any) => {
      const data = {
        id: contractor.id,
        data: {
          contractorName: values.contractorName,
          acronym: values.acronym,
          organizationType: values.organizationType.value,
          previousOrAliasName:
            Array.isArray(values["previousOrAliasName"]) &&
            values["previousOrAliasName"].length > 0
              ? values["previousOrAliasName"].map((el) => {
                  return {
                    name: el,
                  };
                })
              : null,
        },
      };

      setLoading(true);
      const saveData = async () => {
        try {
          await updateContractor(data);
          validation.resetForm();
          toggle();
          toast.success("Contractor Updated Successfully", {
            autoClose: 1000,
          });
        } catch (err: any) {
          toast.error("Error while updating contractor", {
            autoClose: 1000,
          });
        } finally {
          setLoading(false);
          fetchData();
        }
      };
      saveData();
    },
  });

  return (
    <Modal
      id="showModal"
      size="lg"
      isOpen={isModalOpen}
      toggle={toggle}
      centered
    >
      <ModalHeader className="bg-light p-3" toggle={toggle}>
        Update Contractor Details
      </ModalHeader>

      <ContractorForm
        toggle={toggle}
        validation={validation}
        isUpdate
        loading={loading}
        setLoading={setLoading}
      />
    </Modal>
  );
};

export default UpdateContractor;
