import React, { useMemo, useEffect, useState } from "react";
import { Label, FormFeedback, FormGroup } from "reactstrap";
import Select from "react-select";

import { getOrganizationTypes } from "common/services/contractors.service";
import { normalize } from "common/lib/normalize";

const SelectCategory = ({
  validation,
  setLoading,
}: {
  validation: any;
  setLoading: (loading: boolean) => void;
}) => {
  const customSelectStyles = {
    control: (styles: any) => ({
      ...styles,
      borderColor:
        validation.touched.category && validation.errors.category
          ? "red"
          : "#ced4da",
      "&:hover": {
        borderColor:
          validation.touched.category && validation.errors.category
            ? "red"
            : "#ced4da",
      },
    }),
  };

  const [data, setData] = useState<any>([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response: any = await getOrganizationTypes({
          pagination: {
            page: 1,
            pageSize: 100,
          },
        });

        setData(normalize(response.data));
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [setLoading]);

  const options = useMemo(() => {
    const transformedData = data.map((el: any) => {
      return {
        value: el?.id,
        label: `${el?.name}`,
      };
    });
    transformedData.unshift({ value: null, label: "Select category" });
    return transformedData;
  }, [data]);

  return (
    <FormGroup>
      <Label htmlFor="category-field" className="form-label">
        Select Category
      </Label>
      <Select
        name="category"
        id="category"
        value={
          validation.values.category.value ? validation.values.category : null
        }
        placeholder="Select category"
        validate={{
          required: { value: true },
        }}
        onChange={(value: any) => {
          validation.setFieldValue("category", value);
          validation.setFieldError("category", "");
        }}
        options={options}
        onBlur={() => validation.setFieldTouched("category", true)}
        className={
          validation.touched.category && validation.errors.category
            ? "is-invalid"
            : ""
        }
        styles={customSelectStyles}
      />
      <FormFeedback>{validation.errors.category?.value}</FormFeedback>
    </FormGroup>
  );
};

export default SelectCategory;
