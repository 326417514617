import React from "react";

export const FileIconExtension = ({ fileName }: { fileName: any }) => {
  const extension = fileName && fileName.split(".").pop().toLowerCase();
  switch (extension) {
    case "csv":
      return <i className="ri-file-excel-2-line text-success"></i>;
    case "docx":
    case "doc":
      return <i className="ri-file-word-2-line text-primary"></i>;
    case "pdf":
      return <i className="ri-file-pdf-line text-danger"></i>;
    default:
      return <i className="ri-file-line text-primary"></i>;
  }
};

const FileIcon = ({ fileName }: { fileName: any }) => {
  const extension = fileName && fileName.split(".").pop().toLowerCase();
  switch (extension) {
    case "csv1":
      return <i className="ri-file-excel-2-line text-success"></i>;
    case "docx1":
    case "doc1":
      return <i className="ri-file-word-2-line text-primary"></i>;
    case "pdf":
      return <i className="ri-file-word-2-line text-primary"></i>;
    default:
      return <i className="ri-file-word-2-line text-primary"></i>;
  }
};

export default FileIcon;
