//Include Both Helper File with needed methods
import { postJwtLogin, getUserProfile } from "../../../helpers/backend_helper";
import { getRole } from "common/services/users.service";

import {
  loginSuccess,
  logoutUserSuccess,
  apiError,
  reset_login_flag,
  setLoading,
} from "./reducer";

export const loginUser = (user: any, history: any) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));

    let response;
    response = postJwtLogin({
      identifier: user.email,
      password: user.password,
    });

    var data: any = await response;

    if (data) {
      let authData = {
        token: data?.jwt,
        userId: data?.user?.userId,
        email: data?.user?.email,
        username: data?.user?.username,
        firstName: data?.user?.firstName,
        lastName: data?.user?.lastName,
        role: data?.user?.role,
        agency: data?.user?.agency,
        country: data?.user?.country,
        permissions: {},
        title: data?.user?.title,
        createdAt: data?.user?.createdAt,
        updatedAt: data?.user?.updatedAt,
        userCategory: data?.user?.userCategory,
        status: data?.user?.status,
        bio: data?.bio,
        phoneNumber: data?.phoneNumber,
      };
      localStorage.setItem("authUser", JSON.stringify(authData));

      const userProfile: any = await getUserProfile({
        populate: ["role", "agency", "country"],
      });

      authData.role = userProfile?.role;
      authData.agency = userProfile?.agency;

      const role: any = await getRole({
        id: userProfile?.role?.id,
      });

      authData.permissions = role?.role?.permissions;
      await localStorage.setItem("authUser", JSON.stringify(authData));

      dispatch(loginSuccess(authData));
      const lastVisitedURL = sessionStorage.getItem("lastVisitedURL");
      history(lastVisitedURL || "/dashboard");
    }
  } catch (error: any) {
    const errorMsg = error?.response?.data?.error?.message || "";
    dispatch(apiError(errorMsg.replace("identifier", "email")));
  }
};

export const logoutUser = () => async (dispatch: any) => {
  try {
    await localStorage.removeItem("authUser");

    dispatch(logoutUserSuccess(true));
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const resetLoginFlag = () => async (dispatch: any) => {
  try {
    const response = dispatch(reset_login_flag());
    return response;
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const fetchLoggedInUser = () => async (dispatch: any) => {
  try {
    const user = localStorage.getItem("authUser");
    if (!user) {
      return null;
    }
    const parsedUser = JSON.parse(user);
    const userProfile: any = await getUserProfile({
      populate: [
        "role",
        "agency",
        "agency.mainLogo",
        "agency.mainLogoLight",
        "country",
      ],
    });

    if (userProfile) {
      let authData = {
        ...parsedUser,
        email: userProfile?.email,
        username: userProfile?.username,
        firstName: userProfile?.firstName,
        lastName: userProfile?.lastName,
        role: userProfile?.role,
        agency: userProfile?.agency,
        country: userProfile?.country,
        title: userProfile?.title,
        createdAt: userProfile?.createdAt,
        updatedAt: userProfile?.updatedAt,
        userCategory: userProfile?.userCategory,
        status: userProfile?.status,
        bio: userProfile?.bio,
        phoneNumber: userProfile?.phoneNumber,
      };
      localStorage.setItem("authUser", JSON.stringify(authData));

      const role: any = await getRole({
        id: userProfile?.role?.id,
      });
      authData.permissions = role?.role?.permissions;
      await localStorage.setItem("authUser", JSON.stringify(authData));
      return authData;
      // dispatch(loginSuccess(authData));
    }
  } catch (error) {
    dispatch(apiError(error));
    return false;
  }
};
