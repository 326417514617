import React, { useMemo, useEffect } from "react";
import Select from "react-select";
import { useDispatch } from "react-redux";

import { getRegions } from "common/services/regions.service";
import { normalize } from "common/lib/normalize";
import { COUNTRY_LEVEL } from "common/constants";

const CountryFilter = ({
  value,
  onChange,
}: {
  value: any;
  onChange: (value: any) => void;
}) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [data, setData] = React.useState<any>([]);
  const dispatch: any = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        let filters = {
          level: COUNTRY_LEVEL,
        };

        const response = await getRegions({
          pagination: {
            page: 1,
            pageSize: 220,
          },
          filters,
        });
        setData(normalize(response.data));
      } catch (err: any) {
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, setLoading]);

  const options = useMemo(() => {
    const mainOptions = data.map((el: any) => {
      return {
        value: el?.id,
        label: el?.name,
      };
    });
    // mainOptions.unshift({ value: null, label: "None" });
    return mainOptions;
  }, [data]);

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      borderColor: "#ced4da",
      borderRadius: ".25rem",
      boxShadow: "none",
      "&:hover": {
        borderColor: "#ced4da",
      },
    }),
    menu: (provided: any) => ({
      ...provided,
      borderColor: "#ced4da",
      borderRadius: ".25rem",
    }),
  };

  return (
    <Select
      name="country"
      id="country"
      value={value || null}
      placeholder="Select country"
      onChange={(value: any) => {
        onChange(value);
      }}
      options={options}
      isLoading={loading}
      styles={customStyles}
    />
  );
};

export default CountryFilter;
