import SelectOrganizationType from "pages/Contractors/SelectOrganizationType";
import React, { useEffect, useRef } from "react";
import {
  Form,
  Label,
  FormFeedback,
  Input,
  Spinner,
  Row,
  Col,
  CardBody,
  Card,
  Button,
  ModalBody,
} from "reactstrap";
import SimpleBar from "simplebar-react";

const ContractorForm: React.FC<{
  toggle: () => void;
  loading: boolean;
  isUpdate?: boolean;
  validation: any;
  setLoading: (loading: boolean) => void;
}> = ({ validation, isUpdate, loading, setLoading, toggle }) => {
  const simpleBarRef: any = useRef(null);
  useEffect(() => {
    if (simpleBarRef.current) {
      const simpleBarInstance = simpleBarRef.current.getScrollElement();
      simpleBarInstance.scrollTop = simpleBarInstance.scrollHeight;
    }
  }, []);

  return (
    <Form
      className="tablelist-form"
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      <ModalBody className="px-0 py-0">
        <SimpleBar
          ref={simpleBarRef}
          style={{ maxHeight: "80vh" }}
          className=" px-4 py-4"
          autoHide={false}
        >
          <Row>
            <Col md={12} className="mb-3">
              <Row>
                <Col md={6} className="mb-3">
                  <Label htmlFor="id-field" className="form-label">
                    Contractor Name
                  </Label>
                  <Input
                    name="contractorName"
                    id="contractorName"
                    className="form-control"
                    placeholder="Enter contractor name"
                    type="text"
                    validate={{
                      required: { value: true },
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.contractorName || ""}
                    invalid={
                      validation.touched.contractorName &&
                      validation.errors.contractorName
                        ? true
                        : false
                    }
                  />
                  {validation.touched.contractorName &&
                  validation.errors.contractorName ? (
                    <FormFeedback type="invalid">
                      {validation.errors.contractorName}
                    </FormFeedback>
                  ) : null}
                </Col>
                <Col md={6} className="mb-3">
                  <Label htmlFor="id-field" className="form-label">
                    Acronym
                  </Label>
                  <Input
                    name="acronym"
                    id="acronym"
                    className="form-control"
                    placeholder="Enter acronym"
                    type="text"
                    validate={{
                      required: { value: true },
                    }}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.acronym || ""}
                    invalid={
                      validation.touched.acronym && validation.errors.acronym
                        ? true
                        : false
                    }
                  />
                  {validation.touched.acronym && validation.errors.acronym ? (
                    <FormFeedback type="invalid">
                      {validation.errors.acronym}
                    </FormFeedback>
                  ) : null}
                </Col>
                <Col md={6} className="mb-3">
                  <SelectOrganizationType
                    validation={validation}
                    setLoading={setLoading}
                  />
                </Col>
              </Row>

              <h4 className="card-title mb-3"> Previous or Alias Name(s)</h4>

              <Row>
                <Col md={12} className="mb-3">
                  <>
                    {validation.values.previousOrAliasName.map(
                      (name: string, index: number) => (
                        <>
                          <Card className="bg-light">
                            <CardBody>
                              <div key={index} className="mb-3 ">
                                <Label
                                  htmlFor={`previousOrAliasName-${index}`}
                                  className="form-label"
                                >
                                  Alias Name
                                </Label>
                                <Input
                                  name={`previousOrAliasName.${index}`}
                                  id={`previousOrAliasName.${index}`}
                                  className="form-control"
                                  placeholder="Enter alias name"
                                  type="text"
                                  validate={{
                                    required: { value: true },
                                  }}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={name}
                                  invalid={
                                    validation.touched.previousOrAliasName &&
                                    validation.touched.previousOrAliasName[
                                      index
                                    ] &&
                                    validation.errors.previousOrAliasName &&
                                    validation.errors.previousOrAliasName[index]
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.preiousOrAliasName &&
                                validation.touched.previousOrAliasName[index] &&
                                validation.errors.previousOrAliasName &&
                                validation.errors.previousOrAliasName[index] ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors?.previousOrAliasName &&
                                      validation.errors.previousOrAliasName[
                                        index
                                      ]}
                                  </FormFeedback>
                                ) : null}
                              </div>
                              <div className="mb-3 d-flex justify-content-end">
                                <Button
                                  className="btn btn-sm btn-soft-danger waves-effect waves-light material-shadow-none"
                                  onClick={(e: any) => {
                                    e.preventDefault();
                                    const names = [
                                      ...validation.values.previousOrAliasName,
                                    ];
                                    names.splice(index, 1);
                                    validation.setFieldValue(
                                      "previousOrAliasName",
                                      names
                                    );
                                  }}
                                >
                                  {/* <i className="ri-delete-bin-fill me-2"></i> */}
                                  Remove
                                </Button>
                              </div>
                            </CardBody>
                          </Card>
                        </>
                      )
                    )}
                    <button
                      className="btn btn-soft-secondary waves-effect waves-light material-shadow-none"
                      onClick={(e: any) => {
                        e.preventDefault();
                        if (validation.values.previousOrAliasName.length < 5) {
                          validation.setFieldValue("previousOrAliasName", [
                            ...validation.values.previousOrAliasName,
                            "",
                          ]);
                        }
                      }}
                    >
                      Add
                    </button>
                  </>
                </Col>
              </Row>
            </Col>
          </Row>
        </SimpleBar>
      </ModalBody>
      <div className="modal-footer">
        <div className="hstack gap-2 justify-content-end">
          <button
            type="button"
            className="btn btn-light"
            onClick={() => {
              toggle();
            }}
          >
            Close
          </button>

          <button type="submit" className="btn btn-primary" disabled={loading}>
            {loading && (
              <Spinner
                color="light"
                size="sm"
                style={{ marginRight: "8px", marginBottom: "-1px" }}
              ></Spinner>
            )}
            {"Save Changes"}
          </button>
        </div>
      </div>
    </Form>
  );
};

export default ContractorForm;
