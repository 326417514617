import React, { useEffect } from "react";
import AssessmentItem from "../Items/AssessmentItem";
import ContractItem from "../Items/ContractItem";
import ContractorsItem from "../Items/ContractorsItem";
import PersonItem from "../Items/PersonItem";
import SanctionItem from "../Items/SanctionItem";
import { searchGlobalContent } from "common/services/search.service";
import PaginationFooter from "./PaginationFooter";
import LoadingItem from "../Items/LoadingItem";
import { determineEntityType } from "common/lib/entityType";
import debounce from "debounce";
import { DEBOUNCE_TIME } from "common/constants";
import ResultsNotFound from "../Items/ResultsNotFound";

const tab = "1";

const AllResults = ({
  searchValue,
  activeTab,
}: {
  searchValue: string;
  activeTab: string;
}) => {
  const [searchResults, setSearchResults] = React.useState<any>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [page, setPage] = React.useState<number>(1);
  const [totalPages, setTotalPages] = React.useState<number>(0);

  useEffect(() => {
    const search = debounce(async () => {
      try {
        setLoading(true);
        const response: any = await searchGlobalContent({
          query: searchValue,
          pageSize: 20,
          page: page,
        });

        setSearchResults(response.hits);
        setPage(response?.page);
        setTotalPages(response?.totalPages);
        setLoading(false);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }, DEBOUNCE_TIME);

    if (activeTab === tab) {
      search();
    }
    return () => {
      search.clear();
    };
  }, [searchValue, page, activeTab]);

  useEffect(() => {
    setPage(1);
  }, [searchValue]);

  if (loading) {
    return Array.from({ length: 10 }).map((_, index) => (
      <div key={index}>
        {index !== 0 && <div className="border border-dashed"></div>}
        <LoadingItem key={index} />
      </div>
    ));
  }

  if (
    searchResults.length === 0 &&
    (searchValue !== "" || searchValue !== null)
  ) {
    return <ResultsNotFound />;
  }

  return (
    <>
      {searchResults.map((result: any, index: number) => {
        const entityType: string = determineEntityType(result._meilisearch_id);
        if (entityType === "contractor") {
          return (
            <div key={result.id}>
              {index !== 0 && <div className="border border-dashed"></div>}
              <ContractorsItem data={result} index={index} />
            </div>
          );
        }
        if (entityType === "contract") {
          return (
            <div key={result.id}>
              {index !== 0 && <div className="border border-dashed"></div>}
              <ContractItem data={result} index={index} />
            </div>
          );
        }

        if (
          entityType === "risk-assessment" ||
          entityType === "capacity-assessment" ||
          entityType === "partner-verification-report"
        ) {
          return (
            <div key={result.id}>
              {index !== 0 && <div className="border border-dashed"></div>}
              <AssessmentItem
                data={result}
                index={index}
                entityType={entityType}
              />
            </div>
          );
        }
        if (
          entityType === "eu-sanctions-list" ||
          entityType === "us-sanctions-list" ||
          entityType === "uk-sanctions-list" ||
          entityType === "un-designated-list" ||
          entityType === "ungm-list" ||
          entityType === "world-bank-banned-list"
        ) {
          return (
            <div key={result.id}>
              {index !== 0 && <div className="border border-dashed"></div>}
              <SanctionItem
                data={result}
                index={index}
                entityType={entityType}
              />
            </div>
          );
        }
        if (entityType === "person") {
          return (
            <div key={result.id}>
              {index !== 0 && <div className="border border-dashed"></div>}
              <PersonItem data={result} index={index} />
            </div>
          );
        }

        return null;
      })}

      {/* 

      <div className="border border-dashed"></div>

      <PersonItem />

     */}

      <PaginationFooter
        page={page}
        totalPages={totalPages}
        onPageChange={(page: number) => setPage(page)}
      />
    </>
  );
};

export default AllResults;
